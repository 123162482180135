/**
 * @author MrZenW
 * @email MrZenW@Gmail.com, https://MrZenW.com
 * @create date 2021-05-25 13:21:15
 * @modify date 2021-06-04 16:42:40
 * @desc [description]
 */
const { nanoid } = require('nanoid');
const { statehookify, getStatehook, parsePath } = require('$/statehook');

exports.grabWebTaskManagerService = (sessionId) => {
  const sessionIdPath = parsePath(sessionId, '/');
  const statehookName = [].concat(['web_task_manager_service'], sessionIdPath);
  const instance = getStatehook(statehookName);
  if (instance) return instance;
  return statehookify({ statehookName, bindThisToBase: true }, {
    get allTasks() {
      return this.statehookGetPath('allTasks');
    },
    set allTasks(v) {
      throw new Error('The property allTasks cannot be changed manually!');
    },
    get aliveTasks() {
      return Object.entries(this.allTasks).reduce((acc, [webTaskKey, webTaskData]) => {
        if (webTaskData.status === this.CONST_STATUS_RUNNING) {
          acc[webTaskKey] = webTaskData;
        }
        return acc;
      }, {});
    },
    set aliveTasks(v) {
      throw new Error('The property aliveTasks cannot be changed manually!');
    },
    get CONST_STATUS_RUNNING() {
      return 'CONST_STATUS_RUNNING';
    },
    get CONST_STATUS_TERMINATED() {
      return 'CONST_STATUS_TERMINATED';
    },
    getTask(webTaskKey) {
      return this.allTasks[webTaskKey];
    },
    newTask(webTaskKey, webTaskData) {
      if (this.getTask(webTaskKey)) return false;
      webTaskData = Object.assign({}, webTaskData);
      Object.entries({
        windowData: {
          title: 'Unnamed window',
          style: {},
          contentComponentName: '',
          receiveWindowRef: (() => {}),
          receiveContentComponentRef: (() => {}),
        },
        taskData: {},
      }).forEach(([key, value]) => {
        webTaskData[key] = Object.assign({}, value, webTaskData[key]);
      });
      webTaskData.webTaskId = nanoid();
      webTaskData.webTaskKey = webTaskKey;
      webTaskData.createdAt = Date.now();
      webTaskData.status = this.CONST_STATUS_RUNNING;
      this.statehookSetPath(['allTasks', webTaskData.webTaskKey], webTaskData);
      this.statehookEmit('on_task_event', {
        taskEvent: 'new_task',
        webTaskKey,
        webTaskData: Object.assign({}, webTaskData),
      });
      return true;
    },
    terminateTask(webTaskKey) {
      const webTaskData = this.allTasks[webTaskKey];
      if (!webTaskData) {
        throw new Error(`Web task '${webTaskKey}' does not exist!`);
      }
      if (webTaskData.status === this.CONST_STATUS_TERMINATED) {
        throw new Error(`Web task '${webTaskKey}' has been terminated!`);
      }
      if ('function' === typeof webTaskData.onTerminate) {
        webTaskData.onTerminate();
      }
      this.statehookRemovePath(['allTasks', webTaskData.webTaskKey]);
      this.statehookEmit('on_task_event', {
        taskEvent: 'terminate_task',
        webTaskKey,
        webTaskData: Object.assign({}, webTaskData),
      });
    },
    onTaskEvent(cb) {
      this.statehookOn('on_task_event', cb);
    },
  }, (statehook) => {
    statehook.statehookSetPath('allTasks', {});
  });
};
