/**
 * @author MrZenW
 * @email MrZenW@Gmail.com, https://MrZenW.com
 * @create date 2022-02-15 15:14:11
 * @modify date 2022-02-15 15:14:11
 * @desc [description]
 */

import {
  parsePath,
  StatehookClass,
  StatehookName,
  StatehookUnwatch,
  StatehookWatchCallback,
} from '$/statehook/typescript';

import { grabZIndexManagerService } from '$/services/z_index_manager_service';

const React = require('react');

const zIndexManagerService = grabZIndexManagerService('kais-solo-global-z-index-manager-service');

const { convertXML2Python } = require('$/libraries/language-converter/BlocklyXML2Python');
const { blocklyPythonService } = require('$/services/blockly_python_service');

export class PythonCodeWindowService extends StatehookClass {
  sessionService: any
  static override grabOne(statehookName: StatehookName) {
    return super.grabOne([].concat(['PythonCodeWindowService'], parsePath(statehookName, '/')));
  }
  _onInit(args: any) {
    this.sessionService = args.sessionService;
  }
  get webTaskManagerService(): any {
    return this.sessionService.webTaskManagerService;
  }
  get visible(): boolean {
    return !!this.statehookGetPath('visible');
  }
  set visible(newValue: boolean) {
    this.statehookSetPath('visible', newValue);
  }
  watchVisible(callback: StatehookWatchCallback): StatehookUnwatch {
    return this.statehookWatchPath('visible', callback);
  }
  get xml(): string {
    return this.statehookGetPath('xml');
  }
  set xml(newValue: string) {
    if (blocklyPythonService.allBlockDefinitions) {
      console.log(this.xmlSource, this.pythonCodeSource, 'source 1');
      if (this.xmlSource !== 'python') { // only change the python code from python editor
        const pythonCode = convertXML2Python(newValue, blocklyPythonService.allBlockDefinitions);
        this.changePythonCode(
          pythonCode,
          this.xmlSource,
        );
      }
    }
    this.statehookSetPath('xml', newValue);
  }
  xmlSource: 'python' | 'user'
  changeXML(xml: string, source: 'user' | 'python') {
    this.xmlSource = source;
    this.xml = xml;
  }
  watchXml(callback: StatehookWatchCallback): StatehookUnwatch {
    return this.statehookWatchPath('xml', () => {
      callback(undefined, this.xml, this.xmlSource);
    });
  }
  get pythonCode(): string {
    return this.statehookGetPath('pythonCode');
  }
  set pythonCode(newValue: string) {
    this.statehookSetPath('pythonCode', newValue);
  }
  pythonCodeSource: 'python' | 'user'
  changePythonCode(pythonCode: string, source: 'user' | 'python') {
    this.pythonCodeSource = source;
    this.pythonCode = pythonCode;
  }
  watchPythonCode(callback: StatehookWatchCallback): StatehookUnwatch {
    return this.statehookWatchPath('pythonCode', () => {
      callback(undefined, this.pythonCode, this.pythonCodeSource);
    });
  }
  toggle() {
    if (this.visible) {
      this.close();
    } else {
      this.open();
    }
  }
  close() {
    this.visible = false;
    this.webTaskManagerService.terminateTask('PythonCodeWindow');
  }
  open() {
    this.visible = true;
    this.webTaskManagerService.newTask('PythonCodeWindow', {
      windowData: {
        title: 'Python Editor',
        contentComponentName: 'PythonCodeWindowView',
        unresizeable: false,
        unminimiseable: true,
        style: {
          position: 'absolute',
          // top: '0px',
          left: 'calc(50% + 10px)',
          top: '10px',
          // right: '0px',
          minWidth: '600px',
          minHeight: '400px',
          maxWidth: '100%',
          maxHeight: '100%',
          width: 'calc(50% - 20px)',
          height: 'calc(100% - 200px)',
          zIndex: '10',
          backgroundColor: 'var(--theme-bg)',
        },
        titleBarStyle: {

        },
        onClose: () => {
          this.webTaskManagerService.terminateTask('PythonCodeWindow');
        },
      },
    });
    setTimeout(() => {
      zIndexManagerService.goTop('PythonCodeWindow');
    //   // webWindowManagerService.goTop(this.windowRef);
    }, 0.1 * 1e3);
  }
}
