/**
 * @author MrZenW
 * @email MrZenW@Gmail.com, https://MrZenW.com
 * @create date 2021-05-25 13:09:20
 * @modify date 2021-05-25 13:24:28
 * @desc [description]
 */
const { statehookify } = require('$/statehook');

const historyStatehook = statehookify('historyStatehook');
window.history.pushState = ((pushState) => {
  return function () {
    const args = Array.from(arguments);
    const returnV = pushState.apply(this, args);
    historyStatehook.statehookEmit.apply(historyStatehook, [].concat(['pushState'], args));
    return returnV;
  };
})(window.history.pushState);

module.exports = historyStatehook;
