/**
 * @author MrZenW
 * @email MrZenW@Gmail.com, https://MrZenW.com
 * @create date 2021-07-23 16:18:42
 * @modify date 2021-07-23 16:18:42
 * @desc [description]
 */

import { ApiService, HttpResponse, HttpResponseData } from '../api_service';

export interface UserDoc {
  _id: string,
  email: string,
  subscribedAt?: string | null,
  subscriptionLifetime?: number,
  [propName: string]: any,
}

export interface UserSecurityDoc {
  [propName: string]: any,
}

export interface AdminDoc {
  role: string,
  [propName: string]: any,
}

export interface UserInfoResponseData extends HttpResponseData {
  userDoc: UserDoc,
  userSecurityDoc?: UserSecurityDoc,
  adminDoc?: AdminDoc,
  adminRole?: string,
}

export interface UserInfoResponse extends HttpResponse {
  data: UserInfoResponseData,
}

export interface UserRegistrationData {
  email: string,
  password: string,
  googleReCaptchaToken?: string,
}

export interface UserLoginData {
  email: string,
  password: string,
  googleReCaptchaToken?: string,
}

// google oauth
export interface GoogleOAuthDoc {
  sub: string,
  accessToken: string,
  email: string,
  emailVerified: boolean,
  familyName: string,
  givenName: string,
  googleId: string,
  picture: string,
  locale: string,
  name: string,
  hd: string,
  tokenId: string,
  tokenObj: any,
  classroomOAuthCode: string,
  classroomOAuthToken: string,
}

export interface GoogleClassroomAuthorizeResponseData extends HttpResponseData {
  googleOAuthDoc: GoogleOAuthDoc,
}

export interface GoogleClassroomAuthorizeResponse extends HttpResponse {
  data: GoogleClassroomAuthorizeResponseData,
}

export interface RecoverEmailDoc {
  userId: string,
  recoverCode?: string,
}

export interface SendRecoverEmailResponseData extends HttpResponseData {
  recoverEmailDoc: RecoverEmailDoc,
}
export interface SendRecoverEmailResponse extends HttpResponse {
  data: SendRecoverEmailResponseData,
}

export interface ResetPasswordResponseData extends HttpResponseData {
  ok: boolean,
}
export interface ResetPasswordResponse extends HttpResponse {
  data: ResetPasswordResponseData,
}

export interface GoogleClassroomCourseItem {
  alternateLink: string // "https://classroom.google.com/c/MTQ1ODUyMDY1ODUx"
  calendarId: string // "c_classroom6139b004@group.calendar.google.com"
  courseGroupEmail: string // "test_eae1b351@3dprintingsystems.com"
  courseState: string // "ACTIVE"
  creationTime: string // "2020-09-22T04:43:08.536Z"
  descriptionHeading: string // "test"
  enrollmentCode: string // "cml7nsc"
  guardiansEnabled: boolean // false
  id: string // "145852065851"
  name: string // "test"
  ownerId: string // "103822316527998172136"
  teacherFolder: {
    alternateLink: string // "https://drive.google.com/drive/folders/0B7ucDpnWNIcUfjFDYWRUanRJa29IcTFFMk0tM2VwQm9Oam9UV3BBWXhUMXhLZ3R2UnJ0M2s?resourcekey=0-EaT3hT97Xa_mazYtlVmxqQ"
    id: string // "0B7ucDpnWNIcUfjFDYWRUanRJa29IcTFFMk0tM2VwQm9Oam9UV3BBWXhUMXhLZ3R2UnJ0M2s"
    title: string // "test"
    [propName: string]: any
  }
  teacherGroupEmail: string // "test_teachers_8b968f74@3dprintingsystems.com"
  updateTime: string // "2021-12-09T21:36:09.928Z"
}
export interface GoogleClassroomCourseListResponseData extends HttpResponseData {
  courseList: GoogleClassroomCourseItem[],
}

export interface GoogleClassroomCourseListResponse extends HttpResponse {
  data: GoogleClassroomCourseListResponseData,
}

// student
export interface GoogleClassroomStudentItem {
  courseId: string // "145852065851"
  profile: {
    emailAddress: string // "bruce@3dprintingsystems.com"
    id: string // "116563919706397479378"
    name: {
      familyName: string // "Jackson"
      fullName: string // "Bruce Jackson"
      givenName: string // "Bruce"
      [propName: string]: any,
    }
    permissions: {
      permission: string // "CREATE_COURSE"
      [propName: string]: any,
    }[]
    photoUrl: string // "//lh3.googleusercontent.com/a-/AOh14GhHjXbw-I5COAG2Y7ApsKAydFU70W5LuOTyRKKkjo4"
    [propName: string]: any,
  }
  userId: string // "116563919706397479378"
  [propName: string]: any,
}
export interface GoogleClassroomStudentListResponseData extends HttpResponseData {
  studentList: GoogleClassroomStudentItem[]
}

export interface GoogleClassroomStudentListResponse extends HttpResponse {
  data: GoogleClassroomStudentListResponseData,
}

export class UserApiClass {
  apiService: ApiService = null;
  constructor(apiService: ApiService) {
    this.apiService = apiService;
  }
  getMyUserInfo(): Promise<UserInfoResponse> {
    return this.apiService.clientGet('/user/myDoc') as Promise<UserInfoResponse>;
  }
  registerUser(userRegistrationData: UserRegistrationData): Promise<UserInfoResponse> {
    return this.apiService.clientPost('/user/sign-up', null, userRegistrationData, {
      responseErrorUse: false,
    }) as Promise<UserInfoResponse>;
  }
  login(userLoginData: UserLoginData): Promise<UserInfoResponse> {
    return this.apiService.clientPost('/user/login', null, userLoginData, {
      responseErrorUse: false,
    }) as Promise<UserInfoResponse>;
  }
  logout(): Promise<HttpResponse> {
    return this.apiService.clientGet('/user/logout') as Promise<HttpResponse>;
  }
  loginWithGoogle(googleLoginInfo: any): Promise<UserInfoResponse> {
    return this.apiService.clientPost('/user/login-with-google', null, googleLoginInfo) as Promise<UserInfoResponse>;
  }
  googleClassroomAuthorize(oAuthCode: string): Promise<GoogleClassroomAuthorizeResponse> {
    return this.apiService.clientGet(`/user/google-classroom/authorize`, { oAuthCode }) as Promise<GoogleClassroomAuthorizeResponse>;
  }
  googleClassroomCourseList(): Promise<GoogleClassroomCourseListResponse> {
    return this.apiService.clientGet('/user/google-classroom/courses') as Promise<GoogleClassroomCourseListResponse>;
  }
  googleClassroomStudentList(googleCourseId: string, reportError: boolean = false): Promise<GoogleClassroomStudentListResponse> {
    const option: any = {};
    if (reportError) {
      option.responseErrorUse = false;
    }
    return this.apiService.clientGet(`/user/google-classroom/courses/${googleCourseId}/students`, null, null, option) as Promise<GoogleClassroomStudentListResponse>;
  }

  sendRecoverEmail(email: string): Promise<SendRecoverEmailResponse> {
    return this.apiService.clientGet(`/user/send-recover-email`, { email }, null, {
      responseErrorUse: false,
    }) as Promise<SendRecoverEmailResponse>;
  }
  setNewPasswordByRecover(code: string, newPassword: string): Promise<ResetPasswordResponse> {
    return this.apiService.clientGet(`/user/recover-new-pwd`, { code, newPassword }, null, {
      responseErrorUse: false,
    }) as Promise<ResetPasswordResponse>;
  }
  setNewPasswordBySession(currentPassword: string, newPassword: string): Promise<ResetPasswordResponse> {
    return this.apiService.clientGet(`/user/change-pwd`, { currentPassword, newPassword }, null, {
      responseErrorUse: false,
    }) as Promise<ResetPasswordResponse>;
  }
}
