/**
 * @author MrZenW
 * @email MrZenW@gmail.com, https://MrZenW.com
 * @create date 2022-09-05 16:55:00
 * @modify date 2022-09-05 16:55:00
 * @desc [description]
 */

/* eslint-disable react/jsx-curly-brace-presence */

import React from 'react';
import Switch from 'react-switch';
import { SessionContext } from '$/components/session_context';
import { RoomContext } from '$/components/room_context';
import { RoomServiceClass } from '$/services/room_service';
import { BootstrapTooltip } from '$/components/bootstrap-tooltip';
import { createDebounce } from '$/libraries/debounce';

const lockIcon = require('$/assets/images/lock.svg');
const unlockIcon = require('$/assets/images/un-lock.svg');

export const BlocklyWindowTitle = () => {
  const { sessionService: { uiService } } = React.useContext<any>(SessionContext);
  const roomContext = React.useContext<any>(RoomContext);
  const roomService = roomContext.roomService as RoomServiceClass;
  const [checked, setChecked] = React.useState<boolean>(Boolean(uiService.isBlocklyReadOnly));
  function setBlocklyReadOnly(newChecked: boolean) {
    uiService.isBlocklyReadOnly = newChecked;
  }
  React.useEffect(() => {
    const unwatchBlocklyReadOnly = uiService.watchBlocklyReadOnly(() => {
      setChecked(uiService.isBlocklyReadOnly);
    });
    const unwatchRoomDoc = roomService.watchRoomDoc(() => {
      uiService.isBlocklyReadOnly = !!roomService.roomDoc.isBlocklyReadOnly;
    });
    const unwatchMqtt = roomService.mqttBlocklyReadOnlySubscribe((receivedTopic, receivedPayload, receivedPacket, payloadJSON) => {
      if (payloadJSON.isBlocklyReadOnly !== uiService.isBlocklyReadOnly) {
        uiService.isBlocklyReadOnly = Boolean(payloadJSON.isBlocklyReadOnly);
        if (uiService.isBlocklyReadOnly) {
          alertLib.alertNotification('Blockly workspace has been locked by the host!');
        } else {
          alertLib.alertNotification('Blockly workspace has been unlocked by the host!');
        }
      }
    });
    // init `isBlocklyReadOnly`
    uiService.isBlocklyReadOnly = !!roomService.roomDoc.isBlocklyReadOnly;
    return () => {
      unwatchBlocklyReadOnly();
      unwatchRoomDoc();
      unwatchMqtt();
    };
  }, []);
  const height = 20;
  return (
    <div className="d-flex justify-content-between align-items-center">
      <div style={{ width: '33%' }}>&nbsp;</div>
      <div style={{ width: '33%' }}>Blockly</div>
      <div style={{ width: '33%', paddingRight: '10px' }} className="d-flex justify-content-end">
        <BootstrapTooltip
          data-bs-placement="bottom"
          data-bs-toggle="popover"
          data-bs-trigger="hover focus"
          data-bs-content={roomService.isOwner ? 'Lock/Unlock Blockly window' : uiService.isBlocklyReadOnly ? 'Blockly locked by teacher account, Pyhton only.' : 'Blockly unlocked'}
          // container="parent"
        >
          <Switch
            disabled={!roomService.isOwner}
            handleDiameter={16}
            onColor="#2f71f4"
            onChange={createDebounce(async (nextChecked: boolean) => {
              if (roomService.isOwner) {
                await setBlocklyReadOnly(nextChecked);
                await roomService.apiSetBlocklyReadOnly(nextChecked);
                // send mqtt message to the roomwide
                roomService.mqttBlocklyReadOnlyPublishByHost(nextChecked);
              }
            }, 0.5 * 1e3)}
            checked={checked}
            // className="react-switch"
            width={40}
            height={height}
            checkedIcon={(
              <div
                style={{
                  // width: '50px',
                  height: `${height}px`,
                  // lineHeight: '35px',
                  fontSize: '1rem',
                  // marginLeft: '5px',
                  color: 'white',
                }}
                className="d-flex align-items-center justify-content-center"
              >
                <img style={{ height: '80%' }} src={lockIcon} alt="lockIcon" />
              </div>
            )}
            uncheckedIcon={(
              <div
                style={{
                  // width: '50px',
                  height: `${height}px`,
                  // lineHeight: '35px',
                  fontSize: '1rem',
                  // marginLeft: '-50px',
                  color: 'white',
                }}
                className="d-flex align-items-center justify-content-center"
              >
                <img style={{ height: '80%' }} src={unlockIcon} alt="unlockIcon" />
              </div>
            )}
          />
        </BootstrapTooltip>
      </div>
    </div>
  );
};
