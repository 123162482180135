/**
 * @author MrZenW
 * @email MrZenW@Gmail.com, https://MrZenW.com
 * @create date 2021-05-25 13:09:41
 * @modify date 2021-05-25 13:09:41
 * @desc [description]
 */
const React = require('react');
const { useStatehook } = require('$/statehook');
const historyStatehook = require('./historyStatehook');

module.exports = (props) => {
  useStatehook({ props }, (inputArgs, forceUpdate, thisStatehook) => {
    thisStatehook.statehookRegisterUnwatch(historyStatehook.statehookOn('pushState', forceUpdate));
    return () => {
      thisStatehook.statehookDiscard();
    };
  }, React);
  const { children } = props;
  const childrenArray = (Array.isArray(children)) ? children : [children];
  const { pathname } = window.location;
  // console.log(children, 'children');
  return (
    <>
      {childrenArray.find((c) => {
        let matched = null;
        if (typeof c === 'object' && typeof c.props === 'object') {
          const embedFactor = pathname;
          const { embedTest } = c.props;
          if (embedTest instanceof RegExp) {
            matched = embedTest.exec(embedFactor);
          } else if (typeof embedTest === 'function') {
            matched = embedTest();
          } else if (embedFactor === embedTest) {
            matched = embedFactor;
          }
        }
        return !!matched;
      })}
    </>
  );
};
