/**
 * @author MrZenW
 * @email MrZenW@Gmail.com, https://MrZenW.com
 * @create date 2022-02-04 15:10:53
 * @modify date 2022-02-04 15:10:53
 * @desc [description]
 */

const React = require('react');
const bootstrap = require('bootstrap/dist/js/bootstrap');

// https://stackoverflow.com/a/384380
function isElement(obj: any) {
  try {
    // Using W3 DOM2 (works for FF, Opera and Chrome)
    return obj instanceof HTMLElement;
  } catch (e) {
    // Browsers not supporting W3 DOM2 don't have HTMLElement and
    // an exception is thrown and we end up here. Testing some
    // properties that all elements have (works on IE7)
    return (typeof obj === 'object') &&
      (obj.nodeType === 1) && (typeof obj.style === 'object') &&
      (typeof obj.ownerDocument === 'object');
  }
}

export const BootstrapTooltip = (props: any) => {
  let { children, container } = props;
  children = children || null;
  const ref = React.useRef();
  const [tooltipInstance] = React.useState({});
  // evertimes re-render
  React.useEffect(() => {
    if (tooltipInstance.current) {
      tooltipInstance.current.dispose();
    }
    const option: any = {};
    if (container) {
      if (typeof container === 'function') {
        container = container();
      }
      if (typeof container === 'string') {
        if (container === 'parent' && ref && ref.current) {
          container = ref.current.parentElement;
        } else {
          container = document.querySelector(container);
        }
      }
      if (isElement(container)) {
        option.container = container;
      }
    }
    tooltipInstance.current = new bootstrap.Popover(ref.current, option);
  });
  // once when init
  React.useEffect(() => {
    return () => {
      if (tooltipInstance.current) {
        tooltipInstance.current.dispose();
      }
    };
  }, []);
  const propsProcessed = Object.assign({}, props);
  delete propsProcessed.children;
  delete propsProcessed.container;
  return (
    <div {...propsProcessed} ref={ref}>
      {children}
    </div>
  );
};
