/**
 * @author MrZenW
 * @email MrZenW@Gmail.com, https://MrZenW.com
 * @create date 2021-10-27 16:23:21
 * @modify date 2021-10-27 16:23:21
 * @desc [description]
 */

/* eslint-disable jsx-a11y/label-has-associated-control */

import React from 'react';
import { createUseStyles } from 'react-jss';
import cx from 'classnames';
import { LogStructure } from '$/services/blockly_console_service';
import { SessionContext } from '../session_context';
import { BlocklyConsoleWindowModel } from './blockly_console_window.model';

const useStyles = createUseStyles({
  log: {
    padding: '10px',
  },
  logGrey: {
    backgroundColor: 'rgba(128, 128, 128, 0.3)',
  },
});

export const BlocklyConsoleWindowView = (props: any) => {
  const statehook: BlocklyConsoleWindowModel = BlocklyConsoleWindowModel.useStatehook({
    props,
    sessionContext: React.useContext(SessionContext),
  }, React);
  const style = useStyles();
  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        // minWidth: '250px',
        minHeight: '250px',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <div
        style={{
          overflowY: 'scroll',
          overflowX: 'clip',
          width: '100%',
          height: 'calc(100% - 30px)',
          // minWidth: '250px',
          // minHeight: '250px',
        }}
        ref={(ref: any) => {
          statehook.receiveLogContainerRef(ref);
        }}
      >
        {statehook.blocklyConsoleService.logData.map((row: LogStructure, idx: number) => {
          return (
            <div
              key={row.id}
              className={cx(style.log, {
                [style.logGrey]: idx % 2,
              })}
            >
              {row.log}
            </div>
          );
        })}
      </div>
      <div
        style={{
          width: '100%',
          height: '30px',
          // minWidth: '250px',
          // minHeight: '250px',
          padding: '3px',
        }}
      >
        <input
          type="checkbox"
          // name="email"
          className="form-check-input"
          id="blockly-console-autoscroll"
          checked={statehook.isAutoScroll}
          onChange={() => {
            statehook.onChangeAutoScroll();
          }}
        />
        <label htmlFor="blockly-console-autoscroll" className="form-label">Auto scroll</label>
      </div>
    </div>
  );
};
