/**
 * @author MrZenW
 * @email MrZenW@Gmail.com, https://MrZenW.com
 * @create date 2021-10-27 16:21:43
 * @modify date 2021-10-27 16:21:43
 * @desc [description]
 */

import { BlocklyConsoleService } from '$/services/blockly_console_service';
import { UseStatehookClass } from '$/statehook/typescript';

export class BlocklyConsoleWindowModel extends UseStatehookClass {
  sessionService: any
  blocklyConsoleService: BlocklyConsoleService
  logComponentCache: any[]
  isAutoScroll: boolean = true
  logContainerRef: any = null
  _useStatehookInit(inputArgs: any) {
    const { sessionContext } = inputArgs;
    this.sessionService = sessionContext.sessionService;
    this.blocklyConsoleService = this.sessionService.blocklyConsoleService as BlocklyConsoleService;
    this.statehookRegisterUnwatch([
      this.blocklyConsoleService.watchLog(() => {
        this._useStatehookForceUpdate();
      }),
    ]);
  }
  protected _useStatehookAfterRender(): void {
    if (this.isAutoScroll) {
      this.logContainerRef.scrollTop = this.logContainerRef.scrollHeight;
    }
  }
  receiveLogContainerRef(ref: any) {
    this.logContainerRef = ref;
  }
  onChangeAutoScroll() {
    // this.isAutoScroll = event.target.checked;
    this.isAutoScroll = !this.isAutoScroll;
    this._useStatehookForceUpdate();
  }
}
