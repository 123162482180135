/**
 * @author MrZenW
 * @email MrZenW@Gmail.com, https://MrZenW.com
 * @create date 2021-05-25 13:12:05
 * @modify date 2022-09-02 17:45:44
 * @desc [description]
 */

const sweetalert2 = require('sweetalert2');
const withReactContent = require('sweetalert2-react-content');
const { animateFrame, easeOutSine } = require('./animate');
const { createElement } = require('./html_element_creator');
// const { gradientCSSLinearGradient } = require('$/libraries/gradientColour');

const alertQueue = [];

let reactSwal = null;

let inTheProcess = false;
function _processQueue() {
  if (inTheProcess) return;
  inTheProcess = true;
  const one = alertQueue.shift();
  if (!one) {
    inTheProcess = false;
  } else {
    reactSwal.fire(one.arg)
      .then((res) => {
        one.callback(res);
        inTheProcess = false;
        _processQueue();
        return res;
      });
  }
}

function _putToQueue(arg) {
  return new Promise((resolve) => {
    alertQueue.push({
      arg,
      callback: resolve,
    });
    _processQueue();
  });
}

function alertSuccess(arg1) {
  const args = (typeof arg1 === 'string') ? { title: arg1 } : arg1;
  const {
    title,
    icon,
  } = args;
  arg1 = {
    ...args,
    icon: icon || 'success',
    title,
  };
  return _putToQueue(arg1);
  // return sweetalert2.fire({
  //   ...args,
  //   icon: icon || 'success',
  //   title,
  // });
}
sweetalert2.alertSuccess = alertSuccess;

function alertError(arg1) {
  if (arg1 instanceof Error) {
    arg1 = arg1.message;
  }
  const args = (typeof arg1 === 'string') ? { title: arg1 } : arg1;
  const {
    title,
    icon,
  } = args;
  arg1 = {
    ...args,
    icon: icon || 'error',
    title,
  };
  return _putToQueue(arg1);
  // return sweetalert2.fire({
  //   ...args,
  //   icon: icon || 'error',
  //   title,
  // });
}
sweetalert2.alertError = alertError;

function alertWarning(arg1) {
  const args = (typeof arg1 === 'string') ? { title: arg1 } : arg1;
  const {
    title,
    icon,
  } = args;
  arg1 = {
    ...args,
    icon: icon || 'warning',
    title,
  };
  return _putToQueue(arg1);
  // return sweetalert2.fire({
  //   ...args,
  //   icon: icon || 'warning',
  //   title,
  // });
}
sweetalert2.alertWarning = alertWarning;

function alertInfo(arg1) {
  const args = (typeof arg1 === 'string') ? { title: arg1 } : arg1;
  const {
    title,
    icon,
  } = args;
  arg1 = {
    ...args,
    icon: icon || 'info',
    title,
  };
  return _putToQueue(arg1);
  // return sweetalert2.fire({
  //   ...args,
  //   icon: icon || 'info',
  //   title,
  // });
}
sweetalert2.alertInfo = alertInfo;

let notificationRootContainerElement = null;
const initNotificationElement = () => {
  if (!notificationRootContainerElement) {
    notificationRootContainerElement = createElement(`
    <div>
    </div>
    `);
    Object.assign(notificationRootContainerElement.style, {
      position: 'absolute',
      inset: '0px 0px 0px auto',
      overflowX: 'clip',
      overflowY: 'clip',
      maxHeight: '100%',
      height: 'fit-content',
      width: 'fit-content',
      zIndex: '9999',
      paddingTop: '15px',
    });
    document.body.appendChild(notificationRootContainerElement);
  }
  return notificationRootContainerElement;
};
async function alertNotification(arg1) {
  const args = (typeof arg1 === 'string') ? { title: arg1 } : arg1;
  const {
    title,
    icon,
    position,
    timer,
  } = args;
  arg1 = {
    ...args,
    toast: true,
    timerProgressBar: true,
    position: position || 'center',
    icon: icon || 'info',
    // backdrop: true,
    // background: '#000000',
    title,
    showConfirmButton: false,
    timer: timer || 5 * 1e3,
  };
  const container = initNotificationElement();
  // icon
  let iconHtml = '';
  switch (arg1.icon.toLowerCase()) {
    case 'error':
      iconHtml = '<i style="color: red; font-size: 2rem" class="fas fa-times-circle"></i>';
      break;
    case 'success':
      iconHtml = '<i style="color: green; font-size: 2rem" class="fas fa-check-circle"></i>';
      break;
    case 'warning':
      iconHtml = '<i style="color: orange; font-size: 2rem" class="fas fa-exclamation-circle"></i>';
      break;
    case 'question':
      iconHtml = '<i style="color: green; font-size: 2rem" class="fas fa-question-circle"></i>';
      break;
    case 'info':
    default:
      iconHtml = '<i style="color: green; font-size: 2rem" class="fas fa-info-circle"></i>';
      break;
  }
  // insert element
  const padding = 20;
  const flexStyle = 'display: flex; justify-content: flex-start; align-items: center';
  const toastElementContainer = createElement(`
    <div style="height: fit-content; width: fit-content; overflow: visible; margin: 0px 0px 0px auto;">
      <div style="margin: 0px ${padding}px; overflow: hidden; width: fit-content; height: fit-content; transform: translateX(110%); border: 1px solid rgba(128, 128, 128, 0.5); border-radius: 10px; box-shadow: 0px 0px 15px rgb(0, 0, 0); color: var(--theme-color)" class="theme-bg-glass" role="alert" aria-live="assertive" aria-atomic="true">
        <div style="min-height: 75px; min-width: 350px; ${flexStyle}">
          <div style="width: 50px; text-align: right;">
            ${iconHtml}
          </div>
          <div style="width: 100%; padding-left: 10px; padding-right: 20px">
            <div style="margin: 0.618rem auto; width: fit-content;">
              ${arg1.html || arg1.title}
            </div>
          </div>
        </div>
        <div alert-lib-progress-bar style="position: relative; left: 0px; bottom: 0px; width: 100%; height: 5px; background-color: rgba(128, 128, 128, 0.3)">
        </div>
      </div>
    </div>
  `);
  const toastElement = toastElementContainer.querySelector('div');
  container.insertBefore(toastElementContainer, container.childNodes[0]);

  const currentStyle = toastElementContainer.currentStyle || window.getComputedStyle(toastElementContainer);
  const heightPx = parseInt(currentStyle.height, 10) || 0;
  toastElementContainer.style.height = '0px';

  let showStatus = true;
  setTimeout(() => {
    container.style.minHeight = heightPx + (padding * 2) + 'px';
    animateFrame((p) => {
      const pEase = easeOutSine(p);
      // container
      const _h = heightPx + (padding * 1); // important, for the box-shadow at the bottom
      // const _h = heightPx;
      // const _w = widthPx + (padding * 2);
      const value = parseInt(pEase * (_h - 0) + 0, 10);
      toastElementContainer.style.height = value + 'px';

      // element
      const translateX = (pEase * (0 - 110) + 110);
      toastElement.style.transform = 'translateX(' + translateX + '%)';
    }, 0.3 * 1e3);
  }, 0);
  // listen to the event for hiding
  return new Promise((resolve) => {
    async function hide() {
      if (showStatus) {
        showStatus = false;
        // toastElementContainer.style.transition = 'height 0.3s';
        await new Promise((animationResolve) => {
          const toastElementContainerCurrentStyle = toastElementContainer.currentStyle || window.getComputedStyle(toastElementContainer);
          const toastElementContainerHeight = parseInt(toastElementContainerCurrentStyle.height, 10) || 0;
          animateFrame((p) => {
            const pEase = easeOutSine(p);
            const translateX = (pEase * (110 - 0) + 0);
            toastElement.style.transform = 'translateX(' + translateX + '%)';
            toastElement.style.opacity = 1 - pEase;
            toastElementContainer.style.height = `${parseInt(toastElementContainerHeight * (1 - p), 10)}px`;
            if (p === 1) {
              animationResolve();
            }
          }, 0.3 * 1e3);
        });
        // animation finished
        container.removeChild(toastElementContainer);
        container.style.minHeight = '0px';
        resolve();
      }
    }
    const progressBar = toastElementContainer.querySelector('[alert-lib-progress-bar]');
    animateFrame((p) => {
      const _progress = (1 - p) * 100;
      progressBar.style.width = _progress + '%';
      if (p === 1) {
        hide();
      }
    }, arg1.timer);
    // setTimeout(hide, arg1.timer);
  });
}

/*
function alertNotification_discarded(arg1) {
  const args = (typeof arg1 === 'string') ? { title: arg1 } : arg1;
  const {
    title,
    icon,
    position,
    timer,
  } = args;
  arg1 = {
    ...args,
    toast: true,
    timerProgressBar: true,
    position: position || 'center',
    icon: icon || 'info',
    // backdrop: true,
    // background: '#000000',
    title,
    showConfirmButton: false,
    timer: timer || 3 * 1e3,
  };
  return _putToQueue(arg1);
  // return sweetalert2.fire({
  //   ...args,
  //   toast: true,
  //   timerProgressBar: true,
  //   position: position || 'center',
  //   icon: icon || 'info',
  //   // backdrop: true,
  //   // background: '#000000',
  //   title,
  //   showConfirmButton: false,
  //   timer: timer || 3 * 1e3,
  // });
}
*/
sweetalert2.alertNotification = alertNotification;

function alertConfirm(arg1) {
  const args = (typeof arg1 === 'string') ? { title: arg1 } : arg1;
  arg1 = Object.assign({
    icon: 'question',
    showCancelButton: true,
    confirmButtonText: 'Yes',
  }, args);
  return _putToQueue(arg1);
  // return sweetalert2.fire({
  //   icon: icon || 'question',
  //   title,
  //   showCancelButton: true,
  //   confirmButtonText: confirmButtonText || 'Yes',
  // });
}
sweetalert2.alertConfirm = alertConfirm;

sweetalert2.fire = ((fn) => {
  fn = fn || (() => {});
  return function () {
    let args = Array.from(arguments);
    let opt = args[0];
    if (typeof opt === 'string') {
      opt = {
        title: opt,
      };
    }
    // opt.background = opt.background || '#fff ' + gradientCSSLinearGradient(null, 5, 0.5, {
    //   rMin: 150,
    //   rMax: 255,
    //   gMin: 150,
    //   gMax: 255,
    //   bMin: 150,
    //   bMax: 255,
    // });
    args[0] = opt;
    return fn.apply(this, args);
  };
})(sweetalert2.fire);

sweetalert2.sweetAlert = function sweetAlert(arg1) {
  return _putToQueue(arg1);
};

sweetalert2.reactFire = function reactFire(arg) {
  return _putToQueue(arg);
};

reactSwal = withReactContent(sweetalert2);

window.alertLib = sweetalert2;
module.exports = sweetalert2;
