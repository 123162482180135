/**
 * @author MrZenW
 * @email MrZenW@Gmail.com, https://MrZenW.com
 * @create date 2021-05-25 13:11:48
 * @modify date 2021-05-25 13:11:48
 * @desc [description]
 */
const React = require('react');
const PropTypes = require('prop-types');
const cx = require('classnames');
const { createUseStyles } = require('react-jss');
const { nanoid } = require('nanoid');
const { animateFrame } = require('$/libraries/animate');
const { useStatehook } = require('$/statehook');
const moveable = require('$/libraries/moveable');
const resizeable = require('$/libraries/resizeable');
const { gradientCSSLinearGradient } = require('$/libraries/gradientColour');
const webWindowManagerService = require('$/services/web_window_manager_service');
const themeService = require('$/services/theme_service');
const { getObviousColourBy } = require('$/libraries/colour_calculator');
// const maximiseIcon = require('$/assets/images/web_window/maximise.svg');
// const minimiseIcon = require('$/assets/images/web_window/minimise.svg');

const minimiseBlackIcon = require('$/assets/images/web_window/minimise_black_icon.svg');
const minimiseWhiteIcon = require('$/assets/images/web_window/minimise_white_icon.svg');

const restoreBlockIcon = require('$/assets/images/web_window/restore_black_icon.svg');
const restoreWhiteIcon = require('$/assets/images/web_window/restore_white_icon.svg');

const restoreHolderIcon = require(`!!raw-loader?esModule=false!$/assets/images/web_window/restore_holder_icon.svg`);
const minimiseHolderIcon = require(`!!raw-loader?esModule=false!$/assets/images/web_window/minimise_holder_icon.svg`);

const { grabZIndexManagerService } = require('$/services/z_index_manager_service');
const { SessionContext } = require('./session_context');

const zIndexManagerService = grabZIndexManagerService('kais-solo-global-z-index-manager-service');

const debug = debugLib(import.meta.url);

const WebWindowContext = React.createContext({});

function lowercaseEquals() {
  let args = Array.from(arguments);
  if (Array.isArray(args[0])) args = args[0];
  const one = `${args[0]}`.toLowerCase();
  return !args.find((s) => {
    return `${s}`.toLowerCase() !== one;
  });
}

const WINDOW_STATUS_NORMAL = 'WINDOW_STATUS_NORMAL';
const WINDOW_STATUS_MINIMISED = 'WINDOW_STATUS_MINIMISED';
const WINDOW_STATUS_MAXIMISED = 'WINDOW_STATUS_MAXIMISED';

const actions = {
  titleBarRightButtonGroup: [],
  titleBarStyle: {},
  titleBarComponent: null,
  sessionService: null,
  uiService: null,
  _useStatehookInit(args) {
    this.sessionService = args.sessionContext.sessionService;
    this.uiService = this.sessionService.uiService;

    const { webTaskKey } = args.props;
    const currentStatehook = webWindowManagerService.getWebWindowStatehook(webTaskKey);
    if (currentStatehook) {
      throw new Error('Window ' + webTaskKey + ' has already existed!');
    }
    this.isInit = true;
    this.globalOverlayerId = nanoid();
    webWindowManagerService.setWebWindowStatehook(webTaskKey, this);
    zIndexManagerService.initManagement(webTaskKey);
    // listen to the theme change
    this.changeTitleBarControlIcon();
    this.statehookRegisterUnwatch(themeService.onThemeChange(() => {
      this.changeTitleBarControlIcon();
      this._useStatehookForceUpdate();
    }));
    this.statehookWatchPath('isFocused', () => {
      this.changeTitleBarControlIcon();
      this._useStatehookForceUpdate();
    });

    return () => {
      webWindowManagerService.setWebWindowStatehook(webTaskKey, null);
      zIndexManagerService.deleteManagementId(webTaskKey);
      setTimeout(() => {
        this._showOverlayer(false);
      }, 0.3 * 1e3);
    };
  },
  setTitleBarRightButtonGroup(buttons) {
    this.titleBarRightButtonGroup = buttons;
    // this._useStatehookForceUpdate();
  },
  setTitleBarStyle(style) {
    this.titleBarStyle = style;
  },
  setTitleBarComponent(component) {
    this.titleBarComponent = component;
  },
  changeTitleBarControlIcon() {
    if (this.useStatehookArgs && this.useStatehookArgs.props) {
      const { isFocused } = this;
      const { props } = this.useStatehookArgs;
      if (isFocused && props.titleColourFocused) {
        this.maximiseIcon = 'data:image/svg+xml;base64,' + btoa(`${restoreHolderIcon}`.replace(/#HOLDER/g, getObviousColourBy(props.titleColourFocused)));
        this.minimiseIcon = 'data:image/svg+xml;base64,' + btoa(`${minimiseHolderIcon}`.replace(/#HOLDER/g, getObviousColourBy(props.titleColourFocused)));
        return;
      }
    }
    this.maximiseIcon = (themeService.isDarkTheme) ? restoreWhiteIcon : restoreBlockIcon;
    this.minimiseIcon = (themeService.isDarkTheme) ? minimiseWhiteIcon : minimiseBlackIcon;
  },
  get isFullScreen() {
    return lowercaseEquals('full-screen', this.useStatehookArgs.props.windowStatus);
  },
  get isFullWindow() {
    return lowercaseEquals('full-window', this.useStatehookArgs.props.windowStatus);
  },
  get isMinimised() {
    return lowercaseEquals('minimised', this.useStatehookArgs.props.windowStatus);
  },
  get isMaximised() {
    return lowercaseEquals('maximised', this.useStatehookArgs.props.windowStatus);
  },
  get isNormal() {
    return lowercaseEquals('normal', this.useStatehookArgs.props.windowStatus);
  },
  get isHidden() {
    return lowercaseEquals('hidden', this.useStatehookArgs.props.windowStatus);
  },
  // window status
  get isWindowNormal() {
    return this.windowStatus === WINDOW_STATUS_NORMAL;
  },
  get isWindowMaximised() {
    return this.windowStatus === WINDOW_STATUS_MAXIMISED;
  },
  get isWindowMinimised() {
    return this.windowStatus === WINDOW_STATUS_MINIMISED;
  },
  set windowStatus(newStatus) {
    return this.statehookSetPath('windowStatus', newStatus);
  },
  get windowStatus() {
    return this.statehookGetPath('windowStatus') || WINDOW_STATUS_NORMAL;
  },
  get isFocused() {
    return this.statehookGetPath('isFocused');
  },
  set isFocused(value) {
    return this.statehookSetPath('isFocused', value);
  },
  setFocusOnIt() {
    const { webTaskKey } = this.useStatehookArgs.props;
    webWindowManagerService.currentFocusedWebTaskKey = webTaskKey;
    zIndexManagerService.goTop(webTaskKey);
  },
  _showOverlayer(show) {
    let globalOverlayer = document.getElementById(this.globalOverlayerId);
    if (show === false) {
      if (globalOverlayer) {
        document.body.removeChild(globalOverlayer);
      }
    } else {
      if (!globalOverlayer) {
        globalOverlayer = document.createElement('div');
        globalOverlayer.setAttribute('id', this.globalOverlayerId);
        document.body.appendChild(globalOverlayer);
      }
      globalOverlayer.style.top = '0px';
      globalOverlayer.style.left = '0px';
      globalOverlayer.style.right = '0px';
      globalOverlayer.style.bottom = '0px';
      globalOverlayer.style.position = 'fixed';
      globalOverlayer.style.zIndex = '9999';
      globalOverlayer.style.display = 'block';
    }
  },
  _attemptMovieableAndResizeable() {
    if (this.windowRef
      && this.moveHandleRef
      && this.resizeHandleRef
      && this.contentRef
      && this.overlayerRef) {
      this._makeMoveable(this.windowRef, this.moveHandleRef);
      if (!this.useStatehookArgs.props.unresizeable) {
        this._makeResizeable(this.windowRef, this.resizeHandleRef);
      }
    }
  },
  _makeMoveable(windowRef, handleRef) {
    const {
      moveableConfig,
    } = this.useStatehookArgs.props;
    let startX;
    let startY;
    const _moveableConfig = Object.assign({}, moveableConfig || {});
    _moveableConfig.onMouseDown = ((func) => {
      func = func || (() => {});
      return (event) => {
        const { x, y } = event;
        startX = x;
        startY = y;
        // ======
        setTimeout(() => {
          this._showOverlayer(true);
        }, 0.3 * 1e3);
        const result = func(event);
        this.statehookEmit('onMoveableMouseDown', event);
        return result;
      };
    })(_moveableConfig.onMouseDown);
    _moveableConfig.onMouseMove = ((func) => {
      func = func || (() => {});
      return (event) => {
        const result = func(event);
        this.statehookEmit('onMoveableMouseMove', event);
        return result;
      };
    })(_moveableConfig.onMouseMove);
    _moveableConfig.onMouseUp = ((func) => {
      func = func || (() => {});
      return (event) => {
        const { x, y, objectRef } = event;
        const $objectRef = $(objectRef);
        const currentStyle = objectRef.currentStyle || window.getComputedStyle(objectRef);
        const marginLeft = parseInt(currentStyle.marginLeft, 10) || 0;
        const marginTop = parseInt(currentStyle.marginTop, 10) || 0;
        const parentStyle = objectRef.parentElement.currentStyle || window.getComputedStyle(objectRef.parentElement);
        const viewPortWidth = parseInt(parentStyle.width, 10) || 0;
        const viewPortHeight = parseInt(parentStyle.height, 10) || 0;
        let adjustToLeft = null;
        let adjustToTop = null;
        if ((x + marginLeft + objectRef.offsetWidth) < 0) {
          adjustToLeft = startX + 'px';
        } else {
          // const marginRight = parseInt(currentStyle.marginRight, 10) || 0;
          // const rightSide = parseInt(viewPortWidth - (objectRef.offsetWidth + marginRight), 10) || 0;
          if (x + marginLeft > viewPortWidth) {
            adjustToLeft = startX + 'px';
          }
        }
        if ((y + marginTop) < 0) {
          adjustToTop = '0px';
        } else {
          // const marginBottom = parseInt(currentStyle.marginBottom, 10) || 0;
          // const bottomSide = parseInt(viewPortHeight - (objectRef.offsetHeight + marginBottom), 10) || 0;
          if (y + marginTop > viewPortHeight) {
            adjustToTop = startY + 'px';
          }
        }
        if (adjustToLeft || adjustToTop) {
          const jqueryAnimation = {};
          if (adjustToLeft) {
            jqueryAnimation.left = adjustToLeft;
          }
          if (adjustToTop) {
            jqueryAnimation.top = adjustToTop;
          }
          $objectRef.animate(jqueryAnimation, 100);
        }

        // ========
        setTimeout(() => {
          this._showOverlayer(false);
        }, 0.3 * 1e3);
        const result = func(event);
        this.statehookEmit('onMoveableMouseUp', event);
        return result;
      };
    })(_moveableConfig.onMouseUp);

    return moveable(windowRef, handleRef, _moveableConfig);
  },
  _makeResizeable(windowRef, handleRef) {
    const {
      resizeableConfig,
    } = this.useStatehookArgs.props;

    const _resizeableConfig = Object.assign({}, resizeableConfig || {});
    _resizeableConfig.onMouseDown = ((func) => {
      func = func || (() => {});
      return (event) => {
        setTimeout(() => {
          this._showOverlayer(true);
        }, 0.3 * 1e3);
        const result = func(event);
        this.statehookEmit('onResizeMouseDown', event);
        return result;
      };
    })(_resizeableConfig.onMouseDown);
    _resizeableConfig.onMouseMove = ((func) => {
      func = func || (() => {});
      return (event) => {
        const result = func(event);
        this.statehookEmit('onResizeMouseMove', event);
        return result;
      };
    })(_resizeableConfig.onMouseMove);
    _resizeableConfig.onMouseUp = ((func) => {
      func = func || (() => {});
      return (event) => {
        setTimeout(() => {
          this._showOverlayer(false);
        }, 0.3 * 1e3);
        const result = func(event);
        this.statehookEmit('onResizeMouseUp', event);
        return result;
      };
    })(_resizeableConfig.onMouseUp);

    return resizeable(windowRef, handleRef, _resizeableConfig);
  },
  _setWindowRef(ref) {
    if (this.windowRef) return;
    this._bindGoTopFeature(ref, 'windowRef');
    this.windowRef = ref;
    this._attemptMovieableAndResizeable();
  },
  _setMoveHandleRef(ref) {
    if (this.moveHandleRef) return;
    this._bindGoTopFeature(ref, 'moveHandleRef');
    this.moveHandleRef = ref;
    this._attemptMovieableAndResizeable();
  },
  _setResizeHandleRef(ref) {
    if (this.resizeHandleRef) return;
    this._bindGoTopFeature(ref, 'resizeHandleRef');
    this.resizeHandleRef = ref;
    this._attemptMovieableAndResizeable();
  },
  _setContentRef(ref) {
    if (this.contentRef) return;
    this._bindGoTopFeature(ref, 'contentRef');
    this.contentRef = ref;
    this._attemptMovieableAndResizeable();
  },
  _setOverlayerRef(ref) {
    if (this.overlayerRef) return;
    this._bindGoTopFeature(ref, 'overlayerRef');
    this.overlayerRef = ref;
    this._attemptMovieableAndResizeable();
  },
  _bindGoTopFeature(ele, ns) {
    const { webTaskKey } = this.useStatehookArgs.props;
    const namespace = ns + '';
    this.statehookUnwatchWithNS(namespace);
    const handle = () => {
      // webWindowManagerService.goTop(this.windowRef);
      // e.stopPropagation();
      // e.preventDefault();
      zIndexManagerService.goTop(webTaskKey);
      webWindowManagerService.currentFocusedWebTaskKey = webTaskKey;
    };
    ele.addEventListener('mousedown', handle, false);
    ele.addEventListener('touchstart', handle, false);
    let lastOverlayerAnimation = null;
    this.statehookRegisterUnwatchWithNS(namespace, [
      () => {
        ele.removeEventListener('mousedown', handle, false);
        ele.removeEventListener('touchstart', handle, false);
      },
    ]);
    this.statehookUnwatchWithNS('onZIndexChanged');
    this.statehookRegisterUnwatchWithNS('onZIndexChanged', [
      // check if it is the max zindex
      zIndexManagerService.onZIndexChanged((eventName, event) => {
        if (event.managementId === webTaskKey && event.currentMaxZIndex === event.zIndex) {
          if (this.windowRef) {
            if (this.isFocused === true) return;
            this.isFocused = true;
            this.overlayerRef.style.display = 'none';
            if (lastOverlayerAnimation) lastOverlayerAnimation();
            this.windowRef.style.transformOrigin = 'center';
            lastOverlayerAnimation = animateFrame((progress) => {
              // this.overlayerRef.style.opacity = (1 - progress) * 0.5;
              const scale = progress * (1 - 0.996) + 0.996;
              this.windowRef.style.transform = `scale(${scale})`;
              if (progress === 1) {
                lastOverlayerAnimation = null;
                // this.overlayerRef.style.display = 'none';
              }
            }, 0.3 * 1e3);
            this._useStatehookForceUpdate();
          }
        } else {
          if (this.isFocused !== true) return;
          this.isFocused = false;
          this.overlayerRef.style.display = '';
          if (lastOverlayerAnimation) lastOverlayerAnimation();
          this.windowRef.style.transformOrigin = 'center';
          lastOverlayerAnimation = animateFrame((progress) => {
            // this.overlayerRef.style.opacity = progress * 0.5;
            const scale = progress * (0.996 - 1) + 0.996;
            this.windowRef.style.transform = `scale(${scale})`;
            if (progress === 1) {
              lastOverlayerAnimation = null;
            }
          }, 0.3 * 1e3);
          this._useStatehookForceUpdate();
        }
      }),
    ]);
  },
  goTop() {
    // webWindowManagerService.goTop(this.windowRef);
    const { webTaskKey } = this.useStatehookArgs.props;
    zIndexManagerService.goTop(webTaskKey);
  },
  lastStyle: null,
  isAnimating: false,
  _recover(option, cb) {
    if (this.isAnimating) return;
    if (typeof option === 'function') {
      cb = option;
      option = {};
    }
    this.isAnimating = true;
    option = option || {};
    cb = cb || (() => {});
    const { webTaskKey } = this.useStatehookArgs.props;
    webWindowManagerService.currentFocusedWebTaskKey = webTaskKey;
    const { windowRef } = this;
    const currentStyle = windowRef.currentStyle || window.getComputedStyle(windowRef);
    const to = this.lastStyle;

    // left and top
    const toLeft = parseFloat(to.left);
    const toTop = parseFloat(to.top);
    const fromLeft = parseFloat(currentStyle.left);
    const fromTop = parseFloat(currentStyle.top);
    const diffX = toLeft - fromLeft;
    const diffY = toTop - fromTop;

    // width and height
    const toWidth = parseFloat(to.width);
    const toHeight = parseFloat(to.height);
    const fromWidth = parseFloat(currentStyle.width);
    const fromHeight = parseFloat(currentStyle.height);
    const diffWidth = toWidth - fromWidth;
    const diffHeight = toHeight - fromHeight;

    // scale
    const matrixRegex = /matrix\((-?\d*\.?\d+),\s*0,\s*0,\s*(-?\d*\.?\d+),\s*0,\s*0\)/;
    const fromScaleMatch = matrixRegex.exec(currentStyle.transform);
    const fromScaleX = fromScaleMatch ? parseFloat(fromScaleMatch[1]) : 1;
    const fromScaleY = fromScaleMatch ? parseFloat(fromScaleMatch[2]) : 1;

    const toScaleMatch = matrixRegex.exec(to.transform);
    const toScaleX = toScaleMatch ? parseFloat(toScaleMatch[1]) : 1;
    const toScaleY = toScaleMatch ? parseFloat(toScaleMatch[2]) : 1;

    const diffScaleX = toScaleX - fromScaleX;
    const diffScaleY = toScaleY - fromScaleY;

    const fromOpacity = parseFloat(currentStyle.opacity);
    const toOpacity = parseFloat(to.opacity);
    const diffOpacity = toOpacity - fromOpacity;

    animateFrame((progress) => {
      const currentLeft = (diffX * progress + fromLeft);
      const currentTop = (diffY * progress + fromTop);
      windowRef.style.top = currentTop + 'px';
      windowRef.style.left = currentLeft + 'px';

      const currentWidth = (diffWidth * progress + fromWidth);
      const currentHeight = (diffHeight * progress + fromHeight);
      windowRef.style.width = currentWidth + 'px';
      windowRef.style.height = currentHeight + 'px';

      const currentScaleX = (diffScaleX * progress + fromScaleX);
      const currentScaleY = (diffScaleY * progress + fromScaleY);

      windowRef.style.transformOrigin = 'top left';
      windowRef.style.transform = `scale(${currentScaleX}, ${currentScaleY})`;

      const currentOpacity = (diffOpacity * progress + fromOpacity);
      const currentOpacityFixed = Number(currentOpacity).toFixed(2);
      windowRef.style.opacity = currentOpacityFixed;
      if (progress === 1) {
        this.windowStatus = WINDOW_STATUS_NORMAL;
        this.isAnimating = false;
        cb();
      }
    }, {
      duration: option.duration || 0.3 * 1e3,
    });
  },
  recover(options, cb) {
    this.useStatehookArgs.props.onRecover();
    return this._recover(options, cb);
  },
  _maximise(option, cb) {
    if (this.isAnimating) return;
    this.isAnimating = true;
    option = option || {};
    cb = cb || (() => {});
    const { windowRef } = this;
    const currentStyle = windowRef.currentStyle || window.getComputedStyle(windowRef);
    this.lastStyle = Object.assign({}, currentStyle);
    const toLeft = parseInt(0, 10);
    const toTop = parseInt(0, 10);
    const fromLeft = parseInt(currentStyle.left, 10);
    const fromTop = parseInt(currentStyle.top, 10);
    const diffX = toLeft - fromLeft;
    const diffY = toTop - fromTop;

    const parentStyle = windowRef.parentElement.currentStyle || window.getComputedStyle(windowRef.parentElement);
    const toWidth = parseInt(parentStyle.width, 10);
    let toHeight = parseInt(parentStyle.height, 10);

    // check if the task bar is showing
    if (!this.uiService.isTaskbarCollapsed) {
      toHeight -= 100;
    }

    const fromWidth = parseInt(currentStyle.width, 10);
    const fromHeight = parseInt(currentStyle.height, 10);
    const diffWidth = toWidth - fromWidth;
    const diffHeight = toHeight - fromHeight;
    animateFrame((progress) => {
      const currentLeft = (diffX * progress + fromLeft);
      const currentTop = (diffY * progress + fromTop);
      windowRef.style.top = currentTop + 'px';
      windowRef.style.left = currentLeft + 'px';

      const currentWidth = (diffWidth * progress + fromWidth);
      const currentHeight = (diffHeight * progress + fromHeight);
      windowRef.style.width = currentWidth + 'px';
      windowRef.style.height = currentHeight + 'px';
      // const scale = 1 - progress;
      windowRef.style.transformOrigin = 'top left';
      // windowRef.style.transform = `scale(${scale})`;
      // windowRef.style.opacity = scale;
      if (progress === 1) {
        this.windowStatus = WINDOW_STATUS_MAXIMISED;
        this.isAnimating = false;
        cb();
      }
    }, {
      duration: option.duration || 0.3 * 1e3,
    });
  },
  maximise(options, cb) {
    this.useStatehookArgs.props.onMaximise();
    return this._maximise(options, cb);
  },
  minimise(option, cb) {
    this.useStatehookArgs.props.onMinimise();
    if (this.lastStyle) {
      this.recover(Object.assign({}, option, { duration: 0.1 * 1e3 }), () => {
        this._minimise(option, cb);
      });
    } else {
      this._minimise(option, cb);
    }
  },
  _minimise(option, cb) {
    if (this.isAnimating) return;
    this.isAnimating = true;
    cb = cb || (() => {});
    const { webTaskKey } = this.useStatehookArgs.props;
    if (webWindowManagerService.currentFocusedWebTaskKey === webTaskKey) {
      webWindowManagerService.currentFocusedWebTaskKey = null;
    }
    option = option || {};
    const { windowRef } = this;
    const currentStyle = windowRef.currentStyle || window.getComputedStyle(windowRef);
    this.lastStyle = Object.assign({}, currentStyle);
    const toLeft = parseInt(option.left, 10);
    const toTop = parseInt(option.top, 10);
    const fromLeft = parseInt(currentStyle.left, 10);
    const fromTop = parseInt(currentStyle.top, 10);
    const diffX = toLeft - fromLeft;
    const diffY = toTop - fromTop;
    animateFrame((progress) => {
      const currentLeft = (diffX * progress + fromLeft);
      const currentTop = (diffY * progress + fromTop);
      windowRef.style.top = currentTop + 'px';
      windowRef.style.left = currentLeft + 'px';
      const scale = 1 - progress;
      windowRef.style.transformOrigin = 'top left';
      windowRef.style.transform = `scale(${scale})`;
      windowRef.style.opacity = scale;
      if (progress === 1) {
        this.windowStatus = WINDOW_STATUS_MINIMISED;
        this.isAnimating = false;
        cb();
      }
    }, {
      duration: option.duration || 0.3 * 1e3,
    });
  },
};

// component
const useStyles = createUseStyles({
  windowRef: ({
    customisedStyle,
    isFullWindow,
    isFocused,
    windowStatus,
  }) => {
    let overrideStyle = {};
    if (isFullWindow) {
      overrideStyle = {
        top: '0px',
        left: '0px',
        width: '100%',
        height: '100%',
        borderRadius: '0px',
      };
    }
    return Object.assign({
      zIndex: '100',
      position: 'absolute',
      top: '20px',
      left: '20px',
      width: '600px',
      height: '600px',
      backgroundColor: 'rgba(255, 255, 255, 0)',
      borderRadius: '10px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'stretch',
      // display: 'grid',
      // gridTemplateColumns: '100%',
      // gridTemplateRows: ({
      //   'normal': '40px auto',
      //   'full-window': '0px auto',
      // })[windowStatus] || '40px auto',
      overflow: 'hidden',
      // opacity: '0.90',
    }, customisedStyle || {}, overrideStyle);
  },
  title: {
    position: 'relative',
    cursor: 'grab',
    textAlign: 'center',
    background: ({ isFocused, props }) => {
      if (isFocused && props.titleColourFocused) {
        return props.titleColourFocused;
      } else if (!isFocused && props.titleColourUnfocused) {
        return props.titleColourUnfocused;
      } else {
        return 'unset';
      }
    },
    color: ({ isFocused, props }) => {
      if (isFocused && props.titleColourFocused) {
        return getObviousColourBy(props.titleColourFocused);
      }
      return undefined;
    },
    height: '40px',
    // '& > *': {
    //   lineHeight: '40px',
    // },
    // backgroundImage: gradientCSSLinearGradient(null, 5),
    // backgroundColor: 'rgb(109, 162, 99)',
    // textShadow: '0px 0px 15px black',
    // color: '#4a4a4a',
  },
  titleWindowControlButtons: {
    position: 'absolute',
    left: '13px',
    top: '0px',
    bottom: '0px',
    display: 'flex',
    flexDirection: 'row',
    // columnGap: '3px',
    // display: 'grid',
    // gridTemplateColumns: '20px 20px 20px',
    // columnGap: '3px',
    // gridTemplateRows: '100%',
    justifyItems: 'center',
    alignItems: 'center',
    '& > *': {
      width: '20px',
    },
  },
  titleBarRightButtonGroup: {
    position: 'absolute',
    right: '13px',
    top: '0px',
    bottom: '0px',
    display: 'flex',
    flexDirection: 'row',
    // columnGap: '3px',
    // display: 'grid',
    // gridTemplateColumns: 'repeat(auto-fill, 20px)',
    // columnGap: '3px',
    // gridTemplateRows: '100%',
    justifyItems: 'center',
    alignItems: 'center',
  },
  contentRef: {
    overflow: 'hidden',
    // backgroundColor: 'white',
    position: 'relative',
    width: '100%',
    height: '100%',
  },
  overlayerRef: {
    // overlayer
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: '0px',
    left: '0px',
    zIndex: '9999',
    // opacity: 0.5,
    // display: 'none',
  },
  bottomStatusBarRef: {
    backgroundColor: 'rgb(247, 247, 247)',
    lineHeight: '40px',
    display: 'inline-grid',
    gridTemplateColumns: 'auto 30px',
    gridTemplateRows: '100%',
  },
});
const component = (props) => {
  const statehook = useStatehook({
    props,
    sessionContext: React.useContext(SessionContext),
  }, actions, React);
  const {
    children,
    title,
    windowRef,
    windowStatus,
    style,
    statehookRef,
    webTaskKey,
  } = props;
  statehookRef(statehook);

  const classes = useStyles({
    customisedStyle: style,
    windowStatus,
    isFullWindow: statehook.isFullWindow,
    props,
    isFocused: statehook.isFocused,
  });
  return (
    <WebWindowContext.Provider value={{ statehook }}>
      <div
        className={cx('web-window', classes.windowRef, {
          focus: statehook.isFocused,
          unfocus: !statehook.isFocused,
        })}
        style={{
          zIndex: zIndexManagerService.getZIndex(webTaskKey),
        }}
        ref={(ref) => {
          windowRef(ref);
          statehook._setWindowRef(ref);
        }}
        data-web-window
        data-web-window-key={webTaskKey}
      >
        <div
          // moving title
          className={cx(classes.title, 'web-window-title')}
          style={Object.assign({ display: 'flex', flexDirection: 'row' }, statehook.titleBarStyle)}
          // ref={statehook._setMoveHandleRef}

        >
          <div
            ref={statehook._setMoveHandleRef}
            style={{
              width: '100%',
              alignSelf: 'center',
            }}
          >
            {statehook.titleBarComponent || (
              <div
                style={{
                  width: '100%',
                }}
              >
                {title}
              </div>
            )}
          </div>
          <div
            className={classes.titleWindowControlButtons}
          >
            {typeof props.onClose === 'function' && (
              <div
                role="button"
                tabIndex="-1"
                onKeyDown={() => {}}
                onClick={props.onClose}
              >
                <i
                  className="fas fa-times"
                  style={{ cursor: 'pointer', width: '10px', height: '10px' }}
                />
              </div>
            )}
            {!props.unminimiseable && (
              <div
                role="button"
                tabIndex="-1"
                onKeyDown={() => {}}
                onClick={() => {
                  statehook.minimise({
                    left: window.innerWidth / 2,
                    top: window.innerHeight,
                  });
                }}
              >
                <img
                  style={{ cursor: 'pointer', width: '10px', height: '10px' }}
                  src={statehook.minimiseIcon}
                  alt="minimise"
                />
              </div>
            )}
            {!props.unresizeable && (
              <div
                role="button"
                tabIndex="-1"
                onKeyDown={() => {}}
                onClick={() => {
                  if (!statehook.isWindowMaximised) {
                    statehook.maximise({}, () => {
                      statehook.statehookEmit('onResizeMouseUp');
                    });
                  } else {
                    statehook.recover({}, () => {
                      statehook.statehookEmit('onResizeMouseUp');
                    });
                  }
                }}
              >
                <img
                  style={{ cursor: 'pointer', width: '10px', height: '10px' }}
                  src={statehook.maximiseIcon}
                  alt="maximise"
                />
              </div>
            )}
          </div>
          {/* right button group */}
          <div className={classes.titleBarRightButtonGroup}>
            {(statehook.titleBarRightButtonGroup || [])}
          </div>
        </div>
        <div
          className={classes.contentRef}
          ref={statehook._setContentRef}
        >
          <div
            className={cx('web-window-overlayer', classes.overlayerRef)}
            ref={statehook._setOverlayerRef}
          />
          {children(statehook)}
        </div>
        <div
          style={{
            display: statehook.useStatehookArgs.props.unresizeable ? 'none' : 'block',
            position: 'absolute',
            zIndex: 100,
            right: '0px',
            bottom: '0px',
            cursor: 'nwse-resize',
            width: '26px',
            height: '26px',
            borderBottomRightRadius: '4px',
            // background: 'linear-gradient(135deg, rgba(255,255,255,0) 47%, rgba(150,150,150,1) 52%)',
            margin: '3px',
            // backgroundColor: 'white',
            clipPath: 'polygon(100% 0,100% 100%,0 100%)',
            backgroundImage: 'repeating-linear-gradient(135deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0) 4px, rgba(128, 128, 128, 0.5) 4px, rgba(128, 128, 128, 0.5) 8px)',
          }}
          ref={statehook._setResizeHandleRef}
        />
        {/* <div
          className={classes.bottomStatusBarRef}
        >
          <div>
            &nbsp;
          </div>
        </div> */}
      </div>
    </WebWindowContext.Provider>
  );
};
component.propTypes = {
  webTaskKey: PropTypes.string.isRequired,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  titleColourFocused: PropTypes.string,
  titleColourUnfocused: PropTypes.string,
  windowRef: PropTypes.func,
  windowStatus: PropTypes.string,
  style: PropTypes.shape({}),
  statehookRef: PropTypes.func,
  moveableConfig: PropTypes.shape({}),
  resizeableConfig: PropTypes.shape({}),
  children: PropTypes.func.isRequired,
  unresizeable: PropTypes.bool,
  unminimiseable: PropTypes.bool,
  onMinimise: PropTypes.func,
  onMaximise: PropTypes.func,
  onRecover: PropTypes.func,
  onClose: PropTypes.func,
};

component.defaultProps = {
  title: 'Untitled window',
  titleColourFocused: '',
  titleColourUnfocused: '',
  windowRef: (() => {}),
  windowStatus: 'normal',
  style: {},
  statehookRef: (() => {}),
  moveableConfig: {},
  resizeableConfig: {},
  unresizeable: false,
  unminimiseable: false,
  onMinimise: (() => {}),
  onMaximise: (() => {}),
  onRecover: (() => {}),
  onClose: undefined,
};

exports.WebWindow = component;
exports.webWindowManagerService = webWindowManagerService;
exports.WebWindowContext = WebWindowContext;
