/**
 * @author MrZenW
 * @email MrZenW@Gmail.com, https://MrZenW.com
 * @create date 2021-10-06 14:54:00
 * @modify date 2021-10-06 14:54:00
 * @desc [description]
 */

import {
  parsePath,
  StatehookClass,
  StatehookName,
  StatehookUnwatch,
  StatehookWatchCallback,
} from '$/statehook/typescript';
const { registerWebWindowContentComponent }  = require('$/components/web_task_window');
import { grabZIndexManagerService } from '$/services/z_index_manager_service';

const zIndexManagerService = grabZIndexManagerService('kais-solo-global-z-index-manager-service');
exports.registerWebWindowContentComponent = registerWebWindowContentComponent;
registerWebWindowContentComponent('TutorialVideoWindow', require('$/components/tutorial_video_window/tutorial_video_window.view').TutorialVideoWindowView);

export class TutorialVideoService extends StatehookClass {
  sessionService: any
  get visibility(): boolean {
    return !!this.statehookGetPath('visibility');
  }
  set visibility(newValue: boolean) {
    this.statehookSetPath('visibility', newValue);
  }
  watchVisibility(cb: StatehookWatchCallback): StatehookUnwatch {
    return this.statehookWatchPath('visibility', cb);
  }
  get webTaskManagerService(): any {
    return this.sessionService.webTaskManagerService;
  }
  _onInit(args: any) {
    const { sessionService } = args;
    this.sessionService = sessionService;
  }
  static override grabOne(statehookName: StatehookName) {
    return super.grabOne([].concat(['TutorialVideoService'], parsePath(statehookName, '/')));
  }
  public closeVideoWindow() {
    this.visibility = false;
    this.webTaskManagerService.terminateTask('tutorial_video_window');
  }
  public openVideoWindow() {
    this.visibility = true;
    this.webTaskManagerService.newTask('tutorial_video_window', {
      windowData: {
        title: 'Tutorial video',
        contentComponentName: 'TutorialVideoWindow',
        // unresizeable: true,
        // unminimiseable: true,
        style: {
          top: null,
          position: 'absolute',
          // top: '0px',
          // left: '0px',
          bottom: '140px',
          left: 'calc(50% - 20px)',
          width: '400px',
          height: '275px',
          minWidth: '300px',
          minHeight: '220px',
          // height: 'unset',
          backgroundColor: 'var(--theme-bg)',
          zIndex: '10',
        },
        titleBarStyle: {

        },
        onClose: () => {
          this.closeVideoWindow();
        },
      },
    });
    setTimeout(() => {
      zIndexManagerService.goTop('tutorial_video_window');
    //   // webWindowManagerService.goTop(this.windowRef);
    }, 0.1 * 1e3);
  }
}
