/**
 * @author MrZenW
 * @email MrZenW@Gmail.com, https://MrZenW.com
 * @create date 2021-05-25 13:21:01
 * @modify date 2022-04-22 16:55:23
 * @desc [description]
 */
const { statehookify, getStatehook, parsePath } = require('$/statehook');
const { grabMqttService } = require('$/services/mqtt_service');
const { grabUiService } = require('$/services/ui_service');
const { globalCrossWindowChannelService } = require('$/services/cross_window_channel_service');
const { grabLocalStorageService } = require('$/services/local_storage_service');
const { ApiService } = require('$/services/api_service');
const { UserServiceClass } = require('$/services/user_service');
const { AdminServiceClass } = require('$/services/admin_service');
const { grabWebTaskManagerService } = require('$/services/web_task_manager_service');
const {
  grabBlocklySoundService,
  grabWebWindowSoundService,
  grabControlPanelToolbarSoundService,
} = require('$/services/sound_service');
const { GamepadServiceClass } = require('./gamepad_service');
const { TutorialVideoService } = require('./tutorial_video_service');
const { PlayerListWindowService } = require('./player_list_window_service');
const { PythonCodeWindowService } = require('./python_code_window_service');
const { BlocklyConsoleService } = require('./blockly_console_service');
const { GoogleOAuthServiceClass } = require('./google_oauth_service');
const { UserProfileWindowServiceClass } = require('./user_profile_window_service');
const { TutorialDriverServiceClass } = require('./tutorial_driver_service');
const { BlocklyWindowServiceClass } = require('./blockly_window_service');
const { WebglWindowServiceClass } = require('./webgl_window_service');

function communicationParamHitChecker(param, pattern) {
  if (pattern === '*') return true;
  if (pattern instanceof RegExp) return pattern.test(param);
  return pattern === param;
}

const serviceStoreRootPath = ['service_store'];

exports.grabSessionService = (sessionId) => {
  const statehookName = [].concat(['session_service'], parsePath(sessionId, '/'));
  let session = getStatehook(statehookName);
  if (!session) {
    session = statehookify({ statehookName, bindThisToBase: true }, {
      set sessionDoc(newValue) {
        return this.statehookSetPath('sessionDoc', newValue);
      },
      get sessionDoc() {
        return this.statehookGetPath('sessionDoc');
      },
      get sessionId() {
        return this.statehookGetPath('sessionId') || this.sessionDoc.sessionId;
      },
      get sessionPublicId() {
        return this.sessionDoc.sessionPublicId;
      },
      // blockly window
      get blocklyWindowService() {
        return this.statehookGetPath([].concat(serviceStoreRootPath, ['blocklyWindowService']));
      },
      set blocklyWindowService(newValue) {
        return this.statehookSetPath([].concat(serviceStoreRootPath, ['blocklyWindowService']), newValue);
      },
      // blockly window END
      // webgl window
      get webglWindowService() {
        return this.statehookGetPath([].concat(serviceStoreRootPath, ['webglWindowService']));
      },
      set webglWindowService(newValue) {
        return this.statehookSetPath([].concat(serviceStoreRootPath, ['webglWindowService']), newValue);
      },
      // webgl window END
      // user
      async createUserService() {
        const userInfoResponse = await this.apiService.userApi.getMyUserInfo();
        const { userDoc, adminDoc, googleOAuthDoc } = userInfoResponse.data;
        if (this.userService) {
          this.discardUserService();
        }
        if (adminDoc) {
          this.adminService = AdminServiceClass.grabOne([this.sessionId]).initialiseIfNeeded({
            sessionService: this,
            adminDoc,
          });
        }
        if (userDoc) {
          this.userService = UserServiceClass.grabOne([this.sessionId]).initialiseIfNeeded({
            sessionService: this,
            userDoc,
          });
        }
        if (googleOAuthDoc) {
          this.googleOAuthService = GoogleOAuthServiceClass.grabOne([this.sessionId]).initialiseIfNeeded({
            sessionService: this,
            googleOAuthDoc,
          });
        }
      },
      discardUserService() {
        const { userService, adminService } = this;
        if (adminService) {
          adminService.statehookDiscard();
          this.adminService = null;
        }
        if (userService) {
          userService.statehookDiscard();
          this.userService = null;
        }
      },
      get userService() {
        return this.statehookGetPath([].concat(serviceStoreRootPath, ['userService']));
      },
      set userService(newValue) {
        return this.statehookSetPath([].concat(serviceStoreRootPath, ['userService']), newValue);
      },
      watchUserService(cb) {
        return this.statehookWatchPath([].concat(serviceStoreRootPath, ['userService']), cb);
      },
      // admin
      get adminService() {
        return this.statehookGetPath([].concat(serviceStoreRootPath, ['adminService']));
      },
      set adminService(newValue) {
        return this.statehookSetPath([].concat(serviceStoreRootPath, ['adminService']), newValue);
      },
      watchAdminService(cb) {
        return this.statehookWatchPath([].concat(serviceStoreRootPath, ['adminService']), cb);
      },
      // googleOAuth
      get googleOAuthService() {
        return this.statehookGetPath([].concat(serviceStoreRootPath, ['googleOAuthService']));
      },
      set googleOAuthService(newValue) {
        return this.statehookSetPath([].concat(serviceStoreRootPath, ['googleOAuthService']), newValue);
      },
      watchGoogleOAuthService(cb) {
        return this.statehookWatchPath([].concat(serviceStoreRootPath, ['googleOAuthService']), cb);
      },
      // various services
      get mqttService() {
        return this.statehookGetPath([].concat(serviceStoreRootPath, ['mqttService']));
      },
      get uiService() {
        return this.statehookGetPath([].concat(serviceStoreRootPath, ['uiService']));
      },
      get localStorageService() {
        return this.statehookGetPath([].concat(serviceStoreRootPath, ['localStorageService']));
      },
      onLocalStorageServiceChange(cb) {
        return this.statehookWatchPath([].concat(serviceStoreRootPath, ['localStorageService']), cb);
      },
      get apiService() {
        return this.statehookGetPath([].concat(serviceStoreRootPath, ['apiService']));
      },
      get webTaskManagerService() {
        return this.statehookGetPath([].concat(serviceStoreRootPath, ['webTaskManagerService']));
      },
      get blocklySoundService() {
        return this.statehookGetPath([].concat(serviceStoreRootPath, ['blocklySoundService']));
      },
      get webWindowSoundService() {
        return this.statehookGetPath([].concat(serviceStoreRootPath, ['webWindowSoundService']));
      },
      get controlPanelToolbarSoundService() {
        return this.statehookGetPath([].concat(serviceStoreRootPath, ['controlPanelToolbarSoundService']));
      },
      get gamepadService() {
        return this.statehookGetPath([].concat(serviceStoreRootPath, ['gamepadService']));
      },
      get tutorialVideoService() {
        return this.statehookGetPath([].concat(serviceStoreRootPath, ['tutorialVideoService']));
      },
      get blocklyConsoleService() {
        return this.statehookGetPath([].concat(serviceStoreRootPath, ['blocklyConsoleService']));
      },
      get playerListWindowService() {
        return this.statehookGetPath([].concat(serviceStoreRootPath, ['playerListWindowService']));
      },
      get pythonCodeWindowService() {
        return this.statehookGetPath([].concat(serviceStoreRootPath, ['pythonCodeWindowService']));
      },

      get currentWebWorkerService() {
        return this.statehookGetPath([].concat(serviceStoreRootPath, ['currentWebWorkerService']));
      },
      set currentWebWorkerService(newValue) {
        return this.statehookSetPath([].concat(serviceStoreRootPath, ['currentWebWorkerService']), newValue);
      },
      watchCurrentWebWorkerService(listener) {
        return this.statehookWatchPath([].concat(serviceStoreRootPath, ['currentWebWorkerService']), listener);
      },

      // user profile window
      get userProfileWindowService() {
        return this.statehookGetPath([].concat(serviceStoreRootPath, ['userProfileWindowService']));
      },

      // tutorial driver window
      get tutorialDriverService() {
        return this.statehookGetPath([].concat(serviceStoreRootPath, ['tutorialDriverService']));
      },

      crossWindowAnnounceSessionEnteredUrl(url) {
        return globalCrossWindowChannelService.publishBroadcast({
          type: 'AnnounceSessionEnteredUrl',
          url,
          sessionPublicId: this.sessionPublicId,
        });
      },
      crossWindowWatchSessionEnteredUrl(url, cb) {
        return globalCrossWindowChannelService.receiveBroadcast((event) => {
          const { content } = event;
          if (content.type === 'AnnounceSessionEnteredUrl' && content.url === url && content.sessionPublicId === this.sessionPublicId) {
            cb(event);
          }
        });
      },
      sendCommunityData(from, to, subject, data) {
        if (from === '*') throw new Error('Sender name cannot be a "*"');
        if (to === '*') throw new Error('Receiver name cannot be a "*"');
        return this.statehookEmit('community_talk', {
          from,
          to,
          subject,
          data,
        });
      },
      receiveCommunityData(from, to, subject, cb) {
        return this.statehookOn('community_talk', (_, eventData) => {
          if (communicationParamHitChecker(eventData.from, from)
            && communicationParamHitChecker(eventData.to, to)
            && communicationParamHitChecker(eventData.subject, subject)) {
            cb(_, eventData);
          }
        });
      },
      _stopReceiveSetUserKey() {
        return this.statehookUnwatchWithNS('_startReceiveSetUserKey');
      },
      _startReceiveSetUserKey() {
        this._stopReceiveSetUserKey();
        return this.statehookRegisterUnwatchWithNS('_startReceiveSetUserKey', [
          this.receiveCommunityData('unity', 'ui', 'setUserKey', async (_, communicationData) => {
            const { data } = communicationData;
            const { key, value } = data;
            await this.apiService.sessionApi.setExtendedDataItem(key, value);
            this.sessionDoc.extendedData = this.sessionDoc.extendedData || {};
            this.sessionDoc.extendedData[key] = value;
          }),
        ]);
      },
    }, (thisStatehook, sessionServiceObject) => {
      thisStatehook.statehookSetPath('sessionId', sessionId);
      thisStatehook.statehookSetManyPaths({
        uiService: grabUiService(sessionId),
      }, serviceStoreRootPath);
      thisStatehook.statehookSetManyPaths({
        blocklyWindowService: BlocklyWindowServiceClass.grabOne([sessionId]).initialiseIfNeeded({ sessionService: sessionServiceObject }),
        webglWindowService: WebglWindowServiceClass.grabOne([sessionId]).initialiseIfNeeded({ sessionService: sessionServiceObject }),
        mqttService: grabMqttService(sessionId),
        localStorageService: grabLocalStorageService(sessionId),
        apiService: ApiService.grabOne([sessionId]).initialiseIfNeeded({ sessionService: sessionServiceObject }),
        webTaskManagerService: grabWebTaskManagerService(sessionId),
        blocklySoundService: grabBlocklySoundService(sessionId),
        webWindowSoundService: grabWebWindowSoundService(sessionId),
        controlPanelToolbarSoundService: grabControlPanelToolbarSoundService(sessionId),
        gamepadService: GamepadServiceClass.grabOne([sessionId]).initialiseIfNeeded(),
        tutorialVideoService: TutorialVideoService.grabOne([sessionId]).initialiseIfNeeded({ sessionService: sessionServiceObject }),
        blocklyConsoleService: BlocklyConsoleService.grabOne([sessionId]).initialiseIfNeeded({ sessionService: sessionServiceObject }),
        playerListWindowService: PlayerListWindowService.grabOne([sessionId]).initialiseIfNeeded({ sessionService: sessionServiceObject }),
        pythonCodeWindowService: PythonCodeWindowService.grabOne([sessionId]).initialiseIfNeeded({ sessionService: sessionServiceObject }),
        userService: null,
        adminService: null,
        userProfileWindowService: UserProfileWindowServiceClass.grabOne([sessionId]).initialiseIfNeeded({ sessionService: sessionServiceObject }),
        tutorialDriverService: TutorialDriverServiceClass.grabOne([sessionId]).initialiseIfNeeded({ sessionService: sessionServiceObject }),
      }, serviceStoreRootPath);

      // start receive first work state
      sessionServiceObject._startReceiveSetUserKey();

      return () => {
        thisStatehook.statehookRemovePath('sessionId');
        const allServices = thisStatehook.statehookGetPath(serviceStoreRootPath);
        // discard all sub services
        Object.entries(allServices)
          .forEach((serviceName, serviceStatehook) => {
            if (serviceStatehook) {
              serviceStatehook.statehookDiscard();
              const path = [].concat(serviceStoreRootPath, [serviceName]);
              thisStatehook.statehookRemovePath(path);
            }
          });
      };
    });
  }
  return session;
};
