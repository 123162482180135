/**
 * @author MrZenW
 * @email MrZenW@Gmail.com, https://MrZenW.com
 * @create date 2021-05-25 13:20:18
 * @modify date 2021-06-04 16:41:00
 * @desc [description]
 */
const { statehookify } = require('$/statehook');

module.exports = statehookify({ statehookName: 'devtool_service', bindThisToBase: true }, {
  get consoleOpenStatus() {
    return this.statehookGetPath('consoleOpenStatus');
  },
}, () => {

});
