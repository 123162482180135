/**
 * @author MrZenW
 * @email MrZenW@Gmail.com, https://MrZenW.com
 * @create date 2021-10-05 14:58:48
 * @modify date 2021-10-05 14:58:48
 * @desc [description]
 */

import { ApiService, HttpResponse, HttpResponseData } from '../api_service';

export interface SessionExtendedDataItemResponseData extends HttpResponseData {
  key: string,
  value: any,
}

export interface SessionExtendedDataItemResponse extends HttpResponse {
  data: SessionExtendedDataItemResponseData,
}

export class SessionApiClass {
  apiService: ApiService = null;

  constructor(apiService: ApiService) {
    this.apiService = apiService;
  }

  setExtendedDataItem(key: string, value: any): Promise<any> {
    return this.apiService.clientPut(`/client/session/extendedData/${encodeURIComponent(key)}`, null, {
      value,
    });
  }
  getExtendedDataItem(key: string): Promise<SessionExtendedDataItemResponse> {
    return this.apiService.clientGet(`/client/session/extendedData/${encodeURIComponent(key)}`) as Promise<SessionExtendedDataItemResponse>;
  }
}
