/**
 * @author MrZenW
 * @email MrZenW@Gmail.com, https://MrZenW.com
 * @create date 2021-05-25 13:21:37
 * @modify date 2021-05-25 13:21:37
 * @desc [description]
 */
// const React = require('react');
import React from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { init, reportError } from './libraries/error_report';

// sentry.io begining
type MyConsole = (typeof console) & {
  reportError: any;
}
(console as MyConsole).reportError = reportError;
init();
// sentry.io end

// https://github.com/microsoft/TypeScript/issues/33128#issue-486675867
type MyWindow = (typeof window) & {
  FontAwesomeConfig: any;
}
(window as MyWindow).FontAwesomeConfig = Object.assign(
  (window as MyWindow).FontAwesomeConfig || {},
  { autoReplaceSvg: 'nest' },
);

require('$/assets/styles/colours.css?noPostcss');
require('bootstrap/scss/bootstrap.scss');
require('$/assets/styles/all.scss');
require('$/assets/styles/hungry-loader.css?noPostcss');
require('@fortawesome/fontawesome-free/js/fontawesome');
require('@fortawesome/fontawesome-free/js/solid');
require('@fortawesome/fontawesome-free/js/regular');
require('@fortawesome/fontawesome-free/js/brands');
require('animate.css');
require('bootstrap-icons/font/bootstrap-icons.css');
require('$/services/devtool_service');
require('$/services/theme_service');

const { render } = require('react-dom');
const Loading = require('$/components/Loading');
const { SessionContext } = require('$/components/session_context');
const App = require('./App');
const configService = require('$/services/config_service');
const { grabSessionService } = require('$/services/session_service');
const { promiseKeeper } = require('$/libraries/promise-keeper');

const createSession = (sessionId?: string) => {
  return configService.grabSessionInfo(sessionId)
    .then((sessionInfo: any) => {
      configService.currentSessionId = sessionInfo.sessionId;
      const sessionService = grabSessionService(sessionInfo.sessionId);
      sessionService.sessionDoc = sessionInfo;
      return sessionService;
    });
};
const fetchGlobalConfigs = async (sessionService: any) => {
  const [isToolboxError, toolboxError, toolboxResp] = await promiseKeeper(sessionService.apiService.configApi.getGlobalItem('blockly_toolbox'));
  if (isToolboxError) {
    console.error(toolboxError);
  } else {
    configService.setConfigItem('global/blockly_toolbox', toolboxResp.data.configDoc || {});
  }
  const [isFirmwareVersionError, firmwareVersionError, firmwareVersionResp] = await promiseKeeper(sessionService.apiService.configApi.getGlobalItem('robot_firmware_version'));
  if (isFirmwareVersionError) {
    console.error(firmwareVersionError);
  } else {
    configService.setConfigItem('global/robot_firmware_version', firmwareVersionResp.data.configDoc || {});
  }
};

const onVisibilityChange = () => {
  if (document.visibilityState === 'visible') {
    configService.connectSession();
  } else {
    configService.disconnectSession();
  }
};

// Only attach the visibilitychange event listener if the app is not running in an iframe to prevent multiple listeners
if (window.location === window.parent.location) {
  document.addEventListener('visibilitychange', onVisibilityChange);
}

(async () => {
  render(
    <Loading />,
    document.getElementById('root'),
  );
  await configService.fetchClientInfo();
  let [isSessionError, sessionError, sessionService] = await promiseKeeper(createSession(configService.currentSessionId));
  if (isSessionError) {
    alertLib.alertError(sessionError);
    sessionService = await createSession();
  }
  await sessionService.createUserService();
  await fetchGlobalConfigs(sessionService);
  // await fetchGlobalConfig();
  let googleRecaptchaKey = '';
  if (__PRODUCTION__) {
    googleRecaptchaKey = process.env.GOOGLE_RECAPTCHA_SITE_KEY;
  }
  render(
    <GoogleReCaptchaProvider
      reCaptchaKey={googleRecaptchaKey}
    >
      <SessionContext.Provider value={{ sessionService, sessionId: sessionService.sessionId }}>
        <App />
      </SessionContext.Provider>
    </GoogleReCaptchaProvider>,
    document.getElementById('root'),
  );
})();
