/**
 * @author MrZenW
 * @email MrZenW@Gmail.com, https://MrZenW.com
 * @create date 2021-05-25 13:20:36
 * @modify date 2021-06-04 16:41:20
 * @desc [description]
 */
const { statehookify, getStatehook, parsePath } = require('$/statehook');

exports.grabLocalStorageService = (statehookName) => {
  statehookName = [].concat(['local_storage_service'], parsePath(statehookName, '/'));
  const instance = getStatehook(statehookName);
  if (instance) return instance;
  return statehookify({ statehookName, bindThisToBase: true }, {
    getLocalSessionItem(key) {
      key = parsePath(key, '/');
      return this.statehookGetPath([].concat(['localSession'], key));
    },
    popLocalSessionItem(key) {
      const value = this.getLocalSessionItem(key);
      this.removeLocalSessionItem(key);
      return value;
    },
    setLocalSessionItem(key, value) {
      key = parsePath(key, '/');
      const concatKey = [].concat(['localSession'], key);
      if (value === undefined) {
        this.statehookRemovePath(concatKey);
      } else {
        this.statehookSetPath(concatKey, value);
      }
      this.statehookEmit('localSession_item_change', key, value);
    },
    setLocalSessionItems(content) {
      Object.keys(content).forEach((key) => {
        this.setLocalSessionItem(key, content[key]);
      });
    },
    removeLocalSessionItem(key) {
      return this.setLocalSessionItem(key, undefined);
    },
    // local
    getLocalItem(key) {
      key = parsePath(key, '/');
      return this.statehookGetPath([].concat(['local'], key));
    },
    popLocalItem(key) {
      const value = this.getLocalItem(key);
      this.removeLocalItem(key);
      return value;
    },
    setLocalItem(key, value) {
      key = parsePath(key, '/');
      const concatKey = [].concat(['local'], key);
      if (value === undefined) {
        this.statehookRemovePath(concatKey);
      } else {
        this.statehookSetPath(concatKey, value);
      }
      this.statehookEmit('local_item_change', key, value);
    },
    setLocalItems(content) {
      Object.keys(content).forEach((key) => {
        this.setLocalItem(key, content[key]);
      });
    },
    removeLocalItem(key) {
      return this.setLocalItem(key, undefined);
    },
  }, (thisStatehook, baseObject) => {
    const keyPrefixArray = [].concat(['_kai_solo_'], thisStatehook.statehookName);
    const keyPrefix = keyPrefixArray.join('/') + '/';
    // init local
    const localStoreAllKeys = Object.keys(localStorage);
    localStoreAllKeys.forEach((itemKey) => {
      if (itemKey.startsWith(keyPrefix)) {
        const pureKey = itemKey.slice(keyPrefix.length);
        let content = localStorage[itemKey];
        try {
          const contentJSON = JSON.parse(content);
          content = contentJSON;
        } catch (error) {
          //
        }
        baseObject.setLocalItem(pureKey, content);
      }
    });
    // init local session
    const localSessionStoreAllKeys = Object.keys(sessionStorage);
    localSessionStoreAllKeys.forEach((itemKey) => {
      if (itemKey.startsWith(keyPrefix)) {
        const pureKey = itemKey.slice(keyPrefix.length);
        baseObject.setLocalSessionItem(pureKey, sessionStorage[itemKey]);
      }
    });
    const unwatchers = [
      thisStatehook.statehookOn('local_item_change', (_, key, value) => {
        const fullKey = [].concat(keyPrefixArray, key);
        if (value === undefined) {
          localStorage.removeItem(fullKey.join('/'));
          return;
        }
        let valueString = value;
        if (typeof value !== 'string') {
          valueString = JSON.stringify(value);
        }
        localStorage.setItem(fullKey.join('/'), valueString);
      }),
      thisStatehook.statehookOn('localSession_item_change', (_, key, value) => {
        const fullKey = [].concat(keyPrefixArray, key);
        if (value === undefined) {
          localStorage.removeItem(fullKey.join('/'));
          return;
        }
        let valueString = value;
        if (typeof value !== 'string') {
          valueString = JSON.stringify(value);
        }
        sessionStorage.setItem(fullKey.join('/'), valueString);
      }),
    ];
    return () => {
      while (unwatchers.length) unwatchers.pop()();
    };
  });
};
