/**
 * @author MrZenW
 * @email MrZenW@Gmail.com, https://MrZenW.com
 * @create date 2021-10-27 15:57:37
 * @modify date 2021-10-27 15:57:37
 * @desc [description]
 */

import { nanoid } from 'nanoid';
import {
  parsePath,
  StatehookClass,
  StatehookName,
  StatehookUnwatch,
  StatehookWatchCallback,
} from '$/statehook/typescript';
const { registerWebWindowContentComponent }  = require('$/components/web_task_window');
import { grabZIndexManagerService } from '$/services/z_index_manager_service';

const zIndexManagerService = grabZIndexManagerService('kais-solo-global-z-index-manager-service');

registerWebWindowContentComponent('BlocklyConsoleWindowView', require('$/components/blockly_console_window/blockly_console_window.view').BlocklyConsoleWindowView);

export interface LogStructure {
  id: string,
  time: number,
  log: string,
}

export class BlocklyConsoleService extends StatehookClass {
  sessionService: any
  static override grabOne(statehookName: StatehookName) {
    return super.grabOne([].concat(['BlocklyConsoleService'], parsePath(statehookName, '/')));
  }
  get webTaskManagerService(): any {
    return this.sessionService.webTaskManagerService;
  }
  get visibility(): boolean {
    return !!this.statehookGetPath('visibility');
  }
  set visibility(newValue: boolean) {
    this.statehookSetPath('visibility', newValue);
  }
  watchVisibility(cb: StatehookWatchCallback): StatehookUnwatch {
    return this.statehookWatchPath('visibility', cb);
  }
  _onInit(args: any) {
    const { sessionService } = args;
    this.sessionService = sessionService;
    // listen and open the console window
    this.watchLog((_, action) => {
      if (action === 'new') {
        if (this.logData.length === 1) {
          this.openConsoleLogWindow();
        }
      }
    });

    // listen to the started event from web worker
    this.statehookRegisterUnwatch(this.sessionService.receiveCommunityData('ui', '*', 'webworker_started', () => {
      this.clearLog();
    }));
  }
  logData: LogStructure[] = []
  log(log: string) {
    const max = 1 * 1e3;
    if (this.logData.length >= max) {
      const oldestOne = this.logData.shift();
      this.statehookEmit('log', 'delete', oldestOne);
    }
    const newLog = {
      id: nanoid(),
      log,
      time: Date.now(),
    };
    this.logData.push(newLog);
    this.statehookEmit('log', 'new', newLog);
  }
  watchLog(cb: StatehookWatchCallback): StatehookUnwatch {
    return this.statehookOn('log', cb);
  }
  clearLog() {
    this.logData = [];
    this.statehookEmit('log', 'clear');
  }
  public closeConsoleLogWindow() {
    this.visibility = false;
    this.webTaskManagerService.terminateTask('blockly_console_window');
  }
  public openConsoleLogWindow() {
    this.visibility = true;
    this.webTaskManagerService.newTask('blockly_console_window', {
      windowData: {
        title: 'Console',
        contentComponentName: 'BlocklyConsoleWindowView',
        // unresizeable: true,
        unminimiseable: true,
        style: {
          position: 'absolute',
          // top: '0px',
          // left: '0px',
          top: '0px',
          left: '30%',
          minWidth: '250px',
          minHeight: '300px',
          maxWidth: '100%',
          maxHeight: '100%',
          width: '250px',
          height: '300px',
          zIndex: '10',
          backgroundColor: 'var(--theme-bg)',
        },
        titleBarStyle: {

        },
        onClose: () => {
          this.webTaskManagerService.terminateTask('blockly_console_window');
        },
      },
    });
    setTimeout(() => {
      zIndexManagerService.goTop('blockly_console_window');
    //   // webWindowManagerService.goTop(this.windowRef);
    }, 0.1 * 1e3);
  }
}
