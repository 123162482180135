import {
  StatehookPathParam,
  StatehookPath,
} from './statehook_class';

const statehookLib = require('../statehook_lib');

export function parsePath(path: StatehookPathParam, separator: string): StatehookPath {
  return statehookLib.parsePath(path, separator) as StatehookPath;
}

export function pathGetter(argVar: any, path: StatehookPath): any {
  return statehookLib.pathGetter(argVar, path);
}
