/**
 * @author MrZenW
 * @email MrZenW@Gmail.com, https://MrZenW.com
 * @create date 2021-05-25 13:19:32
 * @modify date 2021-05-25 13:19:33
 * @desc [description]
 */

import {
  ApiService,
  HttpResponse,
  HttpResponseData,
  SubModelBridge,
} from '../api_service';

export interface CertificateFormData {
  certificateImageFile?: File,
  codescore: number,
  playerName: string,
  timeTaken: number,
  workName: string,
  robotType: string,
  [propName: string]: any,
}

export interface StudentSubDoc {
  _id: string,
  studentPlayerName: string,
  studentName?: string,
  studentCode: string,
  platform?: string,
  platformPhoto?: string,
  createdAt: string,
  updatedAt: string,
  deletedAt?: string,
  [propName: string]: any,
}
export interface StudentBridge extends SubModelBridge {
  payload: { [studentCode: string]: StudentSubDoc },
}

export interface RoomDoc {
  roomCode: string,
  ownerUserId?: string,
  ownerSessionId: string,
  levelData?: any,
  currentWorkId?: string,
  photonRegion?: string,
  currentGoogleClassroomCourseId?: string,
  studentsBridge: StudentBridge,
  isBlocklyReadOnly?: boolean,
  createdAt: string,
  updatedAt: string,
  deletedAt?: string,
  [propName: string]: any,
}

export interface RoomOwnerUserInfo {
  subscribedAt?: string | null,
  subscriptionLifetime?: number,
}

export interface StudentResponseData extends HttpResponseData {
  roomDoc: RoomDoc,
}

export interface StudentResponse extends HttpResponse {
  data: StudentResponseData,
}

export interface StudentLoginResponseData extends HttpResponseData {
  studentSubDoc: StudentSubDoc,
}
export interface StudentLoginResponse extends HttpResponse {
  data: StudentLoginResponseData
}

export interface RoomInfoResponseData extends HttpResponseData {
  roomDoc: RoomDoc,
  roomEntranceDoc: any,
  userRole: string,
}
export interface RoomInfoResponse extends HttpResponse {
  data: RoomInfoResponseData,
}

// room leaderboard
export interface RoomLeaderboardApiLeaderboardItem {
  value: string,
  score: number,
}

export interface RoomPropertiesResponseData extends HttpResponseData {
  roomDoc: RoomDoc,
}

export interface RoomPropertiesResponse extends HttpResponse {
  data: RoomPropertiesResponseData,
}

export interface RoomLeaderboardResponseData extends HttpResponseData {
  coderLeaderboard: RoomLeaderboardApiLeaderboardItem[],
  fastestLeaderboard: RoomLeaderboardApiLeaderboardItem[],
}
export interface RoomLeaderboardResponse extends HttpResponse {
  data: RoomLeaderboardResponseData,
}

export interface RoomLeaderboardUploadParamItem {
  playerSessionPublicId: string,
  codescore?: number,
  timeTaken?: number,
  playerName: string,
  roomCode: string,
}

// my score
export interface RoomLeaderboardMyScoreResponseData extends HttpResponseData {
  myCoder: {
    score: number,
    value: string,
    ranking: number,
    gameType?: string,
    workName?: string,
    robotType?: string,
  } | null,
  myFastest: {
    score: number,
    value: string,
    ranking: number,
    gameType?: string,
    workName?: string,
    robotType?: string,
  } | null,
}
export interface RoomLeaderboardMyScoreResponse extends HttpResponse {
  data: RoomLeaderboardMyScoreResponseData,
}

export interface CertificateUploadingResponseData extends HttpResponseData {
  imageUrl: string,
}

export interface CertificateUploadingResponse extends HttpResponse {
  data: CertificateUploadingResponseData,
}

export interface PublishAssignmentToGoogleClassroomResponseData extends HttpResponseData {
  imageUrl: string,
}

export interface PublishAssignmentToGoogleClassroomResponse extends HttpResponse {
  data: PublishAssignmentToGoogleClassroomResponseData,
}

export class RoomApiClass {
  apiService: ApiService = null;

  constructor(apiService: ApiService) {
    this.apiService = apiService;
  }

  roomInfo(roomCode: string, params?: any): Promise<RoomInfoResponse> {
    return this.apiService.clientGet(`/room/${roomCode}/info`, params, null, {
      responseErrorUse: false,
    }) as Promise<RoomInfoResponse>;
  }

  createRoom(): any {
    return this.apiService.clientGet(`/room/create`);
  }

  getEntranceCodeInfo(args: any): any {
    return this.apiService.clientGet(`/room/entrance`, args);
  }

  getLevelData(roomCode: string): any {
    return this.apiService.clientGet(`/room/${roomCode}/level_data`);
  }

  setLevelData(roomCode: string, args?: any): any {
    return this.apiService.clientPost(`/room/${roomCode}/level_data`, null, args);
  }

  modifyRoomInfo(roomCode: string, info: { [propName: string]: any }): any {
    return this.apiService.clientPut(`/room/${roomCode}/info`, null, info);
  }

  // room leaderboard
  roomLeaderboardNewScore(roomCode: string, params: RoomLeaderboardUploadParamItem[], extraData?: any): Promise<RoomLeaderboardResponse> {
    return this.apiService.clientPost(`/room/${roomCode}/room-leaderboard/new-score`, null, {
      extraData,
      newScore: params,
    }) as Promise<RoomLeaderboardResponse>;
  }
  roomLeaderboardGetData(roomCode: string): Promise<RoomLeaderboardResponse> {
    return this.apiService.clientGet(`/room/${roomCode}/room-leaderboard`) as Promise<RoomLeaderboardResponse>;
  }
  roomLeaderboardErase(roomCode: string): Promise<any> {
    return this.apiService.clientDelete(`/room/${roomCode}/room-leaderboard`) as Promise<any>;
  }
  roomLeaderboardGetMyScore(roomCode: string): Promise<RoomLeaderboardMyScoreResponse> {
    return this.apiService.clientGet(`/room/${roomCode}/room-leaderboard/my-score`) as Promise<RoomLeaderboardMyScoreResponse>;
  }

  addNewStudents(roomCode: string, studentInfo: any): Promise<StudentResponse> {
    return this.apiService.clientPost(`/room/${roomCode}/students/new-student`, null, studentInfo) as Promise<StudentResponse>;
  }
  removeAllStudents(roomCode: string): Promise<StudentResponse> {
    return this.apiService.clientPost(`/room/${roomCode}/students/remove-all-students`) as Promise<StudentResponse>;
  }
  modifyStudent(roomCode: string, studentId: string, newInfoSection: any): Promise<StudentResponse> {
    return this.apiService.clientPut(`/room/${roomCode}/students/${studentId}`, null, newInfoSection) as Promise<StudentResponse>;
  }
  deleteStudents(roomCode: string, studentIds: string[] | string): Promise<StudentResponse> {
    if (!Array.isArray(studentIds)) {
      studentIds = [studentIds];
    }
    return this.apiService.clientDelete(`/room/${roomCode}/students`, null, { studentIds }) as Promise<StudentResponse>;
  }
  studentLogin(roomCode: string, studentName: string): Promise<StudentLoginResponse> {
    return this.apiService.clientPost(`/room/${roomCode}/students/login`, null, {
      studentName,
    }, {
      responseErrorUse: false,
    }) as Promise<StudentLoginResponse>;
  }

  /**
   * allow students login with their own user name
   * @param roomCode the current room code
   * @param studentName users' customised student name
   * @returns student login response
   */
  studentLoginByCustomisedName(roomCode: string, studentName: string): Promise<StudentLoginResponse> {
    return this.apiService.clientPost(`/room/${roomCode}/students/login`, null, {
      studentName,
      isCustomisedName: true,
    }, {
      responseErrorUse: false,
    }) as Promise<StudentLoginResponse>;
  }
  setCurrentGoogleClassroomCourseId(roomCode: string, currentGoogleClassroomCourseId?: string): Promise<RoomPropertiesResponse> {
    return this.apiService.clientPatch(`/room/${roomCode}/set-curreent-google-classroom-course-id`, null, {
      currentGoogleClassroomCourseId: currentGoogleClassroomCourseId || '',
    }, {
      responseErrorUse: false,
    }) as Promise<RoomPropertiesResponse>;
  }
  certificateUpload(roomCode: string, certificateFormData: CertificateFormData): Promise<CertificateUploadingResponse> {
    const bodyFormData = new FormData();
    Object.entries(certificateFormData).forEach(([key, value]) => {
      bodyFormData.append(key, value);
    });
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };
    return this.apiService.clientPost(`/room/${roomCode}/certificate-upload`, null, bodyFormData, config) as Promise<CertificateUploadingResponse>;
  }
  publishAssignmentToGoogleClassroom(roomCode: string, workName: string, workLink: string): Promise<PublishAssignmentToGoogleClassroomResponse> {
    return this.apiService.clientPost(`/room/${roomCode}/publish-assignment-to-googleclassroom`, null, {
      workName,
      workLink,
    }) as Promise<PublishAssignmentToGoogleClassroomResponse>;
  }

  sendAssignmentToGoogleClassroom(roomCode: string, studentIds: string[], workName: string, workLink: string) {
    return this.apiService.clientPost(`/room/${roomCode}/publish-assignment-to-googleclassroom`, null, {
      workName,
      workLink,
      studentIds,
    }) as Promise<PublishAssignmentToGoogleClassroomResponse>;
  }

  setBlocklyReadOnly(roomCode: string, readonly: boolean): Promise<RoomPropertiesResponse> {
    return this.apiService.clientPatch(`/room/${roomCode}/set-blockly-read-only`, null, {
      readonly: Boolean(readonly),
    }) as Promise<RoomPropertiesResponse>;
  }
}
