/**
 * @author MrZenW
 * @email MrZenW@Gmail.com, https://MrZenW.com
 * @create date 2021-05-25 13:21:29
 * @modify date 2021-08-20 16:48:12
 * @desc [description]
 */
/* eslint-disable import/prefer-default-export */
/* eslint-disable vars-on-top */
/* eslint-disable no-var */
/* eslint-disable prefer-template */
/* eslint-disable func-names */
/* eslint-disable prefer-rest-params */
/* eslint-disable object-shorthand */
/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-restricted-globals */
/* eslint-disable prefer-spread */
/* eslint-disable no-plusplus */
/* eslint-disable camelcase */
/* eslint-disable prefer-arrow-callback */
/* eslint-disable strict */
/* eslint-disable no-undef */
/* eslint-disable global-require */
/* eslint-disable no-loop-func */

(function moduleify(moduleFactory) {
  'use strict';

  var statehookLib = null;

  if (typeof define === 'function' && define.amd) {
    define('statehook', ['statecore'], function (dep1) {
      statehookLib = statehookLib || moduleFactory(dep1);
      return statehookLib;
    });
  } else if (typeof module === 'object' && typeof exports === 'object') {
    statehookLib = statehookLib || moduleFactory([require('statecore')]);
    module.exports = statehookLib;
  }

  var root = (typeof window !== 'undefined' ? window : typeof self !== 'undefined' ? self : typeof global !== 'undefined' ? global : this);
  if (root && typeof root === 'object') {
    statehookLib = statehookLib || moduleFactory(root['statecore']);
    root['statehook'] = statehookLib;
  }
}(function moduleFactory(deps_Statecore) {
  'use strict';

  if (!deps_Statecore) throw new Error('Statehook is depends on Statecore library!');
  var _StatehookLib_ = {};

  var prototypeHasOwn = Object.prototype.hasOwnProperty;
  function _hasOwn(obj, key) {
    return obj !== undefined && obj !== null && prototypeHasOwn.call(obj, key);
  }

  function _hasKey(obj, key) {
    return obj !== undefined && obj !== null && (key in Object(obj));
  }
  _StatehookLib_.hasKey = _hasKey;

  var prototypeToString = Object.prototype.toString;
  // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/isArray#Polyfill
  var ARRAY_TYPE_STRINGIFY = Object.prototype.toString.call([]);
  function _isArray(value) {
    return prototypeToString.call(value) === ARRAY_TYPE_STRINGIFY;
  }

  var Counter__UUID = 0;
  function __genUUID() {
    Counter__UUID += 1;
    return Counter__UUID + '__' + Date.now() + '__' + Math.random();
  }
  _StatehookLib_.genUUID = __genUUID;

  function _blankFunction() { }

  function _isNone(value) {
    if (value === undefined || null === value) return true;
    if ('number' === typeof value && isNaN(value)) return true;
    return false;
  }
  _StatehookLib_.isNone = _isNone;

  function _isNumber(value) {
    return !isNaN(value);
  }
  _StatehookLib_.isNumber = _isNumber;

  function _isKeyValueObject(value) {
    return value !== null && 'object' === typeof value && prototypeToString.call(value) !== ARRAY_TYPE_STRINGIFY;
  }
  _StatehookLib_.isKeyValueObject = _isKeyValueObject;

  function _isBoolean(value) {
    return typeof value === 'boolean';
  }

  function _isFunction(value) {
    return 'function' === typeof value;
  }
  _StatehookLib_.isFunction = _isFunction;

  function _isString(value) {
    return 'string' === typeof value;
  }

  function _isRegExp(value) {
    return value instanceof RegExp;
  }
  _StatehookLib_.isRegExp = _isRegExp;

  function _isPromise(obj) {
    return !!obj && ('object' === typeof obj || 'function' === typeof obj) && 'function' === typeof obj.then;
  }
  _StatehookLib_.isPromise = _isPromise;

  function _objectAssign(target) {
    if (_isNone(target)) throw new TypeError('Cannot convert undefined or null to object');
    var isTargetArray = _isArray(target);
    var to = Object(target);
    if (isTargetArray) {
      for (var iArray = 1; iArray < arguments.length; iArray += 1) {
        var nextArray = arguments[iArray];
        if (_isArray(nextArray)) {
          to.push.apply(to, nextArray);
        } else {
          to.push.call(to, nextArray);
        }
      }
    } else {
      for (var iObject = 1; iObject < arguments.length; iObject += 1) {
        var nextObj = arguments[iObject];
        if (!_isNone(nextObj)) {
          for (var nextKey in nextObj) {
            if (_hasOwn(nextObj, nextKey)) {
              to[nextKey] = nextObj[nextKey];
            }
          }
        }
      }
    }
    return to;
  }
  _StatehookLib_.objectAssign = _objectAssign;

  function _objectKeys(obj) {
    var keys = [];
    for (var key in obj) {
      keys.push(key);
    }
    return keys;
  }

  function _objectOwnKeysForEach(obj, cb) {
    var keys = _objectKeys(obj); // get all keys before operate it.
    for (var idx in keys) {
      var key = keys[idx];
      if (_hasOwn(obj, key)) {
        if (cb(key, obj) === false) {
          return;
        }
      }
    }
  }

  function _bind(func, bindThis) {
    if (func.bind) return func.bind(bindThis);
    return function () {
      return func.apply(bindThis, arguments);
    };
  }

  function _objectBindProps(target) {
    if (_isNone(target)) throw new TypeError('Cannot convert undefined or null to object');
    var to = Object(target);
    var argsLength = arguments.length;
    for (var iObject = 1; iObject < argsLength; iObject += 1) {
      var nextSource = arguments[iObject];
      if (!_isNone(nextSource)) {
        _objectOwnKeysForEach(nextSource, function (key) {
          if (Object.getOwnPropertyDescriptor) {
            var desc = Object.getOwnPropertyDescriptor(nextSource, key);
            if (desc.get || desc.set) {
              if (desc.get) {
                desc.get = _bind(desc.get, to);
              }
              if (desc.set) {
                desc.set = _bind(desc.set, to);
              }
            } else if (_isFunction(desc.value)) {
              desc.value = _bind(desc.value, to);
            }
            Object.defineProperty(to, key, _objectAssign({}, desc));
          } else if (_isFunction(nextSource[key])) {
            to[key] = _bind(nextSource[key], to);
          } else {
            to[key] = nextSource[key];
          }
        });
      }
    }
    return to;
  }
  _StatehookLib_.objectBindProps = _objectBindProps;

  function _parsePath(path, separator) {
    if (!_isString(separator)) throw new Error('The second parameter \'separator\' must be specified as a string!');
    if (_isArray(path)) return path;
    if (_isNone(path)) return [];
    if (_isRegExp(path)) return [path];
    path += '';
    if (!path) return [];
    if (path[0] === '[' && path[1] === '"') {
      var pathStrLen = path.length;
      if (path[pathStrLen - 2] === '"' && path[pathStrLen - 1] === ']') {
        try {
          return JSON.parse(path);
        } catch (error) {
          // oops, no json
        }
      }
    }
    return path.split(separator);
  }
  _StatehookLib_.parsePath = _parsePath;

  function _pathStatus(argVar, path) {
    var currentPathObject = argVar || {};
    for (var idx = 0; idx < path.length; idx += 1) {
      var key = path[idx];
      if (_hasKey(currentPathObject, key)) {
        currentPathObject = currentPathObject[key];
      } else {
        return { exists: false };
      }
    }
    return { exists: true, value: currentPathObject };
  }
  _StatehookLib_.pathStatus = _pathStatus;

  function _pathGetter(argVar, path) {
    return _pathStatus(argVar, path).value;
  }
  _StatehookLib_.pathGetter = _pathGetter;

  function _pathSetter(rootVar, path, value, onlyWhenParentExists) {
    rootVar = rootVar || {};
    var currentVar = rootVar;
    var parentVar = rootVar;
    var key;
    for (var idx = 0; idx < path.length; idx += 1) {
      key = path[idx];
      if (!_hasKey(currentVar, key) || _isNone(currentVar[key])) {
        if (onlyWhenParentExists) {
          throw new Error('Parent node ' + key + ' does not exist');
        } else {
          currentVar[key] = {};
        }
      }
      parentVar = currentVar;
      currentVar = currentVar[key];
    }
    parentVar[key] = value;
    return rootVar;
  }
  _StatehookLib_.pathSetter = _pathSetter;

  function _pathDeleter(argVar, path) {
    var rootVar = argVar || {};
    var currentVar = rootVar;
    var parentVar = currentVar;
    var key;
    for (var idx = 0; idx < path.length; idx += 1) {
      key = path[idx];
      if (!_hasOwn(currentVar, key)) {
        return rootVar;
      }
      parentVar = currentVar;
      currentVar = currentVar[key];
    }
    delete parentVar[key];
    return rootVar;
  }
  _StatehookLib_.pathDeleter = _pathDeleter;

  function _isSameArray(pattern, input) {
    if (pattern.length !== input.length) return false;
    for (var idx = 0; idx < pattern.length; idx += 1) {
      var patternCurr = pattern[idx];
      var inputCurr = input[idx];
      if (_isRegExp(patternCurr)) {
        if (!patternCurr.test(inputCurr)) {
          return false;
        }
      } else if (patternCurr + '' !== inputCurr + '') {
        return false;
      }
    }
    return true;
  }

  function _isBeforeOrSameArray(pattern, input) {
    if (pattern.length < input.length) return false;
    for (var idx = 0; idx < input.length; idx += 1) {
      var patternCurr = pattern[idx];
      var inputCurr = input[idx];
      if (_isRegExp(patternCurr)) {
        if (!patternCurr.test(inputCurr)) {
          return false;
        }
      } else if (patternCurr + '' !== inputCurr + '') {
        return false;
      }
    }
    return true;
  }

  function _isBeforeArray(pattern, input) {
    if (pattern.length <= input.length) return false;
    for (var idx = 0; idx < input.length; idx += 1) {
      var patternCurr = pattern[idx];
      var inputCurr = input[idx];
      if (_isRegExp(patternCurr)) {
        if (!patternCurr.test(inputCurr)) {
          return false;
        }
      } else if (patternCurr + '' !== inputCurr + '') {
        return false;
      }
    }
    return true;
  }

  function _isAfterOrSameArray(pattern, input) {
    if (pattern.length > input.length) return false;
    for (var idx = 0; idx < pattern.length; idx += 1) {
      var patternCurr = pattern[idx];
      var inputCurr = input[idx];
      if (_isRegExp(patternCurr)) {
        if (!patternCurr.test(inputCurr)) {
          return false;
        }
      } else if (patternCurr + '' !== inputCurr + '') {
        return false;
      }
    }
    return true;
  }

  function _isAfterArray(pattern, input) {
    if (pattern.length >= input.length) return false;
    for (var idx = 0; idx < pattern.length; idx += 1) {
      var patternCurr = pattern[idx];
      var inputCurr = input[idx];
      if (_isRegExp(patternCurr)) {
        if (!patternCurr.test(inputCurr)) {
          return false;
        }
      } else if (patternCurr + '' !== inputCurr + '') {
        return false;
      }
    }
    return true;
  }

  function _isStatehookPackage(statehookStore) {
    return statehookStore
    && statehookStore.statehookFunctions
    && statehookStore.statehookFunctions.statehookGetName
    && statehookStore.baseObject;
  }

  // statehook definition begin
  var Store__StatehookInstance = {};
  var Store__UnwatchId = {};
  var Counter__UnwatchId = 0;

  function _getStatehookPackage(statehookName) {
    if (!statehookName) throw new Error('Statehook name must be provided');
    statehookName = _parsePath(statehookName, '/');
    var existingStatehook = _pathGetter(Store__StatehookInstance, statehookName);
    if (_isStatehookPackage(existingStatehook)) {
      return existingStatehook;
    }
    return null;
  }

  deps_Statecore = _objectAssign({}, deps_Statecore);

  // lib funcions start
  var Counter__StatehookName = 0;
  function createStatehook(createStatehookOpt, initArg) {
    createStatehookOpt = createStatehookOpt || {};
    if (_isString(createStatehookOpt) || _isNumber(createStatehookOpt) || _isArray(createStatehookOpt)) {
      createStatehookOpt = { statehookName: createStatehookOpt };
    }
    var statehookName = _parsePath(createStatehookOpt.statehookName, '/');
    if (statehookName.length > 0) {
      var existingStatehook = _getStatehookPackage(statehookName);
      if (existingStatehook) {
        return existingStatehook.baseObject;
      }
    } else {
      Counter__StatehookName += 1;
      statehookName = ['__unnamed_statehook__', Counter__StatehookName + '__' + __genUUID()];
    }
    var __pathSeperator = createStatehookOpt.pathSeperator || '.';
    var __destroyFunc = null;
    var __INNER_EVENT_NAMESPACE = __genUUID();
    var __statecoreInstance = deps_Statecore.createStatecore({});
    var __initFunction = _isFunction(initArg) ? initArg : null;
    var __initObject = _isKeyValueObject(initArg) ? _objectAssign({}, initArg) : null;
    var __unwatcherDefaultNS = 'default_ns__' + __genUUID();
    var __unwatchers = {};
    var __statehookThis = {};
    _objectAssign(__statehookThis, {
      // statecoreNotifyAllObservers: __statecoreInstance.statecoreNotifyAllObservers,
      // statecoreAddObserver: __statecoreInstance.statecoreAddObserver,
      // statecoreDiscard: __statecoreInstance.statecoreDiscard,
      // statecoreIsDiscarded: __statecoreInstance.statecoreIsDiscarded,
      // statecoreGetState: __statecoreInstance.statecoreGetState,
      // statecoreSetState: __statecoreInstance.statecoreSetState,

      statehookName: _objectAssign([], statehookName),
      statehookGetName: function () {
        return _objectAssign([], statehookName);
      },
      statehookClone: function () {
        return _objectAssign(
          {},
          __statehookThis,
          { statehookName: __statehookThis.statehookGetName() },
        );
      },
      statehookIsDiscarded: __statecoreInstance.statecoreIsDiscarded,
      statehookDiscard: function () {
        if (__statehookThis.statehookIsDiscarded()) {
          console.warn('Attempt to discard a discarded statehook instance under path: ' + __statehookThis.statehookName.join('/'));
          return;
        }
        if (_isFunction(__destroyFunc)) {
          __destroyFunc();
        }
        if (__initObject) {
          _objectOwnKeysForEach(__initObject, function (key) {
            delete __initObject[key];
          });
        }
        _pathDeleter(Store__StatehookInstance, __statehookThis.statehookGetName());
        __statehookThis.statehookEmit(__INNER_EVENT_NAMESPACE, {
          action: 'discard',
          method: 'statehookDiscard',
        });
        __statehookThis.statehookUnwatchAll();
        __statecoreInstance.statecoreDiscard();
      },

      statehookRegisterUnwatch: function (argUnwatchFunctions) {
        if (arguments.length > 1) throw new Error('The second argument are not allowed to be specified!');
        return __statehookThis.statehookRegisterUnwatchWithNS(__unwatcherDefaultNS, argUnwatchFunctions);
      },
      statehookRegisterUnwatchWithNS: function (ns, argUnwatchFunctions) {
        if (!_isString(ns) || !ns) throw new Error('The first argument: namespace must be specified in a String!');
        __unwatchers[ns] = __unwatchers[ns] || [];
        if (!_isArray(argUnwatchFunctions)) {
          argUnwatchFunctions = [argUnwatchFunctions];
        }
        var allUnwatchFunctions = [];
        _objectOwnKeysForEach(argUnwatchFunctions, function (key) {
          var _currentFunc = argUnwatchFunctions[key];
          if (!_isFunction(_currentFunc)) {
            throw new Error('Only a function can be registered into the unwatch manager!');
          }
          allUnwatchFunctions.push(_currentFunc);
        });
        var unwatchFunction = function () {
          if (!allUnwatchFunctions) return;
          while (allUnwatchFunctions.length > 0) allUnwatchFunctions.pop()();
          allUnwatchFunctions = null;
        };
        __unwatchers[ns].push(unwatchFunction);
        return unwatchFunction;
      },

      statehookEmit: __statecoreInstance.statecoreNotifyAllObservers,
      statehookOn: function (eventName, func) {
        if (!_isFunction(func)) throw new Error('Listener must be a function!');
        return __statehookThis.statehookRegisterUnwatch(
          __statecoreInstance.statecoreAddObserver(function (emitEventName) {
            if (emitEventName === eventName) {
              func.apply(__statehookThis, arguments);
            }
          }),
        );
      },

      statehookGet: __statecoreInstance.statecoreGetState,
      statehookSet: function (value) {
        return __statehookThis.statehookUpdate(function () {
          return { value: value };
        });
      },
      statehookGetPath: function (path) {
        if (arguments.length === 0) {
          return __statehookThis.statehookGet();
        }
        path = _parsePath(path, __pathSeperator);
        return _pathGetter(__statehookThis.statehookGet(), path);
      },
      statehookSetPath: function (path, value, _opts) {
        return __statehookThis.statehookUpdatePath(_parsePath(path, __pathSeperator), function () {
          return { value: value };
        }, _opts);
      },
      statehookSetManyPaths: function (values, basePath) {
        basePath = _parsePath(basePath, __pathSeperator);
        if (_isArray(values)) {
          // [{key1: value1, key2: value2}]
          _objectOwnKeysForEach(values, function (idx) {
            __statehookThis.statehookSetManyPaths(values[idx], basePath);
          });
        } else {
          // {key1: value1, key2: value2}
          _objectOwnKeysForEach(values, function (key) {
            var subPath = _parsePath(key, __pathSeperator);
            __statehookThis.statehookSetPath([].concat(basePath, subPath), values[key]);
          });
        }
      },
      statehookReset: function () {
        if (_isFunction(__destroyFunc)) {
          __destroyFunc();
        }
        if (__initFunction) {
          __destroyFunc = __initFunction(__statehookThis.statehookClone());
          return null;
        } else if (__initObject) {
          return __statehookThis.statehookReplace(_objectAssign({}, __initObject));
        } else {
          throw new Error('No init argument is provided, cannot reset it.');
        }
      },
      statehookResetPath: function (path, _opts) {
        if (__initFunction) throw new Error('Init argument is not an object, could not find a path in it.');
        _opts = Object(_opts || {});
        path = _parsePath(path, __pathSeperator);
        var pathInfo = _pathStatus(__initObject, path);
        if (pathInfo.exists) {
          return __statehookThis.statehookSetPath(path, pathInfo.value, _opts);
        } else {
          return __statehookThis.statehookRemovePath(path, _opts);
        }
      },
      statehookReplace: function (newState, _opts) {
        _opts = Object(_opts || {});
        var currentState = __statehookThis.statehookGet();
        _objectOwnKeysForEach(currentState, function (path) {
          if (!_hasOwn(newState, path)) {
            __statehookThis.statehookRemovePath(path, _opts);
          }
        });
        return __statehookThis.statehookSetManyPaths(_objectAssign({}, newState), _opts);
      },
      statehookHasPath: function (path) {
        path = _parsePath(path, __pathSeperator);
        return !!_pathStatus(__statehookThis.statehookGet(), path).exists;
      },

      statehookUpdate: function (updater, _opts) {
        _opts = Object(_opts || {});
        var oldState = __statecoreInstance.statecoreGetState();
        var newStateInfo = updater({ value: _objectAssign({}, oldState), exists: true });
        if (!_isKeyValueObject(newStateInfo)) newStateInfo = {};
        var silent = _opts.silent;
        if (_isBoolean(newStateInfo.silent)) {
          silent = newStateInfo.silent;
        }
        var newStateValue = __statecoreInstance.statecoreSetState(newStateInfo.value);
        if (!silent) {
          __statehookThis.statehookEmit(__INNER_EVENT_NAMESPACE, {
            oldState: oldState,
            newState: newStateValue,
            action: 'update',
            method: 'statehookUpdate',
          });
        }
        return newStateValue;
      },
      statehookUpdatePath: function (path, updater, _opts) {
        _opts = Object(_opts || {});
        path = _parsePath(path, __pathSeperator);
        var oldRootState = __statecoreInstance.statecoreGetState();
        var oldRathStateInfo = _pathStatus(oldRootState, path);
        var oldPathState = oldRathStateInfo.value;
        var newPathStateInfo = updater(oldRathStateInfo);
        if (!_isKeyValueObject(newPathStateInfo)) newPathStateInfo = {};
        var silent = newPathStateInfo.silent || _opts.silent;
        var newPathState = newPathStateInfo.value;
        var eventAction;
        var newRootState;
        // delete
        if (newPathState === undefined) {
          newRootState = _pathDeleter(_objectAssign({}, oldRootState), path);
          eventAction = 'delete';
        } else { // update
          newRootState = _pathSetter(_objectAssign({}, oldRootState), path, newPathState, _opts.onlyWhenParentExists);
          eventAction = 'update';
        }
        __statecoreInstance.statecoreSetState(newRootState);
        if (!silent) {
          __statehookThis.statehookEmit(__INNER_EVENT_NAMESPACE, {
            path: path,
            oldPathState: oldPathState,
            oldPathStateExists: oldRathStateInfo.exists,
            newPathState: newPathState,
            action: eventAction,
            method: 'statehookUpdatePath',
          });
        }
        return newPathState;
      },

      statehookRemovePath: function (path, _opts) {
        _opts = Object(_opts || {});
        path = _parsePath(path, __pathSeperator);
        var oldPathState;
        var newRootState = __statehookThis.statehookUpdatePath(path, function (oldPathInfo) {
          oldPathState = oldPathInfo.value;
          return { exists: false };
        }, { silent: true });
        if (!_opts.silent) {
          __statehookThis.statehookEmit(__INNER_EVENT_NAMESPACE, {
            path: path,
            oldPathState: oldPathState,
            action: 'delete',
            method: 'statehookRemovePath',
          });
        }
        return newRootState;
      },

      statehookCreateUnwatchId: function (cb) {
        if (!_isFunction(cb)) {
          throw new Error('Only a function can be used for creating a watch id!');
        }
        Counter__UnwatchId += 1;
        Store__UnwatchId[Counter__UnwatchId] = cb;
        return Counter__UnwatchId;
      },
      statehookUnwatchById: function (id) {
        var unwatchFunc = Store__UnwatchId[id];
        if (_isFunction(unwatchFunc)) {
          delete Store__UnwatchId[id];
          unwatchFunc();
        }
      },

      statehookWatch: function (cb) {
        return __statehookThis.statehookOn(__INNER_EVENT_NAMESPACE, function (eventName, eventObject) {
          cb(undefined, eventObject);
        });
      },

      statehookWatchPath: function (watchPath, cb) {
        watchPath = _parsePath(watchPath, __pathSeperator);
        return __statehookThis.statehookWatch(function (eventType, statehookEvent) {
          var path = _parsePath(statehookEvent.path, __pathSeperator);
          if (_isSameArray(watchPath, path)) {
            cb.apply(__statehookThis, arguments);
          }
        });
      },

      statehookWatchPathAfter: function (matchPath, cb) {
        matchPath = _parsePath(matchPath, __pathSeperator);
        return __statehookThis.statehookWatch(function (eventType, statehookEvent) {
          var path = _parsePath(statehookEvent.path, __pathSeperator);
          if (_isAfterArray(matchPath, path)) {
            cb.apply(__statehookThis, arguments);
          }
        });
      },
      statehookWatchPathSameOrAfter: function (matchPath, cb) {
        matchPath = _parsePath(matchPath, __pathSeperator);
        return __statehookThis.statehookWatch(function (eventType, statehookEvent) {
          var path = _parsePath(statehookEvent.path, __pathSeperator);
          if (_isAfterOrSameArray(matchPath, path)) {
            cb.apply(__statehookThis, arguments);
          }
        });
      },
      statehookWatchPathBefore: function (matchPath, cb) {
        matchPath = _parsePath(matchPath, __pathSeperator);
        return __statehookThis.statehookWatch(function (eventType, statehookEvent) {
          var path = _parsePath(statehookEvent.path, __pathSeperator);
          if (_isBeforeArray(matchPath, path)) {
            cb.apply(__statehookThis, arguments);
          }
        });
      },
      statehookWatchPathSameOrBefore: function (matchPath, cb) {
        matchPath = _parsePath(matchPath, __pathSeperator);
        return __statehookThis.statehookWatch(function (eventType, statehookEvent) {
          var path = _parsePath(statehookEvent.path, __pathSeperator);
          if (_isBeforeOrSameArray(matchPath, path)) {
            cb.apply(__statehookThis, arguments);
          }
        });
      },

      statehookUnwatchAll: function () {
        if (arguments.length > 0) throw new Error('statehookUnwatchAll() does not accept any argument, instead, you can use statehookUnwatchWithNS(namespace)');
        _objectOwnKeysForEach(__unwatchers, function (ns) {
          __statehookThis.statehookUnwatchWithNS(ns);
        });
      },
      statehookUnwatchWithNS: function (ns) {
        ns = ns || __unwatcherDefaultNS;
        var unwatchersNS = __unwatchers[ns] || [];
        while (unwatchersNS.length > 0) unwatchersNS.shift()();
      },
      statehookUnwatchWithNamespace: function (ns) {
        return __statehookThis.statehookUnwatchWithNS(ns);
      },
    });
    if (createStatehookOpt.unmanageable !== true) { // it means that it is a manageable statehook
      _pathSetter(Store__StatehookInstance, __statehookThis.statehookGetName(), {
        statehookFunctions: __statehookThis.statehookClone(),
        baseObject: __statehookThis.statehookClone(),
      });
    }
    // init at the last step
    if (__initFunction) {
      __destroyFunc = __initFunction(__statehookThis.statehookClone());
    } else if (__initObject) {
      __statehookThis.statehookSetManyPaths(_objectAssign({}, __initObject));
    }
    return __statehookThis.statehookClone();
  }
  _StatehookLib_.createStatehook = createStatehook;

  function hasStatehook(statehookName) {
    statehookName = _parsePath(statehookName, '/');
    return _isStatehookPackage(_pathGetter(Store__StatehookInstance, statehookName));
  }
  _StatehookLib_.hasStatehook = hasStatehook;

  function getStatehookFunctions(statehookName) {
    if (!statehookName) throw new Error('Statehook name must be provided');
    statehookName = _parsePath(statehookName, '/');
    var existingStatehook = _pathGetter(Store__StatehookInstance, statehookName);
    if (_isStatehookPackage(existingStatehook)) {
      return existingStatehook.statehookFunctions.statehookClone();
    }
    return null;
  }
  _StatehookLib_.getStatehookFunctions = getStatehookFunctions;

  function getAllStatehooks() {
    return _objectAssign({}, Store__StatehookInstance);
  }
  _StatehookLib_.getAllStatehooks = getAllStatehooks;

  function getStatehook(statehookName) {
    if (!statehookName) throw new Error('Statehook name must be provided');
    statehookName = _parsePath(statehookName, '/');
    var existingStatehook = _pathGetter(Store__StatehookInstance, statehookName);
    if (_isStatehookPackage(existingStatehook)) {
      return existingStatehook.baseObject;
    }
    return null;
  }
  _StatehookLib_.getStatehook = getStatehook;

  function rebaseStatehook(statehookName, base) {
    statehookName = _parsePath(statehookName, '/');
    var existingStatehook = _pathGetter(Store__StatehookInstance, statehookName);
    if (existingStatehook) {
      _objectBindProps(
        base,
        existingStatehook.statehookFunctions.statehookClone(),
      );
      existingStatehook.baseObject = base;
      _pathSetter(Store__StatehookInstance, statehookName, existingStatehook);
    } else {
      throw new Error('statehook ' + JSON.stringify(statehookName) + ' Not found');
    }
    return existingStatehook.baseObject;
  }
  _StatehookLib_.rebaseStatehook = rebaseStatehook;

  function discardStatehook(statehookName) {
    statehookName = _parsePath(statehookName, '/');
    var instance = _pathGetter(Store__StatehookInstance, statehookName);
    if (instance) {
      instance.statehookFunctions.statehookDiscard();
      _pathDeleter(Store__StatehookInstance, statehookName);
    } else {
      throw new Error('statehook ' + JSON.stringify(statehookName) + ' Not found');
    }
  }
  _StatehookLib_.discardStatehook = discardStatehook;

  function statehookify(statehookifyOpt, baseObject, initArg) {
    statehookifyOpt = statehookifyOpt || {};
    if (_isString(statehookifyOpt)) statehookifyOpt = { statehookName: statehookifyOpt };
    statehookifyOpt.statehookName = _parsePath(statehookifyOpt.statehookName, '/');
    baseObject = baseObject || {};
    if (statehookifyOpt.statehookName.length > 0 && hasStatehook(statehookifyOpt.statehookName)) {
      throw new Error('Statehook ' + JSON.stringify(statehookifyOpt.statehookName) + ' has existed!');
    }
    if (statehookifyOpt.bindThisToBase) {
      _objectBindProps(baseObject, baseObject); // bind the functions from base to base itself
    }
    var initFuncWhenStatehookify = null;
    var initFuncWhenCreateStatehook = null;
    var envRef = { initFunc: null, discardFunc: null };
    if (_isFunction(initArg)) {
      initFuncWhenStatehookify = function (_thisStatehook) {
        envRef.discardFunc = initArg(_thisStatehook, baseObject);
      };
      initFuncWhenCreateStatehook = function (_thisStatehook) {
        if (_isFunction(envRef.initFunc)) {
          envRef.initFunc(_thisStatehook);
        }
        return function () {
          if (_isFunction(envRef.discardFunc)) {
            envRef.discardFunc();
          }
        };
      };
    }
    var _statehook = createStatehook(statehookifyOpt, initFuncWhenCreateStatehook || initArg);
    _objectBindProps(baseObject, _statehook);
    if (initFuncWhenStatehookify) {
      initFuncWhenStatehookify(_statehook);
      envRef.initFunc = initFuncWhenStatehookify;
    }

    if (statehookifyOpt.statehookName.length > 0) {
      // rebase the statehook
      return rebaseStatehook(
        statehookifyOpt.statehookName,
        baseObject,
      );
    }
    return baseObject;
  }
  _StatehookLib_.statehookify = statehookify;

  function useStatehook(inputArgs, initFunc, reactLibrary) {
    var { useState, useEffect } = reactLibrary;
    var [statehookFlag] = useState({
      uuid: __genUUID(),
      isMounted: true,
    });
    var stateObjectForceUpdateValue = useState(0);
    var setForceUpdateValue = stateObjectForceUpdateValue[1];
    var statehookName = ['_created_by_useStatehook_', statehookFlag.uuid];
    var isFirst = !hasStatehook(statehookName);
    var statehook = createStatehook(statehookName, {});
    if (isFirst) {
      var isInitFunction = _isFunction(initFunc);
      // bind default functions
      var unoverrideableFuncs = {
        _useStatehookForceUpdate: (function () {
          if (statehookFlag.isMounted) {
            setForceUpdateValue(__genUUID());
          } else {
            throw new Error('Cannot render an unmounted component!');
          }
        }),
      };
      var useStatehookFuncs = {
        isUseStatehook: true,
        useStatehookArgs: undefined,
        _useStatehookInit: isInitFunction ? initFunc : _blankFunction,
        _useStatehookReceiveArgs: (function (_inputArgs) { this.useStatehookArgs = _inputArgs; }),
        _useStatehookAfterRender: _blankFunction,
        _useStatehookBeforeRender: _blankFunction,
      };
      _objectAssign(statehook, useStatehookFuncs, unoverrideableFuncs);
      // END: bind default functions
      if (!isInitFunction) {
        _objectBindProps(statehook, initFunc, unoverrideableFuncs);
      }
      statehook = rebaseStatehook(
        statehookName,
        statehook,
      );
      var _returnedDestroyFunction = statehook._useStatehookInit(
        inputArgs,
        statehook._useStatehookForceUpdate,
        statehook,
      );
      if (_isPromise(_returnedDestroyFunction)) {
        throw new Error('The function _useStatehookInit can not either be an async function or return a promise!');
      }
      if (!_isFunction(_returnedDestroyFunction) && !_isNone(_returnedDestroyFunction)) {
        throw new Error('The function _useStatehookInit can only return either a function type variable or a none-like variable!');
      }
      statehookFlag.destroyFunction = _returnedDestroyFunction;
    } // END: init
    // inputArgs
    statehook._useStatehookReceiveArgs(inputArgs);
    (function (_statehookFlag) { // this function for closture
      useEffect(function () {
        // only once on init
        return function () {
          _statehookFlag.isMounted = false;
          if (_isFunction(_statehookFlag.destroyFunction)) {
            _statehookFlag.destroyFunction(statehook);
          }
          statehook.statehookDiscard();
        };
      }, []);
      useEffect(function () {
        // every times after render
        statehook.statehookEmit('_useStatehookAfterRender', inputArgs);
        statehook._useStatehookAfterRender(inputArgs);
      });
    }(statehookFlag));
    statehook.statehookEmit('_useStatehookBeforeRender', inputArgs);
    statehook._useStatehookBeforeRender(inputArgs);
    return statehook;
  }
  _StatehookLib_.useStatehook = useStatehook;

  function useUUID(React) {
    var { useState } = React;
    var [uuid, setUUID] = useState(0);
    if (uuid === 0) {
      setUUID(__genUUID());
    }
    return uuid;
  }
  _StatehookLib_.useUUID = useUUID;

  return _objectAssign({}, _StatehookLib_);
}));
