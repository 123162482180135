/**
 * @author MrZenW
 * @email MrZenW@Gmail.com, https://MrZenW.com
 * @create date 2021-07-21 10:57:39
 * @modify date 2021-07-21 10:57:39
 * @desc [description]
 */

import { BlockCategoryInfo } from '$/pages/playground/blockly_workspace/block_toolbox/all_blocks';
import { ApiService, HttpResponse, HttpResponseData } from '../api_service';

export interface ConfigData {[propName: string]: any}

export interface ConfigDoc {
  _id: string,
  configKey: string,
  configNamespace: string,
  configValue?: string,
  configData: ConfigData,
  ownerUserId?: string,
  ownerSessionId?: string,
  [propName: string]: any,
}

export interface ToolboxConfigDataBlockItem {
  isAvailable: boolean,
  blockType: string,
  categoryPath?: BlockCategoryInfo[],
}

export interface UserCategory {
  userCategory: string,
  isAvailable: boolean,
  isVisible: boolean,
  content: ToolboxConfigDataBlockItem[],
}

export interface ToolboxConfigData extends ConfigData {
  blocks?: {
    common?: UserCategory,
    roleS?: UserCategory,
    roleT?: UserCategory,
    roleE?: UserCategory,
    roleA?: UserCategory,
    roleM?: UserCategory,
    subscriber?: UserCategory,
    [propName: string]: UserCategory,
  },
}
export interface ToolboxConfigDoc extends ConfigDoc {
  configData: ToolboxConfigData,
}

export interface GlobalConfigResponseData extends HttpResponseData {
  configDoc: ConfigDoc,
}

export interface GlobalConfigResponse extends HttpResponse {
  data: GlobalConfigResponseData,
}

export interface ChangeToolboxUserCategoryParam {
  common?: UserCategory,
  roleS?: UserCategory,
  roleT?: UserCategory,
  roleE?: UserCategory,
  roleA?: UserCategory,
  roleM?: UserCategory,
  subscriber?: UserCategory,
  [userCategory: string]: UserCategory,
}

export class ConfigApiClass {
  apiService: ApiService = null;
  constructor(apiService: ApiService) {
    this.apiService = apiService;
  }

  getGlobalItem(key: string): Promise<GlobalConfigResponse> {
    return this.apiService.clientGet(`/config/global/${key}`) as Promise<GlobalConfigResponse>;
  }

  patchChangeToolboxUserCategory(settings: ChangeToolboxUserCategoryParam): Promise<GlobalConfigResponse> {
    return this.apiService.clientPatch(`/config/global/blockly_toolbox`, null, settings) as Promise<GlobalConfigResponse>;
  }
}
