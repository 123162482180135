/**
 * @author MrZenW
 * @email MrZenW@Gmail.com, https://MrZenW.com
 * @create date 2021-07-23 15:44:43
 * @modify date 2021-07-23 15:44:43
 * @desc [description]
 */

import {
  parsePath,
  StatehookClass, StatehookName,
} from '$/statehook/typescript';
import { UserDoc } from './api_service/apis/user.api';

export class UserServiceClass extends StatehookClass {
  static override grabOne(statehookName: StatehookName) {
    return super.grabOne([].concat(['UserServiceClass'], parsePath(statehookName, '/')));
  }
  public sessionService: any = null
  public userDoc: UserDoc = null
  override _onInit(args: any) {
    const { userDoc, sessionService } = args;
    this.sessionService = sessionService;
    this.userDoc = userDoc as UserDoc;
  }
  get isSubscriptionValid(): boolean {
    const { subscribedDate } = this;
    if (!subscribedDate) return false;
    const subscriptionLifetime = this.userDoc?.subscriptionLifetime || 0;
    return (subscribedDate.getTime() + subscriptionLifetime) > Date.now();
  }
  get subscribedDate() :Date {
    if (!this.userDoc?.subscribedAt) return null;
    return new Date(this.userDoc?.subscribedAt);
  }
}
