/**
 * @author MrZenW
 * @email MrZenW@Gmail.com, https://MrZenW.com
 * @create date 2021-05-25 13:20:14
 * @modify date 2022-03-07 11:22:48
 * @desc [description]
 */
const Statehook = require('$/statehook');
const sysendHelper = require('$/libraries/sysendHelper');

exports.grabCrossWindowChannel = (channel) => {
  const statehookName = 'cross_tab_channel/' + channel;
  let channelHook = Statehook.getStatehook(statehookName);
  if (!channelHook) {
    channelHook = Statehook.statehookify({ statehookName, bindThisToBase: true }, {
      get channelId() {
        return this.statehookGetPath('channelId');
      },
      set channelId(newValue) {
        return this.statehookSetPath('channelId', newValue);
      },
      receiveBroadcast(cb) {
        return this.statehookRegisterUnwatch(sysendHelper.receiveBroadcast(this.channelId, cb));
      },
      publishBroadcast(data) {
        return sysendHelper.publishBroadcast(this.channelId, data);
      },
      listenTo(cb) {
        return sysendHelper.listenTo(this.channelId, cb);
      },
      request(data) {
        return sysendHelper.request(this.channelId, data);
      },
    }, {
      channelId: channel,
    });
  }
  return channelHook;
};

exports.globalCrossWindowChannelService = exports.grabCrossWindowChannel('__global_channel__');
