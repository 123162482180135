/**
 * @author MrZenW
 * @email MrZenW@Gmail.com, https://MrZenW.com
 * @create date 2022-04-13 11:01:51
 * @modify date 2022-04-13 11:01:51
 * @desc [description]
 */

import { parsePath, StatehookClass, StatehookName } from '$/statehook/typescript';

const { grabZIndexManagerService } = require('$/services/z_index_manager_service');

const zIndexManagerService = grabZIndexManagerService('kais-solo-global-z-index-manager-service');

export class UserProfileWindowServiceClass extends StatehookClass {
  sessionService: any
  static override grabOne(statehookName: StatehookName) {
    return super.grabOne([].concat(['UserProfileWindowService'], parsePath(statehookName, '/')));
  }
  _onInit(args: any) {
    const { sessionService } = args;
    this.sessionService = sessionService;
  }
  show() {
    this.sessionService.webTaskManagerService.newTask('user_profile_window', {
      windowData: {
        title: 'Profile',
        contentComponentName: 'UserProfileWindow',
        unresizeable: true,
        unminimiseable: true,
        style: {
          // unity
          position: 'absolute',
          // top: '0px',
          // left: '0px',
          top: '10px',
          left: '30%',
          width: 'auto',
          height: 'auto',
          zIndex: '10',
        },
        onClose: () => {
          this.sessionService.webTaskManagerService.terminateTask('user_profile_window');
        },
      },
    });
    setTimeout(() => {
      zIndexManagerService.goTop('user_profile_window');
    }, 0.1 * 1e3);
  }
  close() {
    this.sessionService.webTaskManagerService.terminateTask('user_profile_window');
  }
}
