/**
 * @author MrZenW
 * @email MrZenW@Gmail.com, https://MrZenW.com
 * @create date 2021-05-25 13:19:23
 * @modify date 2021-05-25 13:19:23
 * @desc [description]
 */
const React = require('react');
const PropTypes = require('prop-types');
const Loading = require('$/components/Loading');

const LoadingPage = () => {
  return (
    <Loading style={{ width: '100%', height: '100vh' }}>
      OPEN BETA VERSION LOADING ...
    </Loading>
  );
};

const PreloadWrapper = (props) => {
  const { component: Component, injectProps } = props;
  return <Component {...injectProps}>{props.children}</Component>;
};
PreloadWrapper.propTypes = {
  component: PropTypes.oneOfType([PropTypes.func, PropTypes.element]).isRequired,
  injectProps: PropTypes.shape({}).isRequired,
};

const Preload = (params) => {
  const { loader, loading: LoadingComponent, delay } = params;
  return (props) => {
    const [component, setComponent] = React.useState({});
    if (!component.current) {
      new Promise((resolve) => {
        setTimeout(resolve, delay || 0);
      }).then(() => {
        loader().then((_model) => {
          setComponent({ current: _model.default }); // it fixes a bug for React
        });
      });
      return <LoadingComponent />;
    } else {
      const ComponentClass = component.current;
      return <PreloadWrapper component={ComponentClass} injectProps={props}>{props.children}</PreloadWrapper>;
    }
  };
};

const pages = {};
pages.playground = Preload({
  loader: () => import('./playground'),
  loading: LoadingPage,
});

pages.googleOAuthCodeReceiver = Preload({
  loader: () => import('./google-oauth-code-receiver'),
  loading: LoadingPage,
});

pages.playground__webgl_view = Preload({
  loader: () => import('./playground__webgl_view'),
  loading: LoadingPage,
});

pages.session_terminated = Preload({
  loader: () => import('./session_terminated'),
  loading: LoadingPage,
});

pages.create_room = Preload({
  loader: () => import('./create_room'),
  loading: LoadingPage,
});

pages.entrance = Preload({
  loader: () => import('./entrance'),
  loading: LoadingPage,
});

pages.homepage = Preload({
  loader: () => import('./homepage'),
  loading: LoadingPage,
});

pages.work = Preload({
  loader: () => import('./work'),
  loading: LoadingPage,
});

pages.verifyEmail = Preload({
  loader: () => import('./verify_email'),
  loading: LoadingPage,
});

pages.recover = Preload({
  loader: () => import('./recover'),
  loading: LoadingPage,
});

pages.error404 = Preload({
  loader: () => import('./error404'),
  loading: LoadingPage,
});

module.exports = pages;
