/**
 * @author MrZenW
 * @email MrZenW@Gmail.com, https://MrZenW.com
 * @create date 2021-05-25 13:21:09
 * @modify date 2022-09-05 17:10:16
 * @desc [description]
 */
const {
  statehookify,
  getStatehook,
  parsePath,
  // pathSetter,
  // pathGetter,
} = require('$/statehook');
// const { animateFrame } = require('$/libraries/animate');

module.exports = {
  grabUiService: (statehookName) => {
    statehookName = [].concat(['ui_service'], parsePath(statehookName, '/'));
    let instance = getStatehook(statehookName);
    if (!instance) {
      instance = statehookify({ statehookName, bindThisToBase: true }, {
        // hamburger menu
        get hamburgerMenuVisible() {
          return this.statehookGetPath('hamburgerMenuVisible') || false;
        },
        set hamburgerMenuVisible(newValue) {
          this.statehookSetPath('hamburgerMenuVisible', !!newValue);
        },
        onHamburgerMenuVisbleChange(cb) {
          return this.statehookWatchPath('hamburgerMenuVisible', cb);
        },
        // pattern selector menu
        get patternMenuVisible() {
          return this.statehookGetPath('patternMenuVisible') || false;
        },
        set patternMenuVisible(newValue) {
          this.statehookSetPath('patternMenuVisible', !!newValue);
        },
        get workModalVisible() {
          return this.statehookGetPath('workModalVisible');
        },
        set workModalVisible(newValue) {
          return this.statehookSetPath('workModalVisible', newValue);
        },
        onWorkModalVisibleChange(cb) {
          return this.statehookWatchPath('workModalVisible', cb);
        },
        // room project modal
        get workModalCurrentTab() {
          return this.statehookGetPath('workModalCurrentTab');
        },
        set workModalCurrentTab(newValue) {
          return this.statehookSetPath('workModalCurrentTab', newValue);
        },
        onWorkModalCurrentTabChange(cb) {
          return this.statehookWatchPath('workModalCurrentTab', cb);
        },

        get webglLoadMode() {
          let current = this.statehookGetPath('webglLoadMode');
          if (!current) {
            this.statehookSetPath('webglLoadMode', 'play');
            current = this.statehookGetPath('webglLoadMode');
          }
          return current;
        },
        set webglLoadMode(newValue) {
          return this.statehookSetPath('webglLoadMode', newValue);
        },

        get isWebglReady() {
          return this.statehookGetPath('isWebglReady');
        },
        set isWebglReady(newValue) {
          return this.statehookSetPath('isWebglReady', newValue);
        },
        watchIsWebglReady(cb) {
          return this.statehookWatchPath('isWebglReady', cb);
        },
        // taskbar
        get isTaskbarCollapsed() {
          return this.statehookGetPath('isTaskbarCollapsed');
        },
        set isTaskbarCollapsed(newValue) {
          return this.statehookSetPath('isTaskbarCollapsed', newValue);
        },
        watchIsTaskbarCollapsed(cb) {
          return this.statehookWatchPath('isTaskbarCollapsed', cb);
        },

        // room leaderboard
        get roomLeaderboardModalVisibilityStatus() {
          return this.statehookGetPath('roomLeaderboardModalVisibilityStatus');
        },
        set roomLeaderboardModalVisibilityStatus(newValue) {
          return this.statehookSetPath('roomLeaderboardModalVisibilityStatus', newValue);
        },
        onRoomLeaderboardModalVisibilityStatusChange(cb) {
          return this.statehookWatchPath('roomLeaderboardModalVisibilityStatus', cb);
        },
        get robotStatusSwitchTooltipVisibility() {
          return this.statehookGetPath('robotStatusSwitchTooltipVisibility');
        },
        set robotStatusSwitchTooltipVisibility(newValue) {
          this.statehookSetPath('robotStatusSwitchTooltipVisibility', newValue);
        },
        onRobotStatusSwitchTooltipVisibilityChange(cb) {
          return this.statehookWatchPath('robotStatusSwitchTooltipVisibility', cb);
        },
        // playerListWindowVisibility
        get playerListWindowVisibility() {
          return this.statehookGetPath('playerListWindowVisibility');
        },
        set playerListWindowVisibility(newValue) {
          this.statehookSetPath('playerListWindowVisibility', newValue);
        },
        onPlayerListWindowVisibilityChange(cb) {
          return this.statehookWatchPath('playerListWindowVisibility', cb);
        },
        fps: 0,
        numberOfSequentialLowFPS: 0,
        isDetectionRunning: false,
        _stopDetectWebpageFPS() {
          return this.statehookUnwatchWithNS('_startDetectWebpageFPS');
        },
        _startDetectWebpageFPS() {
          this.isDetectionRunning = true;
          let lastTimeStamp = Date.now();
          let windowVisible = true;
          let count = 0;
          const detect = () => {
            requestAnimationFrame(() => {
              if (!this.isDetectionRunning) return;
              const current = Date.now();
              count += 1;
              const diff = current - lastTimeStamp;
              if (diff >= 1000) {
                lastTimeStamp = current;
                if (windowVisible) {
                  this.fps = count / (diff / 1000);
                  if (this.fps <= 30) {
                    this.numberOfSequentialLowFPS += 1;
                  } else {
                    this.numberOfSequentialLowFPS = 0;
                  }
                  if (this.numberOfSequentialLowFPS >= 3) {
                    alertLib.alertNotification({
                      icon: 'warning',
                      title: 'A low FPS has been detected!',
                    });
                  }
                  // this.statehookEmit('fps', this.fps);
                }
                count = 0;
              }
              detect();
            });
          };
          detect();
          function _onDocumentVisibilityChange() {
            if (document.visibilityState === 'visible') {
              windowVisible = true;
            } else {
              windowVisible = false;
            }
          }
          window.document.addEventListener('visibilitychange', _onDocumentVisibilityChange, false);
          return this.statehookRegisterUnwatchWithNS('_startDetectWebpageFPS', () => {
            this.isDetectionRunning = false;
            window.document.removeEventListener('visibilitychange', _onDocumentVisibilityChange, false);
          });
        },
        detectWebglLoadingTimeout(onTimeout) {
          setTimeout(async () => {
            if (!this.isWebglReady) {
              onTimeout = onTimeout || (() => {});
              onTimeout();
              const result = await alertLib.alertWarning({
                showConfirmButton: true,
                confirmButtonText: 'Refresh',
                allowOutsideClick: false,
                html: `
                <div>
                  <p>
                    <strong>
                      Kainundrum works best with Operating Systems:
                    </strong>
                  </p>
                  <ul style="text-align: left; width: 100%">
                    <li style="list-style-type:'- '"> Microsoft Windows 10</li>
                    <li style="list-style-type:'- '"> Apple OS X 10.10 or later</li>
                    <li style="list-style-type:'- '"> Google Chrome OS on Chromebooks</li>
                  </ul>
                  <p>
                    <strong>
                      Browsers that work best with Kainundrum:
                    </strong>
                  </p>
                  <ul style="text-align: left; width: 100%">
                    <li style="list-style-type:'- '"> Google Chrome version 50 (or newer)</li>
                    <li style="list-style-type:'- '"> Safari 10 (or newer)</li>
                    <li style="list-style-type:'- '"> Microsoft Edge (Chromium)</li>
                    <li style="list-style-type:'- '"> Mozilla FireFox 4 (or newer)</li>
                  </ul>
                </div>
                `,
              });
              if (result.isConfirmed) {
                window.location.reload();
              }
            }
          }, 2 * 60 * 1e3);
        },

        // tablet mode
        get isTabletMode() {
          return !!this.statehookGetPath('isTabletMode');
        },
        set isTabletMode(newValue) {
          this.statehookSetPath('isTabletMode', newValue);
        },
        watchTabletModeChange(cb) {
          return this.statehookWatchPath('isTabletMode', cb);
        },

        // blockly writing on/off mode
        get isBlocklyReadOnly() {
          return !!this.statehookGetPath('isBlocklyReadOnly');
        },
        set isBlocklyReadOnly(newValue) {
          this.statehookSetPath('isBlocklyReadOnly', newValue);
        },
        watchBlocklyReadOnly(cb) {
          return this.statehookWatchPath('isBlocklyReadOnly', cb);
        },
      });
      // instance._startDetectWebpageFPS();
    }
    return instance;
  },
};
