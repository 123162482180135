const statehookLib = require('../statehook_lib');

export type StatehookName = string|number|Array<string|number>;
export type StatehookCreateStatehookInit = any;
export type StatehookPathParam = null|undefined|unknown|string|number|(Array<string|number>);
export type StatehookWatchPathParam = null|undefined|unknown|string|number|RegExp|(Array<string|RegExp|number>);
export type StatehookPath = Array<string|number>;
export type StatehookUnwatch = (...rest: Array<any>) => any;
export type StatehookWatchCallback = (eventName: undefined, ...rest: Array<any>) => any;
export type StatehookEventCallback = (eventName: string, ...rest: Array<any>) => any;
export type StatehookNodeStatus = { value?: any, exists: boolean };
export type StatehookUpdateNewStateInfo = { silent?: boolean, value?: any };
export type StatehookUpdateFunction = (nodeStatus: StatehookNodeStatus) => StatehookUpdateNewStateInfo;
export type StatehookStateObject = any;
export interface StatehookCreateStatehookOption {
  statehookName?: StatehookName,
  unmanageable?: boolean,
  bindThisToBase?: boolean,
}
export interface StatehookUpdateEvent {
  path?: StatehookPath,
  oldState?: any,
  oldPathState?: any,
  oldPathStateExists?: boolean,
  newState?: any,
  newPathState?: any,
  action: 'update' | 'discard' | 'delete' | 'update',
  method: 'statehookUpdate' | 'statehookDiscard' | 'statehookUpdatePath' | 'statehookRemovePath',
}
export interface StatehookUpdateOptions {
  silent?: boolean, onlyWhenParentExists?: boolean
}

export class StatehookClass {
  private _statehookFunctions: any;

  static getStatehook(statehookName: StatehookName): any {
    const instance = statehookLib.getStatehook(statehookName);
    if (instance) return instance as any;
    return instance as any;
  }

  static hasStatehook(statehookName: StatehookName): boolean {
    return statehookLib.hasStatehook(statehookName) as boolean;
  }

  static statehookify(_opts?: StatehookName|StatehookCreateStatehookOption, baseObject?: any, init?: StatehookCreateStatehookInit): any {
    return statehookLib.statehookify(_opts, baseObject, init) as any;
  }

  static grabOne(_opts?: StatehookName|StatehookCreateStatehookOption, init?: StatehookCreateStatehookInit): any {
    let opts: StatehookCreateStatehookOption = {};
    if (Array.isArray(_opts) || ['string', 'number'].indexOf(typeof _opts) > -1) {
      opts.statehookName = _opts as StatehookName;
    } else {
      opts = _opts as StatehookCreateStatehookOption;
    }
    // const statehookName = [].concat([this.name], statehookLib.parsePath(opts.statehookName, '/'));
    const statehookName = statehookLib.parsePath(opts.statehookName, '/');
    const existingInstance: any = this.getStatehook(statehookName);
    if (existingInstance) {
      return existingInstance;
    }
    return new this(Object.assign({}, opts, { statehookName }), init);
  }

  constructor(_opts?: StatehookName|StatehookCreateStatehookOption, init?: StatehookCreateStatehookInit) {
    this._statehookFunctions = StatehookClass.statehookify(_opts, this, init).statehookClone();
  }

  private _isInited: boolean = false

  get isInited(): boolean {
    return this._isInited;
  }

  set isInited(newValue: boolean) {
    if (!this._isInited) this._isInited = newValue;
  }

  public initIfNeeded(...rest: any[]): this {
    return this.initialiseIfNeeded(...rest);
  }
  public initialiseIfNeeded(...rest: any[]): this {
    if (!this.isInited) {
      this.isInited = true;
      this._onInit(...rest);
    }
    return this;
  }

  protected _onInit(...rest: any[]): any {
  }

  get statehookName(): StatehookName {
    return this._statehookFunctions.statehookGetName() as StatehookName;
  }

  statehookGetName(): StatehookName {
    return this._statehookFunctions.statehookGetName() as StatehookName;
  }

  statehookClone(): any {
    return this._statehookFunctions.statehookClone() as any;
  }

  statehookIsDiscarded(): boolean {
    return this._statehookFunctions.statehookIsDiscarded() as boolean;
  }

  statehookDiscard(): void {
    this._statehookFunctions.statehookDiscard();
  }

  statehookRegisterUnwatch(unwatch: StatehookUnwatch|Array<StatehookUnwatch>): StatehookUnwatch {
    return this._statehookFunctions.statehookRegisterUnwatch(unwatch) as StatehookUnwatch;
  }

  statehookRegisterUnwatchWithNS(namespace: string, unwatch: StatehookUnwatch|Array<StatehookUnwatch>): StatehookUnwatch {
    return this._statehookFunctions.statehookRegisterUnwatchWithNS(namespace, unwatch) as StatehookUnwatch;
  }

  statehookEmit(eventName: string, ...rest: Array<any>): any {
    return this._statehookFunctions.statehookEmit(eventName, ...rest) as any;
  }

  statehookOn(eventName: string, callback: StatehookEventCallback): StatehookUnwatch {
    return this._statehookFunctions.statehookOn(eventName, callback) as StatehookUnwatch;
  }

  statehookGet(): StatehookStateObject {
    return this._statehookFunctions.statehookGet() as StatehookStateObject;
  }

  statehookSet(value: any): any {
    return this._statehookFunctions.statehookSet(value) as any;
  }

  statehookGetPath(path: StatehookPathParam): StatehookStateObject {
    return this._statehookFunctions.statehookGetPath(path) as StatehookStateObject;
  }

  statehookSetPath(path: StatehookPathParam, value: any): any {
    return this._statehookFunctions.statehookSetPath(path, value) as any;
  }

  statehookSetManyPaths(value: any, basePath?: StatehookPathParam): any {
    return this._statehookFunctions.statehookSetManyPaths(value, basePath) as any;
  }

  statehookReset(): any {
    return this._statehookFunctions.statehookReset() as any;
  }

  statehookResetPath(path: StatehookPathParam, _opts?: StatehookUpdateOptions): any {
    return this._statehookFunctions.statehookResetPath(path, _opts) as any;
  }

  statehookReplace(newState: any, _opts?: StatehookUpdateOptions): any {
    return this._statehookFunctions.statehookReplace(newState, _opts) as any;
  }

  statehookHasPath(path: StatehookPathParam): boolean {
    return this._statehookFunctions.statehookHasPath(path) as boolean;
  }

  statehookUpdate(updater: StatehookUpdateFunction, _opts?: StatehookUpdateOptions): any {
    return this._statehookFunctions.statehookUpdate(updater, _opts) as any;
  }

  statehookUpdatePath(path: StatehookPathParam, updater: StatehookUpdateFunction, _opts?: StatehookUpdateOptions): any {
    return this._statehookFunctions.statehookUpdatePath(path, updater, _opts) as any;
  }

  statehookRemovePath(path: StatehookPathParam, _opts?: StatehookUpdateOptions): any {
    return this._statehookFunctions.statehookRemovePath(path, _opts) as any;
  }

  statehookCreateUnwatchId(unwatch: StatehookUnwatch): number {
    return this._statehookFunctions.statehookCreateUnwatchId(unwatch) as number;
  }

  statehookUnwatchById(unwatchId: number): any {
    return this._statehookFunctions.statehookUnwatchById(unwatchId) as any;
  }

  statehookWatch(watchCallback: StatehookWatchCallback): StatehookUnwatch {
    return this._statehookFunctions.statehookWatch(watchCallback) as StatehookUnwatch;
  }

  statehookWatchPath(path: StatehookWatchPathParam, watchCallback: StatehookWatchCallback): StatehookUnwatch {
    return this._statehookFunctions.statehookWatchPath(path, watchCallback) as StatehookUnwatch;
  }

  statehookWatchPathAfter(path: StatehookWatchPathParam, watchCallback: StatehookWatchCallback): StatehookUnwatch {
    return this._statehookFunctions.statehookWatchPathAfter(path, watchCallback) as StatehookUnwatch;
  }

  statehookWatchPathSameOrAfter(path: StatehookWatchPathParam, watchCallback: StatehookWatchCallback): StatehookUnwatch {
    return this._statehookFunctions.statehookWatchPathSameOrAfter(path, watchCallback) as StatehookUnwatch;
  }

  statehookWatchPathBefore(path: StatehookWatchPathParam, watchCallback: StatehookWatchCallback): StatehookUnwatch {
    return this._statehookFunctions.statehookWatchPathBefore(path, watchCallback) as StatehookUnwatch;
  }

  statehookWatchPathSameOrBefore(path: StatehookWatchPathParam, watchCallback: StatehookWatchCallback): StatehookUnwatch {
    return this._statehookFunctions.statehookWatchPathSameOrBefore(path, watchCallback) as StatehookUnwatch;
  }

  statehookUnwatchAll(): any {
    return this._statehookFunctions.statehookUnwatchAll() as any;
  }

  statehookUnwatchWithNS(namespace: string): any {
    return this._statehookFunctions.statehookUnwatchWithNS(namespace) as any;
  }

  statehookUnwatchWithNamespace(namespace: string): any {
    return this._statehookFunctions.statehookUnwatchWithNamespace(namespace) as any;
  }
}
