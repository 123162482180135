require('$/services/config_service');
const React = require('react');
const LocationEmbed = require('$/components/embeder/LocationEmbed');
const LocationSelector = require('$/components/embeder/LocationSelector');
const { checkMobileDevice } = require('./libraries/check-mobile-device');
const pages = require('./pages');

module.exports = () => {
  const allowMobileDevice = localStorage.getItem('allow-mobile-device');
  if (!allowMobileDevice && checkMobileDevice()) {
    if (!`${window.location.pathname}`.toLowerCase().startsWith('/mob')) {
      alertLib.alertWarning({
        // the statement was from Ronel
        title: 'Kainundrum not supported on iOS (iPad) or mobile devices!',
        showConfirmButton: false,
        allowOutsideClick: false,
      });
      return <div />;
    }
  }
  return (
    <LocationSelector>
      <LocationEmbed embedTest={/^\/$/} embedComponent={pages.homepage} />
      <LocationEmbed embedTest={/^\/clear\/?$/} embedComponent={() => { localStorage.clear(); window.location.href = '/'; return <div />; }} />
      <LocationEmbed embedTest={/^\/mob\/?$/} embedComponent={() => { localStorage.setItem('allow-mobile-device', !localStorage.getItem('allow-mobile-device')); alertLib.alertInfo('Mobile devices: ' + (localStorage.getItem('allow-mobile-device') ? 'ENABLED' : 'DISENABLED')).then(() => { window.location.href = '/'; }); return <div />; }} />
      <LocationEmbed embedTest={/^\/recover\/?$/} embedComponent={pages.recover} />
      {/* <LocationEmbed embedTest={/^\/$/} embedComponent={pages.create_room} /> */}
      <LocationEmbed embedTest={/^\/google-oauth\/code-receiver\/?$/} embedComponent={pages.googleOAuthCodeReceiver} />
      <LocationEmbed embedTest={/^\/work\/(.*)\/?$/} embedComponent={pages.work} />
      <LocationEmbed embedTest={/^\/create_room\/?$/} embedComponent={pages.create_room} />
      <LocationEmbed embedTest={/^\/entrance\/(.*)\/?$/} embedComponent={pages.entrance} />
      <LocationEmbed embedTest={/^\/entrance\/?$/} embedComponent={pages.entrance} />
      <LocationEmbed embedTest={/^\/session_terminated\/?$/} embedComponent={pages.session_terminated} />
      <LocationEmbed embedTest={/^\/verify_email\/?$/} embedComponent={pages.verifyEmail} />
      <LocationEmbed embedTest={/^\/([A-Za-z0-9]{8})\/?$/} embedComponent={pages.playground} />
      <LocationEmbed embedTest={/^\/([A-Za-z0-9]{8})\/webgl_view\/?$/} embedComponent={pages.playground__webgl_view} />
      <LocationEmbed embedTest={/(.*)/} embedComponent={pages.error404} />
    </LocationSelector>
  );
};
