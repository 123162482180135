/**
 * @author MrZenW
 * @email MrZenW@Gmail.com, https://MrZenW.com
 * @create date 2022-04-19 17:15:17
 * @modify date 2022-04-19 17:15:17
 * @desc [description]
 */

import React from 'react';
import {
  parsePath,
  StatehookClass,
  StatehookName,
} from '$/statehook/typescript';
import { BlocklyWindowTitle } from '$/pages/playground/blockly_workspace/blockly_window_title';

const { webWindowManagerService } = require('$/components/web_window');

export class BlocklyWindowServiceClass extends StatehookClass {
  private sessionService: any
  static override grabOne(statehookName: StatehookName) {
    return super.grabOne([].concat(['BlocklyWindowService'], parsePath(statehookName, '/')));
  }
  protected _onInit(args: any) {
    const { sessionService } = args;
    this.sessionService = sessionService;
  }
  get webTaskManagerService(): any {
    return this.sessionService.webTaskManagerService;
  }
  close() {
    // this.visibility = false;
    // this.webTaskManagerService.terminateTask('player_list_window');
  }
  open() {
    // this.visibility = true;
    this.sessionService.webTaskManagerService.newTask('blockly_window', {
      windowData: {
        title: () => {
          return <BlocklyWindowTitle />;
        },
        contentComponentName: 'BlocklyWorkspace',
        style: {
          // blockly
          position: 'absolute',
          top: '10px',
          left: '10px',
          width: 'calc(50% - 20px)',
          height: 'calc(100% - 120px)',
          zIndex: '20',
          minWidth: '180px',
          minHeight: '180px',
          maxWidth: '100%',
          maxHeight: '100%',
        },
      },
    });
    // setTimeout(() => {
    //   zIndexManagerService.goTop('blockly_window');
    // }, 3 * 1e3);
  }
  get isWindowMinimised() {
    const blocklyWebWindowStatehook = webWindowManagerService.getWebWindowStatehook('blockly_window');
    return blocklyWebWindowStatehook && blocklyWebWindowStatehook.isWindowMinimised;
  }
  get isWindowNormal() {
    const blocklyWebWindowStatehook = webWindowManagerService.getWebWindowStatehook('blockly_window');
    return blocklyWebWindowStatehook && blocklyWebWindowStatehook.isWindowNormal;
  }
  minimise(event?: any) {
    const blocklyWebWindowStatehook = webWindowManagerService.getWebWindowStatehook('blockly_window');
    if (blocklyWebWindowStatehook) {
      // const pythonCodeWindowService = this.sessionService.pythonCodeWindowService;
      const duration = 0.3 * 1e3;
      if (blocklyWebWindowStatehook.isWindowNormal) {
        const bodyRect = document.body.getBoundingClientRect();
        let top = bodyRect.bottom;
        let left = bodyRect.width / 2;
        if (event && event.target) {
          const elemRect = event.target.getBoundingClientRect();
          top = elemRect.top - bodyRect.top;
          left = elemRect.left - bodyRect.left;
        }
        // try {
        //   pythonCodeWindowService.close();
        // } catch (error) {
        //   //
        // }
        blocklyWebWindowStatehook.minimise({
          top,
          left,
          duration,
        });
        return true;
      } else {
        // pythonCodeWindowService.open();
        blocklyWebWindowStatehook.goTop();
        blocklyWebWindowStatehook.recover({
          duration,
        });
      }
    }
    return false;
  }
}
