/**
 * @author MrZenW
 * @email MrZenW@Gmail.com, https://MrZenW.com
 * @create date 2021-05-25 13:11:45
 * @modify date 2021-05-25 13:11:45
 * @desc [description]
 */
const React = require('react');
const PropTypes = require('prop-types');
const { WebWindow } = require('./web_window');

const contentComponentStore = {};

const registerWebWindowContentComponent = (componentName, component) => {
  if (contentComponentStore[componentName]) throw new Error(`Component ${componentName} has existed!`);
  contentComponentStore[componentName] = component;
};

exports.registerWebWindowContentComponent = registerWebWindowContentComponent;

const WebTaskWindow = (props) => {
  const {
    webTaskKey,
    webTaskData,
    onMinimise,
    onMaximise,
    onRecover,
    onClose,
    titleColourFocused,
    titleColourUnfocused,
  } = props;
  const { windowData } = webTaskData || {};
  const { unresizeable, unminimiseable } = windowData || {};
  const title = typeof windowData.title === 'function' ? windowData.title() : windowData.title;
  const style = typeof windowData.style === 'function' ? windowData.style() : windowData.style;
  return (
    <WebWindow
      webTaskKey={webTaskKey}
      title={title || 'Unnamed window'}
      style={style}
      windowRef={webTaskData.windowData.receiveWindowRef}
      unresizeable={unresizeable}
      unminimiseable={unminimiseable}
      onMinimise={onMinimise}
      onMaximise={onMaximise}
      onRecover={onRecover}
      onClose={onClose}
      titleColourFocused={titleColourFocused}
      titleColourUnfocused={titleColourUnfocused}
    >
      {(windowStatehook) => {
        const Component = contentComponentStore[windowData.contentComponentName];
        return (
          <Component
            webTaskKey={webTaskKey}
            webTaskData={webTaskData}
            receiveRef={webTaskData.windowData.receiveContentComponentRef}
            webWindowStatehookRef={windowStatehook}
          />
        );
      }}
    </WebWindow>
  );
};
WebTaskWindow.propTypes = {
  webTaskKey: PropTypes.string.isRequired,
  webTaskData: PropTypes.shape({
    windowData: PropTypes.shape({
      title: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
      style: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({})]),
      contentComponentName: PropTypes.string.isRequired,
      unresizeable: PropTypes.bool,
      unminimiseable: PropTypes.bool,
      receiveContentComponentRef: PropTypes.func,
      receiveWindowRef: PropTypes.func,
    }).isRequired,
    taskData: PropTypes.shape({}).isRequired,
  }).isRequired,
  onMinimise: PropTypes.func,
  onMaximise: PropTypes.func,
  onRecover: PropTypes.func,
  onClose: PropTypes.func,
  titleColourFocused: PropTypes.string,
  titleColourUnfocused: PropTypes.string,
};
WebTaskWindow.defaultProps = {
  onMinimise: (() => {}),
  onMaximise: (() => {}),
  onRecover: (() => {}),
  onClose: undefined,
  titleColourFocused: '',
  titleColourUnfocused: '',
};

exports.WebTaskWindow = WebTaskWindow;
