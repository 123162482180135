/**
 * @author MrZenW
 * @email MrZenW@Gmail.com, https://MrZenW.com
 * @create date 2021-05-25 13:21:18
 * @modify date 2021-07-19 13:33:47
 * @desc [description]
 */

const { statehookify } = require('$/statehook');

module.exports = statehookify(
  { statehookName: 'web_window_manager_service', bindThisToBase: true },
  {
    set currentFocusedWebTaskKey(newValue) {
      return this.statehookSetPath('currentFocusedWebTaskKey', newValue);
    },
    get currentFocusedWebTaskKey() {
      return this.statehookGetPath('currentFocusedWebTaskKey');
    },
    checkCurrentFocusedWebTaskKey(windowKey) {
      return this.currentFocusedWebTaskKey === windowKey;
    },
    watchCurrentFocusedWebTaskKey(cb) {
      return this.statehookWatchPath('currentFocusedWebTaskKey', cb);
    },
    getWebWindowStatehook(windowKey) {
      return this.statehookGetPath(['windowStatehooks', windowKey]);
    },
    setWebWindowStatehook(windowKey, statehook) {
      const currentStatehook = this.getWebWindowStatehook(windowKey);
      if (currentStatehook) {
        currentStatehook.statehookDiscard();
        this.statehookRemovePath(['windowStatehooks', windowKey]);
      }
      if (statehook) {
        this.statehookSetPath(['windowStatehooks', windowKey], statehook);
      }
    },
  },
);
