/**
 * @author MrZenW
 * @email MrZenW@Gmail.com, https://MrZenW.com
 * @create date 2021-10-06 14:37:05
 * @modify date 2021-10-06 14:37:05
 * @desc [description]
 */

import Player from '@vimeo/player';
import videojs from 'video.js';
import { StatehookWatchCallback, UseStatehookClass } from '$/statehook/typescript';

require('video.js/dist/video-js.min.css');

export class TutorialVideoWindowModel extends UseStatehookClass {
  iframeRef: any = null;
  vimeoPlayer: Player = null;
  sessionService: any = null;
  videojsPlayer: videojs.Player = null;
  webWindowStatehook: any = null;
  get playerState(): string {
    return this.statehookGetPath('playerState') as string;
  }
  set playerState(newState: string) {
    this.statehookSetPath('playerState', newState);
  }
  watchPlayerStateChange(cb: StatehookWatchCallback) {
    return this.statehookWatchPath('playerState', cb);
  }
  _useStatehookInit(inputArgs: any) {
    const { sessionContext, webWindowContext } = inputArgs;
    this.sessionService = sessionContext.sessionService;
    this.webWindowStatehook = webWindowContext.statehook;

    // close video window when the webgl loaded
    if (!this.sessionService.uiService.isWebglReady) {
      this.statehookRegisterUnwatch([
        this.sessionService.uiService.watchIsWebglReady(() => {
          // firstWorkState the state to repersent if the user has finished the first tutorial game
          // this variable is controlled by the webgl's message;
          const firstWorkState = this.sessionService.sessionDoc.extendedData?.firstWorkState;
          if (this.sessionService.uiService.isWebglReady
            && this.playerState !== 'playing'
            && !!firstWorkState) {
            setTimeout(() => {
              this.sessionService.tutorialVideoService.closeVideoWindow();
            }, 3 * 1e3);
          }
        }),
      ]);
    }
  }
  receiveIFrameRef(ref: any) {
    if (!this.iframeRef) {
      this.iframeRef = ref;
      this._createVimeoPlayer();
    }
  }
  private _createVimeoPlayer() {
    this.vimeoPlayer = new Player(this.iframeRef);
    const onPlay = () => {
      this.playerState = 'playing';
    };
    const onPause = () => {
      this.playerState = 'paused';
    };
    const onEnded = () => {
      this.playerState = 'ended';
    };

    this.vimeoPlayer.on('play', onPlay);
    this.vimeoPlayer.on('pause', onPause);
    this.vimeoPlayer.on('ended', onEnded);
    this.statehookRegisterUnwatch(() => {
      this.vimeoPlayer.off('play', onPlay);
      this.vimeoPlayer.off('pause', onPause);
      this.vimeoPlayer.off('ended', onEnded);
      this.vimeoPlayer.destroy();
    });
  }
  receiveVideoElement(ref: any) {
    if (ref && !this.videojsPlayer) {
      const onPlay = () => {
        this.playerState = 'playing';
      };
      const onPause = () => {
        this.playerState = 'paused';
      };
      const onEnded = () => {
        this.playerState = 'ended';
      };
      this.videojsPlayer = videojs(ref, {}, () => {
        this.videojsPlayer.on('play', onPlay);
        this.videojsPlayer.on('pause', onPause);
        this.videojsPlayer.on('ended', onEnded);
      });
      this.statehookRegisterUnwatch(() => {
        this.videojsPlayer.off('play', onPlay);
        this.videojsPlayer.off('pause', onPause);
        this.videojsPlayer.off('ended', onEnded);
      });
    }
  }
}
