/**
 * @author MrZenW
 * @email MrZenW@Gmail.com, https://MrZenW.com
 * @create date 2022-04-19 17:15:17
 * @modify date 2022-04-19 17:15:17
 * @desc [description]
 */

import {
  parsePath,
  StatehookClass,
  StatehookName,
} from '$/statehook/typescript';

const { webWindowManagerService } = require('$/components/web_window');

export class WebglWindowServiceClass extends StatehookClass {
  private sessionService: any
  static override grabOne(statehookName: StatehookName) {
    return super.grabOne([].concat(['WebglWindowServiceClass'], parsePath(statehookName, '/')));
  }
  protected _onInit(args: any) {
    const { sessionService } = args;
    this.sessionService = sessionService;
  }
  get webTaskManagerService():any {
    return this.sessionService.webTaskManagerService;
  }
  get currentPlayerName(): string {
    return this.statehookGetPath('currentPlayerName');
  }
  set currentPlayerName(newValue: string) {
    this.statehookSetPath('currentPlayerName', newValue);
  }
  close() {
    // this.visibility = false;
    // this.webTaskManagerService.terminateTask('player_list_window');
  }
  open() {
    // this.visibility = true;
    this.webTaskManagerService.newTask('webgl_window', {
      windowData: {
        title: () => { return this.currentPlayerName || 'WebGL task'; },
        contentComponentName: 'WebglWindow',
        style: {
          // unity
          position: 'absolute',
          // top: '0px',
          // left: '0px',
          top: '10px',
          left: 'calc(50% + 10px)',
          width: 'calc(50% - 20px)',
          height: 'calc(100% - 120px)',
          minWidth: '180px',
          minHeight: '180px',
          maxWidth: '100%',
          maxHeight: '100%',
          zIndex: '10',
        },
      },
    });
  }
  get isWindowMinimised() {
    const webglWebWindowStatehook = webWindowManagerService.getWebWindowStatehook('webgl_window');
    return webglWebWindowStatehook && webglWebWindowStatehook.isWindowMinimised;
  }
  get isWindowNormal() {
    const webglWebWindowStatehook = webWindowManagerService.getWebWindowStatehook('webgl_window');
    return webglWebWindowStatehook && webglWebWindowStatehook.isWindowNormal;
  }
  minimise(event?: any) {
    const webglWebWindowStatehook = webWindowManagerService.getWebWindowStatehook('webgl_window');
    if (webglWebWindowStatehook) {
      const duration = 0.3 * 1e3;
      if (webglWebWindowStatehook.isWindowNormal) {
        const bodyRect = document.body.getBoundingClientRect();
        let top = bodyRect.bottom;
        let left = bodyRect.width / 2;
        if (event && event.target) {
          const elemRect = event.target.getBoundingClientRect();
          top = elemRect.top - bodyRect.top;
          left = elemRect.left - bodyRect.left;
        }
        webglWebWindowStatehook.minimise({
          top,
          left,
          duration,
        });
        return true;
      } else {
        webglWebWindowStatehook.goTop();
        webglWebWindowStatehook.recover({
          duration,
        });
      }
    }
    return false;
  }
}
