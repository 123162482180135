/**
 * @author MrZenW
 * @email MrZenW@Gmail.com, https://MrZenW.com
 * @create date 2021-12-14 17:51:15
 * @modify date 2021-12-14 17:51:15
 * @desc [description]
 */

import {
  parsePath,
  StatehookClass, StatehookName, StatehookUnwatch, StatehookWatchCallback,
} from '$/statehook/typescript';
import { ApiService } from './api_service';
import { GoogleClassroomCourseItem, GoogleOAuthDoc } from './api_service/apis/user.api';

export enum GoogleClassroomLoadingStatus {
  pending = 1,
  loading,
  done,
  failed,
}
export class GoogleOAuthServiceClass extends StatehookClass {
  static override grabOne(statehookName: StatehookName) {
    return super.grabOne([].concat(['GoogleOAuthServiceClass'], parsePath(statehookName, '/')));
  }
  public sessionService: any = null
  public googleOAuthDoc: GoogleOAuthDoc = null
  override _onInit(args: any) {
    const { googleOAuthDoc, sessionService } = args;
    this.sessionService = sessionService;
    this.googleOAuthDoc = googleOAuthDoc as GoogleOAuthDoc;
  }
  get apiService(): ApiService {
    return this.sessionService?.apiService;
  }
  get courseList(): GoogleClassroomCourseItem[] {
    return this.statehookGetPath('courseList') || [];
  }
  set courseList(newList: GoogleClassroomCourseItem[]) {
    this.statehookSetPath('courseList', newList);
  }
  watchCourseList(cb: StatehookWatchCallback): StatehookUnwatch {
    return this.statehookWatchPath('courseList', cb);
  }
  // course
  get courseLoadingStatus(): GoogleClassroomLoadingStatus {
    return this.statehookGetPath('courseLoadingStatus') || GoogleClassroomLoadingStatus.pending;
  }
  set courseLoadingStatus(newValue: GoogleClassroomLoadingStatus) {
    this.statehookSetPath('courseLoadingStatus', newValue);
  }
  watchCourseLoadingStatus(cb: StatehookWatchCallback): StatehookUnwatch {
    return this.statehookWatchPath('courseLoadingStatus', cb);
  }
  courseLoadingError: any
  async loadAllCourses() {
    this.courseLoadingError = null;
    this.courseLoadingStatus = GoogleClassroomLoadingStatus.loading;
    try {
      const httpResponse = await this.apiService.userApi.googleClassroomCourseList();
      this.courseList = httpResponse.data.courseList;
      this.courseLoadingStatus = GoogleClassroomLoadingStatus.done;
    } catch (error) {
      this.courseLoadingError = error;
      this.courseLoadingStatus = GoogleClassroomLoadingStatus.failed;
    }
  }
}
