/**
 * @author MrZenW
 * @email MrZenW@Gmail.com, https://MrZenW.com
 * @create date 2021-06-15 17:29:29
 * @modify date 2021-06-15 17:29:29
 * @desc [description]
 */
/* eslint-disable no-use-before-define */
import {
  StatehookUnwatch,
  StatehookClass,
} from './statehook_class';

const statehookLib = require('../statehook_lib');

export type UseStatehookArgs = any;
export type UseStatehookForceUpdate = () => void;
export type UseStatehookInitFunctionReturn = void|null|undefined|StatehookUnwatch;
export type UseStatehookInitFunction = (inputArgs?: UseStatehookArgs, _useStatehookForceUpdate?: UseStatehookForceUpdate, statehook?: StatehookClass) => UseStatehookInitFunctionReturn;
export interface ReactLibrary {
  useState(state: any): Array<any>;
  useEffect(effectFunction: () => void|null|undefined|(() => void), scope?: any): void;
  [propName: string]: any;
}

interface StatehookFlag {
  statehookObj?: UseStatehookClass,
  destroyFunction: any,
  isMounted: boolean,
}

export class UseStatehookClass extends StatehookClass {
  static useStatehook(args: UseStatehookArgs, reactLibrary: ReactLibrary): any {
    const { useState, useEffect } = reactLibrary;

    const statehookFlag: StatehookFlag = useState({
      statehookObj: undefined,
      destroyFunction: null,
      isMounted: true,
    } as StatehookFlag)[0] as StatehookFlag;
    const stateObjectForceUpdateValue = useState(0);
    if (!statehookFlag.statehookObj) {
      statehookFlag.statehookObj = new this().initialiseIfNeeded();
      const setForceUpdateValue: (count: string) => void = stateObjectForceUpdateValue[1];
      // bind default functions
      const unoverrideableFuncs = {
        _useStatehookForceUpdate: (function () {
          if (statehookFlag.isMounted) {
            setForceUpdateValue(statehookLib.genUUID());
          } else {
            throw new Error('Cannot render an unmounted component!');
          }
        }),
      };
      statehookLib.objectAssign(statehookFlag.statehookObj, unoverrideableFuncs);
      // END: bind default functions
      const _returnedDestroyFunction = statehookFlag.statehookObj._useStatehookInit(
        args,
        statehookFlag.statehookObj._useStatehookForceUpdate,
        statehookFlag.statehookObj.statehookClone(),
      );
      if (statehookLib.isPromise(_returnedDestroyFunction)) {
        throw new Error('The function _useStatehookInit can not either be an async function or return a promise!');
      }
      if (!statehookLib.isFunction(_returnedDestroyFunction) && !statehookLib.isNone(_returnedDestroyFunction)) {
        throw new Error('The function _useStatehookInit can only return either a function type variable or a none-like variable!');
      }
      statehookFlag.destroyFunction = _returnedDestroyFunction;
    }
    // END: init
    // inputArgs
    statehookFlag.statehookObj._useStatehookReceiveArgs(args);
    statehookFlag.statehookObj.statehookEmit('_useStatehookBeforeRender', args);
    statehookFlag.statehookObj._useStatehookBeforeRender(args);

    useEffect(function () {
      // only once on init
      return function () {
        statehookFlag.isMounted = false;
        if (statehookLib.isFunction(statehookFlag.destroyFunction)) {
          statehookFlag.destroyFunction(statehookFlag.statehookObj);
        }
        statehookFlag.statehookObj.statehookDiscard();
      };
    }, []);
    useEffect(function () {
      // every times after render
      statehookFlag.statehookObj.statehookEmit('_useStatehookAfterRender', args);
      statehookFlag.statehookObj._useStatehookAfterRender(args);
    });
    return statehookFlag.statehookObj;
  }

  public isUseStatehook = true;

  // constructor(args: UseStatehookArgs, useStatehookObject: UseStatehookClass, reactLib: ReactLibrary) {

  // }

  public useStatehookArgs: UseStatehookArgs;

  protected _useStatehookInit(inputArgs?: UseStatehookArgs, _useStatehookForceUpdate?: UseStatehookForceUpdate, statehook?: StatehookClass): void|UseStatehookInitFunctionReturn {
    return function (): void {};
  }

  protected _useStatehookReceiveArgs(inputArgs?: UseStatehookArgs): void {
    this.useStatehookArgs = inputArgs;
  }

  protected _useStatehookForceUpdate(): void {
  }

  protected _useStatehookAfterRender(inputArgs?: UseStatehookArgs): void {
  }

  protected _useStatehookBeforeRender(inputArgs?: UseStatehookArgs): void {
  }
}
