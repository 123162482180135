import { parsePath, StatehookClass, StatehookName } from '$/statehook/typescript';
import { AdminDoc } from './api_service/apis/user.api';

export class AdminServiceClass extends StatehookClass {
  static override grabOne(statehookName: StatehookName) {
    return super.grabOne([].concat(['AdminServiceClass'], parsePath(statehookName, '/')));
  }
  sessionService: any = null
  _onInit(args: any) {
    const { adminDoc, sessionService } = args;
    this.sessionService = sessionService;
    this.statehookSetManyPaths(adminDoc as AdminDoc);
  }
  getProp(propName: string): any {
    return this.statehookGetPath(propName) as any;
  }
  setProp(propName: string, value: any) {
    this.statehookSetPath(propName, value);
  }
  isAdmin(): boolean {
    return this.getProp('role') === 'admin';
  }
  isSuperAdmin(): boolean {
    return this.getProp('role') === 'super_admin';
  }
}
