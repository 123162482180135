/**
 * @author MrZenW
 * @email MrZenW@Gmail.com, https://MrZenW.com
 * @create date 2021-06-17 11:32:28
 * @modify date 2021-06-17 11:32:31
 * @desc [description]
 */
import { ApiService, HttpResponse, HttpResponseData } from '../api_service';

export interface Work {
  _id: string,
  workType: string,
  workName: string,
  workCoverImagePath?: string,
  workDescription?: string,
  forkedFrom?: Work,
  ownerUserId?: string,
  ownerSessionId?: string,
  publishForGameType?: string,
  blocklyXML?: string,
  levelData?: any,
  likedNumber: number,
  createdAt: string,
  usedCount?: number,
  docUrl?: string,
  isDefaultWork?: boolean,
  $amIOwner?: boolean,
  [propName: string]: any,
}

export interface WorkInfoForSubmitting {
  _id: string,
  workName: string,
  workCoverImageFile?: File,
  workDescription?: string,
  publishForAge?: string,
  publishForCategory?: string,
  publishForGameType?: string,
  docUrl?: string,
  [propName: string]: any,
}

export interface WorkInfoResponseData extends HttpResponseData {
  workDoc: Work,
}
export interface WorkInfoResponse extends HttpResponse {
  data: WorkInfoResponseData
}

export interface MyWorksResponseData extends HttpResponseData {
  myWorks: Array<Work>,
  currentPageNumber: number,
  nextPageNumber: number,
}
export interface MyWorksResponse extends HttpResponse {
  data: MyWorksResponseData,
}

export interface NewWorkResponseData extends HttpResponseData {
  newWork: Work,
}
export interface NewWorkResponse extends HttpResponse {
  data: NewWorkResponseData,
}

// official works
export interface OfficialWorksResponseData extends HttpResponseData {
  officialWorks: Array<Work>,
  currentPageNumber: number,
  nextPageNumber: number,
}

export interface OfficialWorksResponse extends HttpResponse {
  data: OfficialWorksResponseData,
}

// public works
export enum PublicWorkForAge {
  all = 'all',
  Age0To5 = 'Age0To5',
  Age6To8 = 'Age6To8',
  Age9To11 = 'Age9To11',
  Age12Plus = 'Age12Plus',
}
export enum PublicWorkForGameType {
  all = 'all',
  MazeRace = 'MazeRace',
  // HideAndSeek = 'HideAndSeek',
  RoamFree = 'RoamFree',
  // CubeRun = 'CubeRun',
  // Escape = 'Escape',
}
export enum PublicWorkForCategory {
  all = 'all',
  SinglePlayer = 'SinglePlayer',
  Multiplayer = 'Multiplayer',
}
export interface PublicWorkFilter {
  publishForAge?: PublicWorkForAge
  publishForGameType?: PublicWorkForGameType
  publishForCategory?: PublicWorkForCategory
  mode?: string
  [optionName: string]: any
}
export interface PublicWorksResponseData extends HttpResponseData {
  publicWorks: Array<Work>,
  currentPageNumber: number,
  nextPageNumber: number,
}

export interface PublicWorksResponse extends HttpResponse {
  data: PublicWorksResponseData,
}

// week-1
export interface Week001ApiLeaderboardItem {
  value: string,
  score: number,
}

export interface Week001LeaderboardResponseData extends HttpResponseData {
  coderLeaderboard: Week001ApiLeaderboardItem[],
  fastestLeaderboard: Week001ApiLeaderboardItem[],
}
export interface Week001LeaderboardResponse extends HttpResponse {
  data: Week001LeaderboardResponseData,
}

export interface Week001LeaderboardUploadParamItem {
  playerSessionPublicId: string,
  codescore?: number,
  timeTaken?: number,
  playerName: string,
  roomCode: string,
}

export interface Week001UnityPlayerScoreDataItem {
  timeTaken: number,
  codescore: number,
  userName: string,
}
export interface Week001UnityPlayerScoreData {
  [playerSessionPublicId: string]: Week001UnityPlayerScoreDataItem
}

// my score
export interface Week001MyScoreResponseData extends HttpResponseData {
  myCoder: {
    score: number,
    value: string,
    ranking: number,
  } | null,
  myFastest: {
    score: number,
    value: string,
    ranking: number,
  } | null,
}
export interface Week001MyScoreResponse extends HttpResponse {
  data: Week001MyScoreResponseData,
}

export const FilterOptions = {
  age: [
    {
      text: '0 to 5',
      value: PublicWorkForAge.Age0To5,
    },
    {
      text: '6 to 8',
      value: PublicWorkForAge.Age6To8,
    },
    {
      text: '9 to 11',
      value: PublicWorkForAge.Age9To11,
    },
    {
      text: '12+',
      value: PublicWorkForAge.Age12Plus,
    },
  ],
  gameType: [
    {
      text: 'Maze Race',
      value: PublicWorkForGameType.MazeRace,
    },
    /*{
      text: 'Hide & Seek',
      value: PublicWorkForGameType.HideAndSeek,
    },*/
    {
      text: 'Roam Free',
      value: PublicWorkForGameType.RoamFree,
    },
    /*{
      text: 'Cube Run',
      value: PublicWorkForGameType.CubeRun,
    },
    {
      text: 'Escape',
      value: PublicWorkForGameType.Escape,
    },*/
  ],
  category: [
    {
      text: 'Single Player',
      value: PublicWorkForCategory.SinglePlayer,
    },
    {
      text: 'Multiplayer',
      value: PublicWorkForCategory.Multiplayer,
    },
  ],
};

export class WorkApiClass {
  apiService: ApiService

  constructor(apiService: ApiService) {
    this.apiService = apiService;
  }

  getWorkInfo(workId: string): Promise<WorkInfoResponse> {
    return this.apiService.clientGet(`/work/${workId}`) as Promise<WorkInfoResponse>;
  }

  deleteWork(workId: string): Promise<HttpResponse> {
    return this.apiService.clientDelete(`/work/${workId}`) as Promise<HttpResponse>;
  }

  patchWorkInfo(workId: string, info: any): Promise<WorkInfoResponse> {
    const bodyFormData = new FormData();
    Object.entries(info).forEach(([key, value]) => {
      if (key === 'levelData') {
        value = JSON.stringify(value);
      }
      bodyFormData.append(key, value as any);
    });
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };
    return this.apiService.clientPatch(`/work/${workId}`, null, bodyFormData, config) as Promise<WorkInfoResponse>;
  }

  getRoomClientWork(roomCode: string): Promise<WorkInfoResponse> {
    return this.apiService.clientGet(`/work/roomClientWork/${roomCode}`) as Promise<WorkInfoResponse>;
  }

  patchRoomClientWork(roomCode: string, patchInfo: any): Promise<WorkInfoResponse> {
    return this.apiService.clientPatch(`/work/roomClientWork/${roomCode}`, null, patchInfo) as Promise<WorkInfoResponse>;
  }

  getMyWorks(pageNumber: number|string): Promise<MyWorksResponse> {
    return this.apiService.clientGet(`/work/my_works`, { p: pageNumber }) as Promise<MyWorksResponse>;
  }

  getOfficialWorks(pageNumber: number|string): Promise<OfficialWorksResponse> {
    return this.apiService.clientGet('/work/official_works', { p: pageNumber }) as Promise<OfficialWorksResponse>;
  }

  getPublicWorks(pageNumber: number|string, filter?: PublicWorkFilter): Promise<PublicWorksResponse> {
    return this.apiService.clientGet('/work/public_works', Object.assign({ p: pageNumber }, filter)) as Promise<PublicWorksResponse>;
  }

  createNewWork(workName: string): Promise<NewWorkResponse> {
    return new Promise((resolve, reject) => {
      this.apiService.clientPost('/work/new', null, { workName }, {
        responseErrorUse: reject,
      })
        .then(resolve);
    });
  }

  changeRoomClientWork(roomCode: string, workId: string, count: boolean = true): Promise<WorkInfoResponse> {
    return this.apiService.clientPost(`/work/roomClientWork/${roomCode}/changeWork/${workId}`, {
      count,
    }) as Promise<WorkInfoResponse>;
  }

  patchLevelDataItem(workId: string, levelDataItemKey: string, value: any): Promise<HttpResponse> {
    return this.apiService.clientPatch(`/work/${workId}/levelData/${levelDataItemKey}`, null, value) as Promise<HttpResponse>;
  }

  fork(workId: string, extraWorkProperties?: any): Promise<NewWorkResponse> {
    return this.apiService.clientPost(`/work/fork/${workId}`, null, extraWorkProperties) as Promise<NewWorkResponse>;
  }

  week001LeaderboardNewScore(roomCode: string, params: Week001LeaderboardUploadParamItem | Week001LeaderboardUploadParamItem[], extraData?: any): Promise<Week001LeaderboardResponse> {
    if (!Array.isArray(params)) {
      params = [params];
    }
    return this.apiService.clientPost(`/work/-room/${roomCode}/weekly-challenge/week-001/new-score`, null, {
      extraData,
      newScore: params,
    }) as Promise<Week001LeaderboardResponse>;
  }
  week001LeaderboardGetData(): Promise<Week001LeaderboardResponse> {
    return this.apiService.clientGet(`/work/weekly-challenge/week-001/leaderboard`) as Promise<Week001LeaderboardResponse>;
  }
  week001LeaderboardGetMyScore(roomCode: string): Promise<Week001MyScoreResponse> {
    return this.apiService.clientGet(`/work/-room/${roomCode}/weekly-challenge/week-001/my-score`) as Promise<Week001MyScoreResponse>;
  }
  publishWork(newWorkInfo: WorkInfoForSubmitting): Promise<NewWorkResponse> {
    const bodyFormData = new FormData();
    Object.entries(newWorkInfo).forEach(([key, value]) => {
      if (value) bodyFormData.append(key, value);
    });
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };
    return this.apiService.clientPost(`/work/publish/new-publication`, null, bodyFormData, config) as Promise<NewWorkResponse>;
  }
  increaseLikeNumber(workId: string): Promise<WorkInfoResponse> {
    return this.apiService.clientGet(`/work/${workId}/like`) as Promise<WorkInfoResponse>;
  }
}
