/**
 * @author MrZenW
 * @email MrZenW@Gmail.com, https://MrZenW.com
 * @create date 2021-05-25 13:21:07
 * @modify date 2021-12-14 15:39:14
 * @desc [description]
 */
const { statehookify } = require('$/statehook');
const { globalCrossWindowChannelService } = require('$/services/cross_window_channel_service');

const themes = ['dark', 'light'];

module.exports = statehookify({ statehookName: 'theme_service', bindThisToBase: true }, {
  get currentThemeName() {
    return this.statehookGetPath('currentThemeName');
  },
  set currentThemeName(newThemeName) {
    newThemeName = `${newThemeName}`.toLowerCase();
    const currentThemeName = (this.currentThemeName + '').toLowerCase();
    if (newThemeName === currentThemeName) {
      return null;
    }
    if (themes.indexOf(newThemeName) === -1) throw new Error('Only support either dark or light!');
    document.body.classList.add(newThemeName + '-theme');
    document.body.classList.remove(currentThemeName + '-theme');
    this.statehookSetPath('currentThemeName', newThemeName);
    globalCrossWindowChannelService.publishBroadcast({
      type: 'ThemeChange',
      currentThemeName: newThemeName,
    });
    return newThemeName;
  },
  set isHighContrastStyle(v) {
    return this.statehookSetPath('isHighContrastStyle', v);
  },
  get isHighContrastStyle() {
    return this.statehookGetPath('isHighContrastStyle');
  },
  get isDarkTheme() {
    return this.currentThemeName === 'dark';
  },
  get isLightTheme() {
    return this.currentThemeName === 'light';
  },
  onThemeChange(cb) {
    return this.statehookWatchPath('currentThemeName', () => {
      cb(this.currentThemeName);
    });
  },
}, (statehook, baseObject) => {
  const changeFunc = (event) => {
    const isDark = event.matches;
    baseObject.currentThemeName = isDark ? 'dark' : 'light';
  };
  // init
  changeFunc(window.matchMedia('(prefers-color-scheme: dark)'));
  window.matchMedia('(prefers-color-scheme: dark)')
    .addEventListener('change', changeFunc);

  baseObject.statehookWatchPath('isHighContrastStyle', () => {
    if (baseObject.isHighContrastStyle) {
      window.document.body.classList.add('high-contrast-style');
    } else {
      window.document.body.classList.remove('high-contrast-style');
    }
  });

  // listen to other tab
  globalCrossWindowChannelService.receiveBroadcast((event) => {
    const { content } = event;
    if (content.type === 'ThemeChange') {
      baseObject.currentThemeName = content.currentThemeName;
    }
  });
  return () => {
    window.matchMedia('(prefers-color-scheme: dark)')
      .removeEventListener('change', changeFunc);
  };
});
