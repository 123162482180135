/**
 * @author MrZenW
 * @email MrZenW@Gmail.com, https://MrZenW.com
 * @create date 2021-05-25 13:09:08
 * @modify date 2021-05-25 13:09:08
 * @desc [description]
 */
const React = require('react');
const PropTypes = require('prop-types');
const { useStatehook } = require('$/statehook');
const { EmbedContext } = require('./embed_context');

const Embed = (props) => {
  const statehook = useStatehook({ props }, (inputArgs, forceUpdate, thisStatehook) => {
    thisStatehook.statehookOn('_useStatehookBeforeRender', (eventType, _inputArgs) => {
      const { embedTest, embedFactor } = _inputArgs.props;
      let matched = null;
      if (embedTest instanceof RegExp) {
        matched = embedTest.exec(embedFactor);
      } else if (typeof embedTest === 'function') {
        matched = embedTest();
      } else if (embedFactor === embedTest) {
        matched = embedFactor;
      }
      thisStatehook.statehookSetPath('embedTest', embedTest);
      thisStatehook.statehookSetPath('embedFactor', embedFactor);
      thisStatehook.statehookSetPath('matched', matched);
    });
    return () => {
      thisStatehook.statehookDiscard();
    };
  }, React);
  const { embedComponent: Component, children } = props;
  const matched = statehook.statehookGetPath('matched');
  if (matched) {
    const componentProps = Object.entries(props).reduce((acc, [k, v]) => {
      if (Object.keys(Embed._propTypes).indexOf(k) === -1) {
        acc[k] = v;
      }
      return acc;
    }, {});
    // componentProps['embedMatched'] = matched;
    const embedTest = statehook.statehookGetPath('embedTest');
    const embedFactor = statehook.statehookGetPath('embedFactor');
    return (
      <EmbedContext.Provider value={{ embedTest, embedFactor, embedMatched: matched }}>
        <Component {...componentProps}>
          {children}
        </Component>
      </EmbedContext.Provider>
    );
  } else {
    return <></>;
  }
};
Embed._propTypes = {
  embedFactor: PropTypes.string,
  embedTest: PropTypes.oneOfType([PropTypes.func, PropTypes.string, PropTypes.instanceOf(RegExp)]).isRequired,
  embedComponent: PropTypes.oneOfType([PropTypes.func, PropTypes.element]).isRequired,
};
Embed.propTypes = Object.assign({}, Embed._propTypes);
Embed.defaultProps = {
  embedFactor: '',
};

module.exports = Embed;
