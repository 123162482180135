/**
 * @author MrZenW
 * @email MrZenW@Gmail.com, https://MrZenW.com
 * @create date 2022-05-31 12:04:08
 * @modify date 2022-05-31 12:04:10
 * @desc [description]
 */

/* eslint-disable */
/* eslint-disable no-restricted-syntax */
const Blockly = require('blockly');
const { customAlphabet } = require('nanoid');
const monaco = require('monaco-editor/esm/vs/editor/editor.api');
const _ = require('lodash');
// const monaco = {};
// import { full } from 'acorn/dist/walk';
const { createDebounce } = require('$/libraries/debounce');
const { statehookify } = require('$/statehook');
const { convertPython2XML } = require('$/libraries/language-converter/BlocklyPython2XML');

const nanoid = customAlphabet('1234567890abcdef', 5);

function genBlocklyUid(...rest) {
  return Blockly.utils.genUid(...rest);
}

function extractAllArgs(def) {
  return Object.entries(def).reduce((acc, [defKey, value]) => {
    if (defKey.startsWith('args')) {
      acc[parseInt(defKey.slice('args'.length), 10)] = value;
    }
    return acc;
  }, []);
}

function extractAllArgItems(def) {
  return extractAllArgs(def).reduce((acc, curr) => {
    return acc.concat(curr);
  }, []);
}
function convertAcornRange2MonacoRange(sourceCode, acornRange) {
  // acornRange = { start, end }
  const { start, end } = acornRange;
  const beforeStart = '\n' + sourceCode.substr(0, start);
  const beforeEnd = '\n' + sourceCode.substr(0, end);
  return {
    startLineNumber: beforeStart.match(/\n/g).length,
    startColumn: beforeStart.length - beforeStart.lastIndexOf('\n'),

    endLineNumber: beforeEnd.match(/\n/g).length,
    endColumn: beforeEnd.length - beforeEnd.lastIndexOf('\n'),
  };
}

function convertMonacoRange2AcornRange(argSourceCode, monacoRange) {
  const {
    startLineNumber,
    startColumn,
    endLineNumber,
    endColumn,
  } = monacoRange;
  const sourceCode = `\n${argSourceCode}`; // the first line is 1 in the editor
  // const template = `
  // 0:
  // 1:log('hello');
  // 2:log('world');
  // 3:
  // `;
  let loopTimesStart = startLineNumber;
  let startLineIndex = -1;
  while (loopTimesStart > 0) {
    startLineIndex = sourceCode.indexOf('\n', startLineIndex + 1);
    loopTimesStart -= 1;
  }
  let loopTimesEnd = endLineNumber;
  let endLineIndex = -1;
  while (loopTimesEnd > 0) {
    endLineIndex = sourceCode.indexOf('\n', endLineIndex + 1);
    loopTimesEnd -= 1;
  }
  const start = startLineIndex + startColumn;
  const end = endLineIndex + endColumn;

  return {
    start,
    end,
  };
}
exports.convertAcornRange2MonacoRange = convertAcornRange2MonacoRange;
exports.convertMonacoRange2AcornRange = convertMonacoRange2AcornRange;
exports.extractAllArgItems = extractAllArgItems;
exports.blocklyPythonService = statehookify('blocklyPythonService', {
  addFunctionFromJsonInit(blocklyType, definition) {
    definition = Object.assign({}, definition);
    // blocklyType = blocklyType.replace(/\//g, '__');
    blocklyType = _.last(blocklyType.split('/'));
    definition['blocklyType'] = definition['blocklyType'] || blocklyType;
    Object.keys(definition).forEach((itemKey) => {
      if (itemKey.startsWith('args')) {
        definition[itemKey] = Array.from(definition[itemKey]).filter((arg) => {
          if (arg.type === 'field_image') return false;
          if (arg.type === 'field_label') return false;
          if (arg.type === 'input_dummy') return false;
          return true;
        });
      }
    });
    this.statehookSetPath(['blocks', blocklyType], definition);
    if (definition['kaiPythonFunctionName']) {
      if (!Array.isArray(definition['kaiPythonFunctionName'])) {
        definition['kaiPythonFunctionName'] = [definition['kaiPythonFunctionName']];
      }
      definition['kaiPythonFunctionName'].forEach((funcName) => {
        this.statehookSetPath(['blocks', funcName], definition);
      })
    }
  },
  get allBlockDefinitions() {
    return this.statehookGetPath(['blocks']);
  },
  findBlockDefinition(blocklyType) {
    return this.statehookGetPath(['blocks', blocklyType]);
  },
  getAllFunctionDefinitionsForMonaco(opt, additions) {
    additions = additions || [];
    const blocks = this.allBlockDefinitions || {};
    // {
    //   label: '"lodash"',
    //   kind: monaco.languages.CompletionItemKind.Function,
    //   documentation: "The Lodash library exported as Node.js modules.",
    //   insertText: '"lodash": "*"',
    //   range: range
    // },
    const returnv = Object.entries(blocks).reduce((acc, [blockType, def]) => {
      const args = extractAllArgItems(def).reduce((accArg, row) => {
        if (row.name) {
          if (row.name === 'CALLBACK' && row.type === 'input_statement') {
            // a callback function
            // accArg.push(`() => {\n\t\${${accArg.length + 1}:# Your logic here!\n\tpass}\n}`);
            const funcNanoId = `_callback_func_${nanoid()}`;
            accArg.push({
              before: `def ${funcNanoId}():\n\t\${${accArg.length + 1}:# Your logic here!\n\tpass}\n`,
              content: funcNanoId,
              after: '',
            })
          } else {
            // accArg.push(`\${${accArg.length + 1}:${row.name}__${row.type}}`)
            accArg.push({
              before: '',
              content: `\${${accArg.length + 1}:${row.name}__${row.type}}`,
              after: '',
            })
          }
        }
        return accArg;
      }, []);
      // def.forEach((rowDef) => {
      //   Object.entries(rowDef).forEach(([currDefKey, currVal]) => {
      //     if (currDefKey.startsWith('args')) {
      //       currVal = _.get(currVal, '[0]') || currVal;
      //       args[currDefKey.slice('args'.length)] = `${currVal.name}__${currVal.type}`;
      //     }
      //   });
      // });
      const beforeCode = args.reduce((acc, a) => { acc.push(a.before); return acc }, []);
      const argsContent = args.reduce((acc, a) => { acc.push(a.content); return acc; }, []);
      acc.push(Object.assign({
        label: blockType,
        kind: monaco.languages.CompletionItemKind.Function,
        // https://microsoft.github.io/monaco-editor/api/interfaces/monaco.languages.completionitem.html#documentation
        documentation: def.message0,
        detail: def.message0,
        insertText: `${beforeCode.join('')}${blockType}(${argsContent.join(', ')})`,
        insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
      }, opt));
      return acc;
    }, []).concat(additions.map((row) => {
      return Object.assign({
        kind: monaco.languages.CompletionItemKind.Function,
        // https://microsoft.github.io/monaco-editor/api/interfaces/monaco.languages.completionitem.html#documentation
        insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
      }, row, opt);
    }));
    return returnv;
  },
  convertAcornRange2MonacoRange(acornRange) {
    return convertAcornRange2MonacoRange(this.code, acornRange);
  },
  convertMonacoRange2AcornRange(range) {
    return convertMonacoRange2AcornRange(this.code, range);
  },
  set code(newValue) {
    this.statehookSetPath('code', newValue);
  },
  get code() {
    return this.statehookGetPath('code');
  },
  parse(cb) {
    return this.buildASTDebounce(this.code, cb);
  },
  buildASTDebounce: createDebounce(function (code, cb) {
    return this.buildAST(code, cb);
  }, 0.5 * 1e3),
  buildAST(code, cb) {
    const result = convertPython2XML(code, this.allBlockDefinitions);
    const [error, instance] = result;
    const errors = Array.from(_.get(instance, 'errors') || []);
    if (error) {
      console.error(error);
      const errorMessage = _.get(error, 'message') || `${error}`;
      // check unexpected token error
      const matched = /.* \((\d+):(\d+)\)/g.exec(errorMessage);
      if (matched) {
        // const originalMessage = matched[0];
        const lineNumber = parseInt(matched[1]);
        const columnNumber = parseInt(matched[2]);
        errors.push({
          error,
          section: this.convertMonacoRange2AcornRange({
            startLineNumber: lineNumber,
            startColumn: columnNumber,
            endLineNumber: lineNumber,
            endColumn: columnNumber + 1,
          }),
        })
      } else {
        kaiAlert.statusNotify({
          type: 'error',
          position: 'bottom',
          title: 'Programme Error:',
          text: errorMessage,
          timer: 5 * 1e3,
        });
      }
    }
    this.errors = errors;
    this.instanceOfAST2XML = instance;
    if ('function' === typeof cb) cb(...result);
    return result;
  },
  set errors(newValue) {
    this.statehookSetPath('errors', newValue);
  },
  get errors() {
    return this.statehookGetPath('errors') || [];
  },
  watchErrors(cb) {
    return this.statehookWatchPath('errors', cb);
  },
  set instanceOfAST2XML(instance) {
    this.statehookSetPath('instanceOfAST2XML', instance);
  },
  get instanceOfAST2XML() {
    return this.statehookGetPath('instanceOfAST2XML');
  },
  watchInstanceOfAST2XML(cb) {
    return this.statehookWatchPath('instanceOfAST2XML', cb)
  },
  watchCode(cb) {
    return this.statehookWatchPath('code', cb);
  },

  findErrorsByASTRange(range) {
    const { start, end } = range;
    return this.errors.filter((err) => {
      const s1 = err.section.start;
      const e1 = err.section.end;
      const s2 = start;
      const e2 = end;

      // s1[  s2[  e2]  e1]
      if (s1 <= s2 && e2 <= e1) return true;
      // s2[  s1[  e1]  e2]
      if (s2 <= s1 && e1 <= e2) return true;
      // s1[   e1]   s1[    e1]
      //   s2[         e2]
      if (s1 <= s2 && e1 >= s2) return true;
      if (s1 <= e2 && e1 >= e2) return true;
      return false;
    });
  },
}, {});
