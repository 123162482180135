/**
 * @author MrZenW
 * @email MrZenW@Gmail.com, https://MrZenW.com
 * @create date 2021-11-19 11:43:57
 * @modify date 2021-11-19 11:43:57
 * @desc [description]
 */

import {
  parsePath,
  StatehookClass,
  StatehookName,
  StatehookUnwatch,
  StatehookWatchCallback,
} from '$/statehook/typescript';
import { grabZIndexManagerService } from '$/services/z_index_manager_service';

const zIndexManagerService = grabZIndexManagerService('kais-solo-global-z-index-manager-service');

// export const PlayerListWindowService: any = null;

export class PlayerListWindowService extends StatehookClass {
  sessionService: any
  static override grabOne(statehookName: StatehookName) {
    return super.grabOne([].concat(['PlayerListWindowService'], parsePath(statehookName, '/')));
  }
  get webTaskManagerService(): any {
    return this.sessionService.webTaskManagerService;
  }

  get allowedSeatCount(): number {
    return this.statehookGetPath('allowedSeatCount') || 6;
  }
  set allowedSeatCount(newValue: number) {
    this.statehookSetPath('allowedSeatCount', newValue);
  }

  get welcomeMessage(): string {
    return this.statehookGetPath('welcomeMessage') || '';
  }
  set welcomeMessage(newValue: string) {
    this.statehookSetPath('welcomeMessage', newValue);
  }
  watchWelcomeMessage(cb: StatehookWatchCallback) {
    return this.statehookWatchPath('welcomeMessage', cb);
  }
  _onInit(args: any) {
    this.sessionService = args.sessionService;
    this.statehookRegisterUnwatch([
      this.sessionService.uiService.onPlayerListWindowVisibilityChange(() => {
        if (this.sessionService.uiService.playerListWindowVisibility) {
          this._open();
        } else {
          this._close();
        }
      }),
    ]);
  }
  toggle() {
    this.sessionService.uiService.playerListWindowVisibility = !this.sessionService.uiService.playerListWindowVisibility;
    // if (this.visibility) {
    //   this.close();
    // } else {
    //   this.open();
    // }
  }
  public close() {
    this.sessionService.uiService.playerListWindowVisibility = false;
  }
  private _close() {
    this.webTaskManagerService.terminateTask('player_list_window');
  }
  public open() {
    this.sessionService.uiService.playerListWindowVisibility = true;
  }
  private _open() {
    this.webTaskManagerService.newTask('player_list_window', {
      windowData: {
        title: 'Lobby',
        contentComponentName: 'PlayerListWindowView',
        unresizeable: true,
        unminimiseable: true,
        style: {
          position: 'absolute',
          // top: '0px',
          left: '10px',
          top: '10px',
          // right: '0px',
          // minWidth: '600px',
          minHeight: '500px',
          // maxWidth: '100%',
          maxHeight: 'calc(100% - 100px)',
          width: '600px',
          height: '700px',
          zIndex: '10',
          backgroundColor: 'var(--theme-bg)',
        },
        titleBarStyle: {

        },
        onClose: () => {
          this.webTaskManagerService.terminateTask('player_list_window');
        },
      },
    });
    setTimeout(() => {
      zIndexManagerService.goTop('player_list_window');
    //   // webWindowManagerService.goTop(this.windowRef);
    }, 0.1 * 1e3);
  }
}
