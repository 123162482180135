/**
 * @author MrZenW
 * @email MrZenW@Gmail.com, https://MrZenW.com
 * @create date 2021-10-06 14:34:55
 * @modify date 2021-10-06 14:34:55
 * @desc [description]
 */

/* eslint-disable jsx-a11y/media-has-caption */

import React from 'react';
import { SessionContext } from '../session_context';
import { TutorialVideoWindowModel } from './tutorial_video_window.model';

const { WebWindowContext } = require('../web_window');

export const TutorialVideoWindowView = (props: any) => {
  const statehook: TutorialVideoWindowModel = TutorialVideoWindowModel.useStatehook({
    props,
    sessionContext: React.useContext(SessionContext),
    webWindowContext: React.useContext(WebWindowContext),
  }, React);
  // set titleBarRightButtonGroup
  statehook.webWindowStatehook.setTitleBarStyle({
    height: '60px',
  });
  statehook.webWindowStatehook.setTitleBarComponent([
    <div key="tutorials">
      <div className="d-flex justify-content-center gap-5">
        <div style={{ paddingLeft: '80px' }}>
          <i className="fas fa-book-open" />
          &nbsp;
          <a
            href="//kaiseducation.com/video-tutorials/"
            target="_blank"
            rel="noreferrer"
            style={{
              color: 'unset',
              // textShadow: '0px 0px 5px grey',
            }}
          >
            Tutorials
          </a>
        </div>
        <div>
          <a
            href="https://docs.google.com/document/d/1iQRzJIVOH9jpHZM2mZEPldqaKM0aQPD-BrpFB9BNb7I/"
            target="_blank"
            rel="noreferrer"
            style={{
              color: 'unset',
              // textShadow: '0px 0px 5px grey',
            }}
          >
            <i className="fa fa-book" />
             User Guide
          </a>
        </div>
      </div>
    </div>,
  ]);
  return (
    <div
      style={{
        width: '100%',
        height: '100%',
      }}
    >
      <video
        ref={(ref) => {
          statehook.receiveVideoElement(ref);
        }}
        className="video-js"
        style={{ width: '100%', height: '100%' }}
        controls
        preload="auto"
        poster="https://proxy-do-spaces-kaisclan-sgp1.kaisclan.ai/kainundrum/tutorial-videos/kainundrum-low-quality-hosted-video-poster.png"
        data-setup="{}"
      >
        <source src="https://proxy-do-spaces-kaisclan-sgp1.kaisclan.ai/kainundrum/tutorial-videos/kainundrum-low-quality-hosted-video.mp4" type="video/mp4" />
        <p className="vjs-no-js">
          To view this video please enable JavaScript, and consider upgrading to a
          web browser that
          <a href="https://videojs.com/html5-video-support/" target="_blank" rel="noreferrer">
            supports HTML5 video
          </a>
        </p>
      </video>
      {/* <iframe
        title="tutorial video"
        src="https://player.vimeo.com/video/623851227?h=ffeb4e161c"
        width="100%"
        height="100%"
        frameBorder="0"
        allow="autoplay; fullscreen; picture-in-picture"
        allowFullScreen
        ref={(ref) => {
          statehook.receiveIFrameRef(ref);
        }}
      /> */}
    </div>
  );
};
