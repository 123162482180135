/**
 * @author MrZenW
 * @email MrZenW@Gmail.com, https://MrZenW.com
 * @create date 2021-10-04 14:55:37
 * @modify date 2021-10-04 14:55:37
 * @desc [description]
 */

import { animateFrame } from '$/libraries/animate';
import { parsePath, StatehookClass, StatehookName, StatehookUnwatch, StatehookWatchCallback } from '$/statehook/typescript';

export const ANGLE_UP = 45 * 0;
export const ANGLE_RIGHT_UP = 45 * 1;
export const ANGLE_RIGHT = 45 * 2;
export const ANGLE_RIGHT_DOWN = 45 * 3;
export const ANGLE_DOWN = 45 * 4;
export const ANGLE_LEFT_DOWN = 45 * 5;
export const ANGLE_LEFT = 45 * 6;
export const ANGLE_LEFT_UP = 45 * 7;
export const ANGLE_NONE = -1;

export class GamepadServiceClass extends StatehookClass {
  ref: any
  refJoystick: any
  joystickStatehook: any
  joyButtonsStatehook: any
  setRef(ref: any) {
    this.ref = ref;
  }
  lastStyle: any = null
  isAnimating: boolean = false
  get isMinimised(): boolean {
    return !!this.statehookGetPath('isMinimised');
  }
  set isMinimised(newValue: boolean) {
    this.statehookSetPath('isMinimised', newValue);
  }
  static override grabOne(statehookName: StatehookName) {
    return super.grabOne([].concat(['GamepadServiceClass'], parsePath(statehookName, '/')));
  }
  onMinimisedChange(cb: StatehookWatchCallback): StatehookUnwatch {
    return this.statehookWatchPath('isMinimised', cb);
  }
  minimiseToCentre() {
    const viewPointHeight = parseInt(window.innerHeight + '', 10) || 0;
    const viewPointWidth = parseInt(window.innerWidth + '', 10) || 0;
    return this.minimise({ top: (viewPointHeight - 30) + 'px', left: (viewPointWidth * 0.5) + 'px' });
  }
  minimise(option: any = {}) {
    if (this.isMinimised) return;
    if (this.isAnimating) return;
    this.isAnimating = true;
    const { top, left } = option;
    const { ref } = this;
    const currentStyle = ref.currentStyle || window.getComputedStyle(ref);
    this.lastStyle = Object.assign({}, currentStyle);
    const startTop = parseInt(this.lastStyle.top, 10);
    const endTop = parseInt(top, 10);
    const startLeft = parseInt(this.lastStyle.left, 10);
    const endLeft = parseInt(left, 10);
    ref.style.bottom = 'unset';
    this.isMinimised = true;
    animateFrame((progress: number) => {
      ref.style.top = progress * (endTop - startTop) + startTop + 'px';
      ref.style.left = progress * (endLeft - startLeft) + startLeft + 'px';
      const scale = `scale(${1 - progress})`;
      ref.style.transform = scale;
      if (progress === 1) {
        this.isAnimating = false;
      }
    });
  }
  recover() {
    if (!this.isMinimised) return;
    if (!this.lastStyle) return;
    if (this.isAnimating) return;
    this.isAnimating = true;
    const { ref } = this;
    const currentStyle = ref.currentStyle || window.getComputedStyle(ref);
    const startTop = parseInt(currentStyle.top, 10);
    const endTop = parseInt(this.lastStyle.top, 10);
    const startLeft = parseInt(currentStyle.left, 10);
    const endLeft = parseInt(this.lastStyle.left, 10);
    ref.style.bottom = 'unset';
    this.isMinimised = false;
    animateFrame((progress: number) => {
      ref.style.top = progress * (endTop - startTop) + startTop + 'px';
      ref.style.left = progress * (endLeft - startLeft) + startLeft + 'px';
      ref.style.transform = `scale(${progress})`;
      if (progress === 1) {
        this.isAnimating = false;
      }
    });
  }
  get gamepadState(): any {
    return this.statehookGetPath('gamepadState') || { joystick: {}, joyButtons: { a: false, b: false } };
  }
  set gamepadState(newValue: any) {
    this.statehookSetPath('gamepadState', Object.assign({}, newValue));
  }
  _setJoystickRef(ref: any) {
    this.refJoystick = ref;
  }
  _setJoystickStatehookRef(statehookRef: any) {
    this.joystickStatehook = statehookRef;
    if (this.isEnabled) {
      this.enable(true);
    } else {
      this.enable(false);
    }
  }
  _setJoyButtonsStatehookRef(statehookRef: any) {
    this.joyButtonsStatehook = statehookRef;
    if (this.isEnabled) {
      this.enable(true);
    } else {
      this.enable(false);
    }
  }
  _attachGamepadJoystickAction(gamepadState: any) {
    const { joystick } = gamepadState;
    let left = 0;
    if (joystick.x > 0.4) { // right
      left = 1;
    } else if (joystick.x < -0.4) { // left
      left = -1;
    }
    let up = 0;
    if (joystick.y > 0.4) { // bottom
      up = 1;
    } else if (joystick.y < -0.4) { // up
      up = -1;
    }
    let angle = -1;
    if (left === -1) { // left
      if (up === -1) {
        angle = ANGLE_LEFT_UP;
      } else if (up === 1) {
        angle = ANGLE_LEFT_DOWN;
      } else {
        angle = ANGLE_LEFT;
      }
    } else if (left === 1) { // right
      if (up === -1) {
        angle = ANGLE_RIGHT_UP;
      } else if (up === 1) {
        angle = ANGLE_RIGHT_DOWN;
      } else {
        angle = ANGLE_RIGHT;
      }
    } else {
      if (up === -1) {
        angle = ANGLE_UP;
      } else if (up === 1) {
        angle = ANGLE_DOWN;
      } else {
        angle = ANGLE_NONE;
      }
    }
    Object.assign(gamepadState.joystick, {
      angle,
      destance: angle === -1 ? 0 : 255,
    });
    return gamepadState;
  }
  _changeJoystickState(value: any, cb: (...rest: any[]) => {}) {
    const { gamepadState } = this;
    const newGamepadState = this._attachGamepadJoystickAction(Object.assign({}, gamepadState, {
      joystick: value,
    }));
    cb(newGamepadState);
    this.gamepadState = newGamepadState;
  }
  _changeButtonState(newButtonState: any, cb: (...rest: any[]) => {}) {
    const { gamepadState } = this;
    const newGamepadState = Object.assign({}, gamepadState, {
      joyButtons: newButtonState,
    });
    cb(newGamepadState);
    this.gamepadState = newGamepadState;
  }
  get isEnabled(): boolean {
    return !!this.statehookGetPath('enabled');
  }
  enable(enable: boolean = true) {
    if (this.joystickStatehook) {
      this.joystickStatehook.enable(false);
      if (enable !== false) {
        this.joystickStatehook.enable(true);
      }
    }
    if (this.joyButtonsStatehook) {
      this.joyButtonsStatehook.enable(false);
      if (enable !== false) {
        this.joyButtonsStatehook.enable(true);
      }
    }
    if (enable === false) {
      this.statehookSetPath('enabled', false);
    } else {
      this.statehookSetPath('enabled', true);
    }
  }
  setJoystickStateValue() {
    this.joystickStatehook.setValue.apply(this, arguments);
  }
  watchGamepadState(cb: StatehookWatchCallback): StatehookUnwatch {
    return this.statehookWatchPath('gamepadState', () => {
      cb(this.gamepadState);
    });
  }
}
