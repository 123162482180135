const _ = require('lodash');
const { customAlphabet } = require('nanoid');

const nanoid = customAlphabet('1234567890abcdef', 5);

class BlocklyXML2Python {
  constructor() {
    this.codeSETUP = '';
    this.codeLoop = '';
    this.codeEvents = '';
    this.codeFunctionDef = {};
    this.blocklyDef = {};
  }
  parse(xml, blocklyDef) {
    this.blocklyDef = blocklyDef;
    const xmlParser = new DOMParser();
    const xmlDoc = xmlParser.parseFromString(xml, 'application/xml');
    const xmlDocEle = xmlDoc.documentElement;
    const blockRunProgram = xmlDocEle.querySelector('block[type$="/_start"]');
    this.parse_runProgram(blockRunProgram.querySelector(['statement[name="DO"]']));

    const blockFunctionsDefNoReturn = xmlDocEle.querySelectorAll('block[type="procedures_defnoreturn"]');
    this.parse_procedures_defnoreturn(blockFunctionsDefNoReturn);

    const blockFunctionsDefReturn = xmlDocEle.querySelectorAll('block[type="procedures_defreturn"]');
    this.parse_procedures_defreturn(blockFunctionsDefReturn);

    const entireCode =
`# 🅚🅐🅘❜🅢 🅟🅨🅣🅗🅞🅝
# Kai Python is a reference implementation of the Python programming language.
# It does not support the following functions:
# "import", "Class" and "for loop".
# Note: "for loop" is replaced with the function repeat_times(times, callback)

# == Function definitions ==
${Object.values(this.codeFunctionDef).join('\n')}

# == Programme area ==
${this.codeSETUP}
# == Programme area END ==

`;

    return entireCode;
  }

  parse_runProgram(dom) {
    if (dom) {
      const setupCode = this.statement_hub(dom.children, 0);
      this.codeSETUP = setupCode;
    }
  }
  parse_procedures_defnoreturn(doms) {
    doms = Array.from(doms);
    doms.forEach((dom) => {
      const children = Array.from(dom.children);
      const funcNameDom = children.find((c) => c.getAttribute('name') === 'NAME');
      let funcName = null;
      if (funcNameDom) funcName = `${funcNameDom.innerHTML}`.replace(/[^A-Za-z0-9_]+/g, '_');

      let args = [];
      const mutation = children.find((c) => c.tagName === 'mutation');
      if (mutation) {
        args = Array.from(mutation.children).map((c) => {
          return c.getAttribute('name');
        });
      }
      this.section_def_function(funcName, _.get(children.find((c) => c.tagName === 'statement'), 'children'), args);
    });
  }
  parse_procedures_defreturn(doms) {
    doms = Array.from(doms);
    console.log(doms, 'parse_procedures_defreturn');
    doms.forEach((dom) => {
      const children = Array.from(dom.children);
      const funcNameDom = children.find((c) => c.getAttribute('name') === 'NAME');
      let funcName = null;
      if (funcNameDom) funcName = `${funcNameDom.innerHTML}`.replace(/[^A-Za-z0-9_]+/g, '_');

      let args = [];
      const mutation = children.find((c) => c.tagName === 'mutation');
      if (mutation) {
        args = Array.from(mutation.children).map((c) => {
          return c.getAttribute('name');
        });
      }

      let returnDom = children.find((c) => c.getAttribute('name') === 'RETURN');
      this.section_def_function(funcName, _.get(children.find((c) => c.tagName === 'statement'), 'children'), args, returnDom);
    });
  }
  statement_hub(doms, indentationLevel) {
    indentationLevel = parseInt(indentationLevel, 10) || 0;
    console.log(doms, 'xmldoc');
    const indentation = '\t'.repeat(indentationLevel);
    if (!doms) doms = [];
    const domArray = Array.from(doms);

    let domListCurrDom = domArray[0];
    const domList = [];
    let next = null;
    if (domListCurrDom) {
      domList.push(domListCurrDom);
      next = Array.from(domListCurrDom.children || []).find((d) => d.tagName === 'next');
    }
    while (next) {
      domListCurrDom = next.children[0];
      if (domListCurrDom) {
        domList.push(domListCurrDom);
        next = Array.from(domListCurrDom.children).find((d) => d.tagName === 'next');
      }
    }
    const codeList = domList.map((currDom) => {
      const t = this.parse_block_dom_to_code(currDom, indentationLevel);
      return t;
    });
    let returnValue = null;
    if (codeList.length > 0) {
      // returnValue = indentation + codeList.join('\n' + indentation);
      returnValue = codeList.join('\n');
    } else {
      returnValue = indentation + 'pass';
    }
    return returnValue;
  }
  section_def_function(functionName, statement, argNames, returnDom) {
    argNames = argNames || [];
    console.assert(Array.isArray(argNames));
    const funcId = nanoid();
    const funcName = functionName || `_callback_func_${funcId}`;
    let code = this.statement_hub(statement, 1);
    let returnCode = '';
    if (returnDom) {
      returnCode = '\n' + '\t'.repeat(1) + 'return ' + this.tool_pick_value(returnDom);
      code += returnCode;
    }
    const defCode = `def ${funcName}(${argNames.join(', ')}):\n${code}`;
    this.codeFunctionDef[funcName] = defCode;
    return { name: funcName, code: defCode };
  }
  parse_block_dom_to_code(blockDom, indentationLevel) {
    indentationLevel = parseInt(indentationLevel, 10) || 0;
    let blockType = blockDom.getAttribute('type');
    // blockType = blockType.replace(/\//g, '__');
    blockType = _.last(blockType.split('/'));
    // const blockId = blockDom.getAttribute('id');
    const blockDef = this.blocklyDef[blockType];
    const argsDoms = Array.from(blockDom.children).filter((cd) => cd.tagName !== 'next');

    const specialFunc = this[`block__${blockType}`];
    if ('function' === typeof specialFunc) {
      return specialFunc.call(this, blockDom, blockDef, argsDoms, indentationLevel);
    }

    // found the definition on the def for the block
    if (blockDef) {
      const blockDefAllArgs = Object.keys(blockDef).reduce((acc, currKey) => {
        if (currKey.startsWith('args')) {
          acc = acc.concat(blockDef[currKey]);
        }
        return acc;
      }, []);
      const codeArgs = blockDefAllArgs.map((currArg) => {
        const { type } = currArg;
        if (type === 'input_statement') {
          // another function
          // this.section_def_function()
          const statementDom = argsDoms.find((d) => d.tagName === 'statement');
          if (statementDom) {
            const { name: funcName } = this.section_def_function(null, statementDom.children);
            return funcName;
          }
        } else {
          const currArgDom = argsDoms.find((d) => d.getAttribute('name') === currArg.name);
          if (currArgDom) return this.code_section_arg(currArgDom);
        }
        return '';
      });
      const functionName = blockDef['blocklyType'] || blockType;
      const returnval = `${'\t'.repeat(indentationLevel)}${functionName}(${codeArgs.join(', ')})`;
      return returnval;
    }
    return '';
  }
  code_section_arg(argDom) {
    const tagName = argDom.tagName; // field or value
    const argDomChildren = Array.from(argDom.children);
    let returnValue = null;
    if (tagName === 'field') { // <field>
      // no children mean this is an non-block
      const rawValue = argDom.innerHTML;
      let valueJsonFormat = null;
      if (`${rawValue}`.startsWith('{') || `${rawValue}`.startsWith('[')) {
        try {
          valueJsonFormat = JSON.parse(rawValue);
        } catch (error) {
          valueJsonFormat = null;
        }
      }
      if (valueJsonFormat !== null && _.has(valueJsonFormat, 'text') && _.has(valueJsonFormat, 'value')) {
        returnValue = _.get(valueJsonFormat, 'value');
      } else {
        returnValue = rawValue;
      }
      returnValue = JSON.stringify(returnValue); // attach - "
    } else { // <value>
      // ! actually, shadows and blocks are same, they just have different proformance
      let argDomContentShadow = null;
      // otherThanShadow has higher priority to display
      let argDomContentOtherThanShadow = null;
      argDomChildren.forEach((aDom) => {
        if (aDom.tagName === 'shadow') {
          argDomContentShadow = aDom;
        } else {
          argDomContentOtherThanShadow = aDom;
        }
      });
      if (argDomContentOtherThanShadow) {
        returnValue = this.parse_block_dom_to_code(argDomContentOtherThanShadow);
      } else {
        returnValue = this.parse_block_dom_to_code(argDomContentShadow);
      }
    }

    return returnValue;
  }
  tool_pick_blocks_to_name(childrenDom) {
    return Array.from(childrenDom).reduce((acc, curr) => {
      acc[curr.getAttribute('name')] = curr;
      return acc;
    }, {});
  }
  tool_pick_value(dom) {
    if (dom === null || dom === undefined) {
      return 'None';
    }
    let shadow = null;
    let otherThanShadow = null;
    Array.from(dom.children).forEach((c) => {
      if (c.tagName === 'shadow') {
        shadow = c;
      } else {
        otherThanShadow = c;
      }
    });
    return this.parse_block_dom_to_code(otherThanShadow || shadow);
  }
  block__math_arithmetic(blockDom, def, childrenDom) {
    console.log(blockDom, def, childrenDom, 'block__math_arithmetic');
    const { OP, A, B } = this.tool_pick_blocks_to_name(childrenDom);
    const op = ({
      ADD: '+',
      MINUS: '-',
      MULTIPLY: '*',
      DIVIDE: '/',
      POWER: '^',
    })[OP.innerHTML];
    const codeA = this.tool_pick_value(A);
    const codeB = this.tool_pick_value(B);
    return `${codeA} ${op} ${codeB}`;
  }
  block__math_number(blockDom, def, childrenDom) {
    console.log(blockDom, def, childrenDom, 'block__math_number');
    return _.get(childrenDom, '[0].innerHTML');
  }
  block__variables_get(blockDom, def, childrenDom) {
    return _.get(childrenDom, '[0].innerHTML');
  }
  block__text(blockDom, def, childrenDom) {
    const returnValue = _.get(childrenDom, '[0].innerHTML');
    return `"${returnValue}"`;
  }
  block__logic_boolean(blockDom, def, childrenDom) {
    return _.get(childrenDom, '[0].innerHTML') === 'TRUE' ? 'True' : 'False';
  }
  block__customized_controls_whileUntil(blockDom, def, childrenDom, indentationLevel) {
    console.log(blockDom, def, childrenDom, 'block__customized_controls_whileUntil');
    const { MODE, BOOL, DO } = this.tool_pick_blocks_to_name(childrenDom);

    let condition = null;
    if (BOOL) {
      condition = this.tool_pick_value(BOOL);
    }
    if (MODE.innerHTML === 'UNTIL') {
      condition = `not (${condition})`;
    }
    let whileBody = null;
    if (DO) {
      // put the code to the body of while loop statement
      whileBody = this.statement_hub(DO.children, indentationLevel + 1);
    }

    const code = `${'\t'.repeat(indentationLevel)}while ${condition}:\n${whileBody}`;

    return code;
  }
  block__customized_controls_if(blockDom, def, childrenDom, indentationLevel) {
    console.log(blockDom, def, childrenDom, 'block__customized_controls_if');
    const blockSlotDict = this.tool_pick_blocks_to_name(childrenDom);
    let ifNumber = 1;
    const mutation = childrenDom.find((c) => c.tagName === 'mutation');
    if (mutation) {
      ifNumber += parseInt(mutation.getAttribute('elseif'), 10) || 0;
    }
    console.log(ifNumber, 'ssss');
    const ifSlots = [];
    const doSlots = [];
    for (let ifIdx = 0; ifIdx < ifNumber; ifIdx += 1) {
      ifSlots.push(blockSlotDict['IF' + ifIdx]);
      doSlots.push(blockSlotDict['DO' + ifIdx]);
    }
    const { ELSE } = blockSlotDict;
    let code = '';
    ifSlots.forEach((ifCondition, idx) => {
      let condition = 'False';
      if (ifCondition) {
        condition = this.tool_pick_value(ifCondition);
      }
      // if
      let ifExpression = 'elif';
      if (idx === 0) ifExpression = 'if';
      code += `\n${'\t'.repeat(indentationLevel)}${ifExpression} ${condition}:\n`;

      // do
      const doSection = doSlots[idx];
      if (doSection) {
        code += this.statement_hub(doSection.children, indentationLevel + 1);
      } else {
        code += '\t'.repeat(indentationLevel + 1) + 'pass';
      }
    });
    if (ELSE) {
      const elseBody = this.statement_hub(ELSE.children, indentationLevel + 1);
      code += '\n' + '\t'.repeat(indentationLevel) + 'else:\n' + elseBody;
    }
    // let condition = null;
    // if (ifCondition) {
    //   condition = this.tool_pick_value(ifCondition);
    // }
    // let body = '\t'.repeat(indentationLevel + 1) + 'pass';
    // if (DO) {
    //   body = this.statement_hub(DO.children, indentationLevel + 1);
    // }
    // let code = `${'\t'.repeat(indentationLevel)}if ${condition}:\n${body}`;
    // if (ELSE) {
    //   const elseBody = this.statement_hub(ELSE.children, indentationLevel + 1);
    //   code += '\n' + '\t'.repeat(indentationLevel) + 'else:\n' + elseBody;
    // }
    return code;
  }
  block__variables_set(blockDom, def, childrenDom, indentationLevel) {
    console.log(blockDom, def, childrenDom, 'block__variables_set');
    const { VAR, VALUE } = this.tool_pick_blocks_to_name(childrenDom);
    const variableName = VAR.innerHTML;
    const value = this.tool_pick_value(VALUE);

    return `${'\t'.repeat(indentationLevel)}${variableName} = ${value}`;
  }
  block__customized_logic_compare(blockDom, def, childrenDom) {
    console.log(blockDom, def, childrenDom, 'block__customized_logic_compare');
    const { OP, A, B } = this.tool_pick_blocks_to_name(childrenDom);
    const op = ({
      EQ: '==',
      NEQ: '!=',
      LT: '<',
      LTE: '<=',
      GT: '>',
      GTE: '>=',
    })[OP.innerHTML];
    const codeA = this.tool_pick_value(A);
    const codeB = this.tool_pick_value(B);
    return `${codeA} ${op} ${codeB}`;
  }
  block__customized_controls_repeat_ext(blockDom, def, childrenDom, indentationLevel) {
    console.log(blockDom, def, childrenDom, 'block__customized_controls_repeat_ext');
    const { TIMES, DO } = this.tool_pick_blocks_to_name(childrenDom);
    const times = this.tool_pick_value(TIMES);
    const { name: funcName } = this.section_def_function(null, _.get(DO, 'children'));
    const code = `${'\t'.repeat(indentationLevel)}repeat_times(${times}, ${funcName})`;
    return code;
  }
  block__customized_controls_for(blockDom, def, childrenDom, indentationLevel) {
    console.log(blockDom, def, childrenDom, 'block__customized_controls_for');
    const {
      VAR,
      FROM,
      TO,
      BY,
      DO,
    } = this.tool_pick_blocks_to_name(childrenDom);
    const varName = VAR.innerHTML;
    const from = this.tool_pick_value(FROM);
    const to = this.tool_pick_value(TO);
    const by = this.tool_pick_value(BY);
    const { name: funcName } = this.section_def_function(null, _.get(DO, 'children'), [varName]);
    return `${'\t'.repeat(indentationLevel)}count_with(${from}, ${to}, ${by}, ${funcName})`;
  }
  block__customized_controls_flow_statements(blockDom, def, childrenDom, indentationLevel) {
    console.log(blockDom, def, childrenDom, 'block__customized_controls_flow_statements');
    const op = ({
      BREAK: 'break',
      CONTINUE: 'continue',
    })[childrenDom[0].innerHTML];
    if (op) return '\t'.repeat(indentationLevel) + op;
    return null;
  }
  block__array_set_item(blockDom, def, childrenDom, indentationLevel) {
    console.log(blockDom, def, childrenDom, 'block__array_set_item');
    const { ARRAY_NAME, KEY, VALUE } = this.tool_pick_blocks_to_name(childrenDom);
    const arrayName = ARRAY_NAME.innerHTML;
    const key = this.tool_pick_value(KEY);
    const value = this.tool_pick_value(VALUE);
    const code = `array_set_item(${key}, ${value}, ${arrayName})`;
    return '\t'.repeat(indentationLevel) + code;
  }
  block__procedures_callnoreturn(blockDom, def, childrenDom, indentationLevel) {
    console.log(blockDom, def, childrenDom, 'block__procedures_callnoreturn');
    childrenDom = Array.from(childrenDom);
    const mutation = childrenDom.find((c) => c.tagName === 'mutation');
    let funcName = null;
    let argDefs = [];
    if (mutation) {
      funcName = `${mutation.getAttribute('name')}`.replace(/[^A-Za-z0-9_]+/g, '_');
      argDefs = Array.from(mutation.children || []);
    }
    const allInputArgDoms = childrenDom.filter((currDom) => `${currDom.getAttribute('name')}`.startsWith('ARG'));
    const allInputArgCodes = argDefs.map((curr, idx) => {
      if (allInputArgDoms[idx]) return this.tool_pick_value(allInputArgDoms[idx]);
      return '""';
    });
    const code = `${funcName}(${allInputArgCodes.join(', ')})`;
    return '\t'.repeat(indentationLevel) + code;
  }
  block__procedures_callreturn(blockDom, def, childrenDom) {
    console.log(blockDom, def, childrenDom, 'block__procedures_callreturn');
    return this.block__procedures_callnoreturn(blockDom, def, childrenDom, 0);
  }
  // the end of the class definitions
}

function convertXML2Python(...rest) {
  const instance = new BlocklyXML2Python();
  return instance.parse(...rest);
}

exports.convertXML2Python = convertXML2Python;
